// 复制内容
export function copyToClipboard(content) {
  if (window.clipboardData) {
    console.log('window.clipboardData', window.clipboardData);
    window.clipboardData.setData('text', content);
  } else {
    (function (content) {
      document.oncopy = function (e) {
        e.clipboardData.setData('text', content);
        e.preventDefault();
        document.oncopy = null;
      };
    })(content);
    document.execCommand('Copy');
  }
}

//节流
export function throttle(func, time = 1000) {
  let timer = undefined;
  return function (...args) {
    if (!timer) {
      timer = setTimeout(() => {
        timer = undefined;
      }, time);
      func.call(this, ...args);
    }
  };
}

export function getQueryToken() {
  let geturl = window.location.href;
  let getqyinfo = geturl.split('?')[1];
  let getqys = new URLSearchParams(getqyinfo);
  let authToken = getqys.get('authToken');
  return authToken;
}

export function splicing(list) {
  let same;
  let i = -1;
  let len = list.length;
  let arr = [];

  if (!len) return;
  while (++i < len) {
    const item = list[i];
    if (item.check) {
      if (item.check !== Boolean(same)) {
        arr.push(...['@', item.begin, '~', item.end]);
      } else if (arr.length) {
        arr.pop();
        arr.push(item.end);
      }
    }
    same = Boolean(item.check);
  }
  arr.shift();
  return arr.join('');
}
