<template>
  <div class="app-container">
    <el-dialog
      :visible="visible"
      :title="!detailId ? '新建': '编辑'"
      width="600px"
      @close="handleClose"
    >
      <el-row>
        <el-form
          ref="dialogForm"
          :model="dataSource"
          size="small"
          label-width="110px"
        >
          <el-form-item
            label="名称"
            prop="name"
            :rules="{
              required: false,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model.trim="dataSource.name"
              placeholder="请输入"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="广告类型"
            prop="type"
            :rules="{
              required: true,
              message: '请选择',
              trigger: 'change',
            }"
          >
            <el-select
              v-model="dataSource.type"
              clearable
              style="width: 340px"
              :disabled="detailId ? true :  false"
            >
              <el-option
                v-for="item in adverTypeList"
                :key="item.code"
                :value="item.code"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="dataSource.type == 'rewardVideoAd'"
            label="服务端回调"
            prop="callbackFlag"
            :rules="{
              required: true,
              message: '请选择',
              trigger: 'change',
            }"
          >
            <el-switch
              v-model="dataSource.callbackFlag"
              :active-value="1"
              :inactive-value="0"
              @change="switchChange"
            />
          </el-form-item>

          <el-form-item
            v-if="dataSource.callbackFlag"
            label="奖励名称"
            prop="rewardName"
            :rules="{
              required: dataSource.callbackFlag ? true : false,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model.trim="dataSource.rewardName"
              placeholder="请输入"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item
            v-if="dataSource.callbackFlag"
            label="奖励数量"
            prop="rewardNum"
            :rules="{
              required: dataSource.callbackFlag ? true : false,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <!-- <el-input
              v-model.trim="dataSource.rewardNum"
              placeholder="请输入"
              style="width: 300px"
            ></el-input> -->
            <el-input-number 
              style="width: 440px" 
              :min="1"
              step-strictly
              v-model.trim="dataSource.rewardNum"
            ></el-input-number>
          </el-form-item>

          <el-form-item
            v-if="dataSource.callbackFlag"
            label="回调URL"
            prop="callbackUrl"
            :rules="{
              required: dataSource.callbackFlag ? true : false,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model.trim="dataSource.callbackUrl"
              placeholder="请输入"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item
            v-if="dataSource.callbackFlag"
            label="回调密钥"
            prop="callbackSecret"
            :rules="{
              required: false,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model.trim="dataSource.callbackSecret"
              placeholder="请输入"
              style="width: 300px"
              :disabled="true"
            >
            <div slot="append">
              <i 
                class="el-icon-document-copy"
                style="cursor: pointer;"
               @click="copyLink(`${dataSource.callbackSecret}`)"></i>
            </div>
            </el-input>
            <span class="refesh" @click.stop="refreshCipher">刷新</span>
            <!-- <i
              class="el-icon-document-copy"
              style="color: #409eff; margin-left: 5px; cursor: pointer; vertical-align: top"
              @click="copyLink(``)"
            ></i> -->
          </el-form-item>
          

        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import proApi from '@/api/pages/proApi';
import applicationConfigApi from '@/api/pages/applicationConfig';

export default {
  name: 'dataSourceConfigList',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data:{
      type: Object,
      default:{}
    },
    adverTypeList:{
      type: Array,
      default:[]
    },
    detailId:{

    }
  },
  data() {
    return {
      dataSource: {
        name: '',
        type:'',
        callbackFlag: 0,
        callbackSecret: '',
        callbackUrl: '',
        rewardName:'',
        rewardNum:''
      },
    };
  },

  watch: {
    visible: {
      handler: function (val) {
        if (val && this.$parent.detailId) {
          this.dataSource = this.data
        }else {
          this.dataSource = {
            name: '',
            type:'',
            callbackFlag: 0,
            callbackSecret: '',
            callbackUrl: '',
            rewardName:'',
            rewardNum:''
          }
        }
      },
      deep: true,
    },
  },

  mounted() {},

  methods: {
    handleClose() {
      // this.$refs.dialogForm.resetFields();
      this.$emit('close');
    },

    // 复制链接
    copyLink(content) {
      console.log(content)
      this.copyToClipboard(content) // 需要复制的文本内容
      this.$message.success('复制成功')
    },
    copyToClipboard(content) {
      if (window.clipboardData) {
        console.log('window.clipboardData', window.clipboardData)
        window.clipboardData.setData('text', content)
      } else {
        ;(function (content) {
          document.oncopy = function (e) {
            e.clipboardData.setData('text', content)
            e.preventDefault()
            document.oncopy = null
          }
        })(content)
        document.execCommand('Copy')
      }
    },

    refreshCipher() {
      proApi.genCallbackSecret().then(res => {
        if(res && res.code == 1 && res.data) {
          this.dataSource.callbackSecret = res.data
        }
      })
    },

    switchChange(val) {
      console.log('val-0-000-', val)
      if(val == 1) {
        this.refreshCipher()
      }
    },

    // 提交
    submitData() {
      let that = this;
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(that.dataSource));
          applicationConfigApi.adverUpdate(data).then((res) => {
            if (res && res.code == 1) {
              this.$message.success('保存成功');
              this.handleClose();
              this.$parent.adverSpaceList();
            } else {
              this.$message.error('保存失败');
            }
          })
          .catch((e) => {
            console.log('e', e);
          });
        }
      });
    },

    testLinks() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          proApi.testConnect({
        dsUrl: this.dataSource.dsUrl,
        dsPassword: this.dataSource.dsPassword,
        dsAccount: this.dataSource.dsAccount,
        dsType: this.dataSource.dsType
      }).then(res =>{
        if(res && res.code == 1 && res.data) {
          this.$message.success(res.data)
        }else if(res && res.code == 0){
          this.$message.error(res.data)
        }
      })
        }
      });
     
    }
  },
};
</script>

<style scoped lang="scss">
.dialog-footer {
  text-align: center;
  display: block;
}
.el-input-group__append, .el-input-group__prepend {
  padding: 0 8px;
  
}
.refesh {
  font-size: 13px;
  color: #3D7FFF;
  padding-left: 8px;
  cursor: pointer;
}
</style>
