<template>
  <div class="app-container">
    <el-card>
      <el-row>
        <el-form ref="templeteForm" :model="requestParams" size="small" :inline="true">
          <el-row type="flex" style="flex-wrap: wrap">
            <div class="el-custom-grid">
              <el-form-item prop="configAppId">
                <el-select filterable class="el-select-darkness" v-model="requestParams.configAppId" placeholder="应用" clearable @change="changeApp">
                  <el-option v-for="item in projectList" :key="item.id" :label="`${item.platformTypeName} | ${item.name} | id:${item.id}`" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item prop="adSpaceDataId">
                <el-select filterable class="el-select-darkness" v-model="requestParams.adSpaceDataId" placeholder="广告位" clearable @change="changeAd">
                  <el-option v-for="item in adverSpaceList" :key="item.id" :label="`${item.name} | id:${item.id}`" :value="item.id" />
                </el-select>
              </el-form-item>

              <el-form-item prop="adNetworkCodeList">
                <el-select multiple class="el-select-darkness" v-model="requestParams.adNetworkCodeList" placeholder="广告网络" clearable @change="changeNet">
                  <el-option v-for="item in adverNetList" :key="item.adNetworkCode" :label="item.adNetworkName" :value="item.adNetworkCode" />
                </el-select>
              </el-form-item>

              <!-- <div>
                <el-button size="small" @click="handleSearch()" class="el-button-search">查询</el-button>

                <el-button size="small" @click="handleReset()" class="el-button-reset">重置</el-button>
              </div> -->
            </div>
          </el-row>
          <el-col  :span="24" :style='{"margin-bottom": "10px", "display": "flex", "justify-content": tabList && tabList.length && tabList.length > 0 ? "space-between": "end"}'>
            <div v-if="tabList && tabList.length && tabList.length >0 ">
              <el-radio-group  v-model="requestParams.adBitsSubsetId" @change="handerTab">
                <el-radio-button
                  v-for="(item, index) in tabList"
                  :key="index"
                  :label="item.id"
                >
                    {{ item.name }}
                </el-radio-button>
              </el-radio-group>
            </div>
            <el-button type="primary" size="small" style="margin-left: 20px" @click.stop="trafficGroup">流量分组管理</el-button>
          </el-col>
        </el-form>

        <div style="margin-bottom: 20px">
          <el-dropdown @command="handleMenuClick">
            <el-button type="primary" size="small">
              添加代码位
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">添加单个广告位</el-dropdown-item>
              <el-dropdown-item command="2">批量添加广告位</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button @click="handleWaterfallFlowConfig" type="primary" size="small" style="margin-left: 20px">瀑布流策略设置</el-button>
        </div>

        <!-- 表格 -->
        <div style="margin-bottom: 10px; color: #333; font-weight: bold">竞价</div>
        <el-table
          size="small"
          :data="biddingTypeList"
          style="width: 100%; margin-bottom: 50px"
          :header-cell-style="{
            height: '50px',
            fontSize: '14px',
            color: '#333',
            fontweight: 400,
          }"
          border
        >
          <el-table-column label="操作" fixed="left" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="editData(scope.row, 'edit')">编辑</el-button>

              <el-popconfirm title="确定要删除吗？" @confirm="deleteData(scope.row.id)">
                <el-button style="margin-left: 30px" slot="reference" type="text" size="small" class="el-button-text-danger">删除</el-button>
              </el-popconfirm>

              <el-popconfirm title="确定要更改状态吗？" @confirm="updateAdverStatus(scope.row)">
                <el-switch slot="reference" style="margin-left: 30px" :value="scope.row.status" :active-value="1" :inactive-value="0" />
              </el-popconfirm>
            </template>
          </el-table-column>

          <el-table-column prop="adNetworkName" label="广告网络" align="center" />
          <el-table-column prop="adBitsCode" label="代码位名称/id" align="center">
            <template slot-scope="scope">
              <div style="display: flex; align-items: center; justify-content: center">
                <div>{{ scope.row.adBitsName }}-{{ scope.row.adTypeName }}-{{ scope.row.adBitsCode }}</div>
                <i
                  class="el-icon-document-copy"
                  style="color: #409eff; margin-left: 5px; cursor: pointer; vertical-align: top"
                  @click="copyLink(`${scope.row.adBitsName}-${scope.row.adTypeName}-${scope.row.adBitsCode}`)"
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="排序价格" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.price ? scope.row.price : '/' }}</div>
            </template>
          </el-table-column>
        </el-table>

        <div style="margin-bottom: 10px; color: #333; font-weight: bold">按价格</div>
        <el-table
          size="small"
          :data="priceTypeList"
          style="width: 100%; margin-bottom: 50px"
          :header-cell-style="{
            height: '50px',
            fontSize: '14px',
            color: '#333',
            fontweight: 400,
          }"
          border
        >
          <el-table-column label="操作" fixed="left" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="editData(scope.row, 'edit')">编辑</el-button>

              <el-popconfirm title="确定要删除吗？" @confirm="deleteData(scope.row.id)">
                <el-button style="margin-left: 30px" slot="reference" type="text" size="small" class="el-button-text-danger">删除</el-button>
              </el-popconfirm>

              <el-popconfirm title="确定要更改状态吗？" @confirm="updateAdverStatus(scope.row)">
                <el-switch slot="reference" style="margin-left: 30px" :value="scope.row.status" :active-value="1" :inactive-value="0" />
              </el-popconfirm>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="status" label="状态" width="120" align="center">
            <template slot-scope="scope">
              <el-switch
                :value="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="updateStatus($event, scope.row, scope.$index)"
              />
            </template>
          </el-table-column> -->
          <el-table-column prop="adNetworkName" label="广告网络" align="center" />
          <el-table-column prop="adBitsCode" label="代码位名称/id" align="center">
            <template slot-scope="scope">
              <div style="display: flex; align-items: center; justify-content: center">
                <div>{{ scope.row.adBitsName }}-{{ scope.row.adTypeName }}-{{ scope.row.adBitsCode }}</div>
                <i
                  class="el-icon-document-copy"
                  style="color: #409eff; margin-left: 5px; cursor: pointer; vertical-align: top"
                  @click="copyLink(`${scope.row.adBitsName}-${scope.row.adTypeName}-${scope.row.adBitsCode}`)"
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="排序价格" align="center" />
        </el-table>

        <div style="margin-bottom: 10px; color: #333; font-weight: bold">兜底</div>
        <el-table
          size="small"
          :data="bottomTypeList"
          style="width: 100%"
          :header-cell-style="{
            height: '50px',
            fontSize: '14px',
            color: '#333',
            fontweight: 400,
          }"
          border
        >
          <el-table-column label="操作" fixed="left" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="editData(scope.row, 'edit')">编辑</el-button>

              <el-popconfirm title="确定要删除吗？" @confirm="deleteData(scope.row.id)">
                <el-button style="margin-left: 30px" slot="reference" type="text" size="small" class="el-button-text-danger">删除</el-button>
              </el-popconfirm>
              <el-popconfirm title="确定要更改状态吗？" @confirm="updateAdverStatus(scope.row)">
                <el-switch slot="reference" style="margin-left: 30px" :value="scope.row.status" :active-value="1" :inactive-value="0" />
              </el-popconfirm>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="status" label="状态" width="120" align="center">
            <template slot-scope="scope">
              <el-switch
                :value="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="updateStatus($event, scope.row, scope.$index)"
              />
            </template>
          </el-table-column> -->
          <el-table-column prop="adNetworkName" label="广告网络" align="center" />
          <el-table-column prop="adBitsCode" label="代码位名称/id" align="center">
            <template slot-scope="scope">
              <div style="display: flex; align-items: center; justify-content: center">
                <div>{{ scope.row.adBitsName }}-{{ scope.row.adTypeName }}-{{ scope.row.adBitsCode }}</div>
                <i
                  class="el-icon-document-copy"
                  style="color: #409eff; margin-left: 5px; cursor: pointer; vertical-align: top"
                  @click="copyLink(`${scope.row.adBitsName}-${scope.row.adTypeName}-${scope.row.adBitsCode}`)"
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="排序价格" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.price ? scope.row.price : '/' }}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>
    <!-- 瀑布流策略 -->
    <WaterfallFlowConfig
      :visible="waterfallFlowConfigVisible"
      :waterfallStrategyCode="waterfallStrategyCode"
      :subsetListId="subsetListId"
      :waterfallStrategyData="waterfallStrategyData"
      @close="handleWaterfallFlowConfigRefresh"
    ></WaterfallFlowConfig>
    <!-- 新建、编辑套餐 -->
    <EditModal
      :visible="adVerVisible"
      :adverNetList="adverNetList"
      :configAppId="requestParams.configAppId"
      :adSpaceDataId="requestParams.adSpaceDataId"
      :adBitsSubsetId="requestParams.adBitsSubsetId"
      :adSpaceData="adSpaceData"
      :configAppData="configAppData"
      :itemData="itemData"
      @close="adVerVisible = false"
    ></EditModal>

    <EditorPrice
      :visible="editVisible"
      :editType="editType"
      :symbolList="symbolList"
      :ruleConfigList="ruleConfigList"
      :adverNetList="adverNetList"
      :configAppId="requestParams.configAppId"
      :adSpaceDataId="requestParams.adSpaceDataId"
      :adBitsSubsetId="requestParams.adBitsSubsetId"
      :adSpaceData="adSpaceData"
      :configAppData="configAppData"
      @close="editVisible = false"
    ></EditorPrice>
    <BatchAdd
      :visible="batchAddVisible"
      :adverNetList="adverNetList"
      :configAppId="requestParams.configAppId"
      :adSpaceDataId="requestParams.adSpaceDataId"
      :adBitsSubsetId="requestParams.adBitsSubsetId"
      :adSpaceData="adSpaceData"
      :configAppData="configAppData"
      @close="batchAddVisible = false"
    />
    <TrafficGroupManage
      :visible="traffGroupVisible"
      :adverNetList="adverNetList"
      :configAppId="requestParams.configAppId"
      :adSpaceDataId="requestParams.adSpaceDataId"
      :adBitsSubsetId="requestParams.adBitsSubsetId"
      @close="traffGroupVisible = false"
    />
  </div>
</template>

<script>
import proApi from '@/api/pages/proApi'
import EditorPrice from './components/EditorPrice'
import BatchAdd from './components/BatchAdd'
import TrafficGroupManage from './components/TrafficGroupManage'
import EditModal from './components/EditModal'
import applicationConfigApi from '@/api/pages/applicationConfig'
import WaterfallFlowConfig from './components/WaterfallFlowConfig'
export default {
  name: 'dataSourceConfigList',
  components: {
    EditorPrice,
    BatchAdd,
    TrafficGroupManage,
    EditModal,
    WaterfallFlowConfig,
  },
  data() {
    return {
      dataList: [],
      requestParams: {
        configAppId: '',
        adSpaceDataId: '',
        adNetworkCodeList:[],
        // type: '',
        // status: '',
        adBitsSubsetId: '',
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },

      editType: '',
      editVisible: false,
      detailId: '',
      tenantDbList: [],
      typeList: [],
      dimensionSetVisible: false,
      seteditType: '',
      dimensionTitle: '',
      dimensionId: '',
      projectList: [],
      databaseTypeList: [],
      statusList: [
        { code: 1, name: '启用' },
        { code: 2, name: '关闭' },
      ],
      trafficGroupVisible: false,
      ruleConfigList: [],
      symbolList: [],
      adverSpaceList: [],
      adverNetList: [],
      tabIndex: 0,
      tabList: [],
      biddingTypeList: [], //竞价
      priceTypeList: [], //按价格
      bottomTypeList: [], //兜底
      projectCodeIndex: 0,
      adVerSpaceIndex: 0,
      adSpaceData: {},
      configAppData: {},
      batchAddVisible: false,
      traffGroupVisible: false,
      adVerVisible: false,
      itemId: '',
      itemData: {},
      waterfallFlowConfigVisible: false,
      waterfallStrategyCode: '',
      waterfallStrategyData: '',
      subsetListId: '',
      adNetworkCodeList:[]
    }
  },

  watch: {
    'requestParams.configAppId': {
      handler: function (val) {
        if (val) {
          this.requestParams.configAppId = val
          this.getAdverSpaceList(val) //广告位列表
          this.getAdverNetList(val) //广告网络
          this.projectList.forEach((item, index) => {
            if (item.id == val) {
              this.configAppData = item
            }
          })
        }
      },
      deep: true,
      immediate: false,
    },
    'requestParams.adSpaceDataId':{
      handler: function (val) {
        if (val) {
          this.requestParams.adSpaceDataId = val
          this.getTabtacticsData(); //分组tab和策略数据
        }
      },
      deep: true,
      immediate: false,
    },
  },

  mounted() {
    this.getProjectList() // 应用列表
  },
  methods: {
    // 瀑布流策略设置
    handleWaterfallFlowConfig() {
      this.waterfallFlowConfigVisible = true
      if (this.tabList && this.tabList[this.tabIndex]) {
        this.waterfallStrategyCode = this.tabList[this.tabIndex].waterfallStrategyCode
        this.waterfallStrategyData = this.tabList[this.tabIndex].waterfallStrategyData
        this.subsetListId = this.tabList[this.tabIndex].id + ''
      }
    },
    //应用列表
    getProjectList() {
      applicationConfigApi
        .appList()
        .then((res) => {
          if (res && res.code === 1 && res.data) {
            this.projectList = res.data
            this.requestParams.configAppId = this.projectList[this.projectCodeIndex].id
            this.configAppData = this.projectList[this.projectCodeIndex]
            // this.getAdverNetList() //广告网络
            // this.getAdverSpaceList() //广告位列表
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },

        //广告位下拉列表
        getAdverSpaceList() {
          applicationConfigApi.forSelect({
            configAppId: this.requestParams.configAppId
          }).then((res) => {
            if (res && res.code === 1 && res.data) {
              this.adverSpaceList = res.data
              this.requestParams.adSpaceDataId = this.adverSpaceList && this.adverSpaceList.length && this.adverSpaceList.length > 0 
              ? this.adverSpaceList[this.adVerSpaceIndex].id : ''
              this.adSpaceData = this.adverSpaceList[this.adVerSpaceIndex]
              if(this.requestParams.adSpaceDataId) {
                this.getTabtacticsData()
              }else {
                this.tabList = []
              }
            }
          })
          .catch((e) => {
              console.log(e);
          });
        },

    //广告网络
    getAdverNetList(val) {
      applicationConfigApi
        .appNeworkList({
          configAppId: this.requestParams.configAppId,
        })
        .then((res) => {
          if (res && res.code === 1 && res.data) {
            this.adverNetList = res.data
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },

    //获取分组tab及策略数据
    getTabtacticsData(val) {
      applicationConfigApi
        .subsetList({
          adSpaceDataId: this.requestParams.adSpaceDataId,
        })
        .then((res) => {
          if (res && res.code === 1 && res.data) {
            this.tabList = res.data
            if (this.tabList && this.tabList.length && this.tabList.length > 0) {
              this.requestParams.adBitsSubsetId = this.tabList[this.tabIndex].id
              this.initData()
            }
            
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },

    changeNet() {
        if(!this.requestParams.adBitsSubsetId) {
          
        }else {
          this.initData()
        }
    },

    //切换tab
    handerTab(val) {
      if (this.tabList && this.tabList.length && this.tabList.length > 0) {
        this.tabList.forEach((item, index)=> {
          if(item.id == val) {
            this.tabIndex = index
          }
        })
      }
      this.requestParams.adBitsSubsetId = val
      this.initData()
    },


    changeApp(val) {
      this.requestParams.adNetworkCodeList = []
      this.getAdverNetList()
      // this.getAdverSpaceList()
      // this.getAdverNetList()

    },

    changeAd(e) {
      this.adverSpaceList.forEach((item, index) => {
        if(item.id == e) {
          this.adSpaceData = this.adverSpaceList[index]
        }
      })
    },

    // 复制链接
    copyLink(content) {
      console.log(content)
      this.copyToClipboard(content) // 需要复制的文本内容
      this.$message.success('复制成功')
    },
    copyToClipboard(content) {
      if (window.clipboardData) {
        console.log('window.clipboardData', window.clipboardData)
        window.clipboardData.setData('text', content)
      } else {
        ;(function (content) {
          document.oncopy = function (e) {
            e.clipboardData.setData('text', content)
            e.preventDefault()
            document.oncopy = null
          }
        })(content)
        document.execCommand('Copy')
      }
    },

    //批量操作
    handleMenuClick(command) {
      if (command == 1) {
        this.editVisible = true
      } else {
        this.batchAddVisible = true
      }
    },

    trafficGroup() {
      this.traffGroupVisible = true
    },

    deleteData(id) {
      applicationConfigApi
        .dataDelete(id)
        .then((res) => {
          if (res && res.code === 1) {
            this.$message.success('删除成功')
            this.initData()
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },

   

    //列表更改状态
    updateAdverStatus(row) {
      let val = row.status == 1 ? 0 : 1
      applicationConfigApi
        .adBitsUpdateStatus({
          id: row.id,
          status: val,
        })
        .then((res) => {
          if (res && res.code === 1) {
            this.$message.success('更新成功')
            this.initData()
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },

    getDataSourceType() {
      proApi.listDataSourceType({}).then((res) => {
        if (res && res.code == 1 && res.data) {
          this.databaseTypeList = res.data
        }
      })
    },

    initData() {
      this.pageParam = {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      }
      this.loadData()
    },
    // 获取列表
    loadData() {
      let that = this
      // console.log('requestParams.adNetworkCodeList----', typeof(this.adNetworkCodeList))
      // const requestData = JSON.parse(JSON.stringify(that.requestParams));
      // that.requestParams.adNetworkCodeList = this.adNetworkCodeList
      // console.log('that.requestParams', that.requestParams)
      applicationConfigApi
        .dataList({
          ...that.requestParams,
          ...that.pageParam,
        })
        .then((res) => {
          if (res && res.code === 1 && res.data) {
            this.biddingTypeList = res.data.biddingTypeList
            this.priceTypeList = res.data.priceTypeList
            this.bottomTypeList = res.data.bottomTypeList

            that.dataList = res.data.list
            //   that.pageParam.total = res.data.total;
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },

    getsymbolList() {
      applicationConfigApi.operatorList().then((res) => {
        if (res.code == 1 && res.data) {
          this.symbolList = res.data
        }
      })
    },

    // 测试链接
    testLinks(row) {
      proApi
        .testConnect({
          dsUrl: row.dsUrl,
          dsPassword: row.dsPassword,
          dsAccount: row.dsAccount,
          dsType: row.dsType,
        })
        .then((res) => {
          if (res && res.code == 1 && res.data) {
            this.$message.success(res.data)
          } else if (res && res.code == 0) {
            this.$message.error(res.data)
          }
        })
    },

    //操作状态
    updateStatus(e, row, index) {
      let msg = row.status == 1 ? '确定关闭？' : '确定开启？'
      let val = row.status == 1 ? 0 : 1
      this.$confirm(msg, '提示', { type: 'warning' }).then(() => {
        proApi
          .dataSourceUpdateStatus({ id: row.id, status: val })
          .then((res) => {
            if (res && res.code == 1) {
              this.$message.success('修改成功')
              this.initData()
            }
          })
          .catch((e) => {
            console.log(e)
          })
      })
    },

    // 分页
    pageSizeChange(val) {
      this.pageParam.pageSize = val
      this.pageParam.pageNum = 1
      this.loadData()
    },
    // 分页
    pageIndexChange(val) {
      this.pageParam.pageNum = val
      this.loadData()
    },

    // 编辑、新增、复制
    editData(row, type) {
      this.editType = type
      this.itemId = row.id
      this.itemData = row
      this.adVerVisible = true
    },

    dimensionTypeSet(row) {
      this.dimensionSetVisible = true
      this.dimensionTitle = row.name
      this.dimensionId = row.id
    },

    handleSearch() {
      this.initData()
    },
    handleReset() {
      this.$refs.templeteForm.resetFields()
      this.initData()
    },
    handleWaterfallFlowConfigRefresh() {
      this.waterfallFlowConfigVisible = false
      this.getTabtacticsData(this.requestParams.adSpaceDataId) //分组tab和策略数据
    },
    // 获取名称
    getFieldName(list, key, val) {
      if (list && list.length > 0) {
        let obj = list.filter((item) => {
          return item[key] == val
        })
        if (obj.length > 0) {
          return obj[0].name
        }
      }
      return null
    },
  },
}
</script>
<style scoped lang="scss">
.qy_img {
  display: flex;
  .qy_img_item {
    margin-right: 20px;
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
    background: #000;
    border-radius: 10px;
    position: relative;
    img {
      width: 55px;
      margin: auto;
      display: block;
    }
  }
  .qy_img_name {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
  }
  .qy_img_name1 {
    text-align: center;
    font-size: 8px;
    margin-top: 5px;
    color: #fff;
  }
}
.top_title {
  margin-bottom: 20px;
  .txt {
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
  }
}
.page_block {
  text-align: center;
  margin-top: 20px;
}
.button-box {
  text-align: right;
  margin-top: 40px;
}
.title-tip {
  display: flex;
}
.rightTitle {
  margin-left: 8px;
  font-weight: bold;
}
.text {
  color: #999;
  font-size: 13px;
}
.imgs {
  width: 80px;
  height: 80px;
}
.top-container {
  display: flex;
  justify-content: space-between;
}
.top-right {
  margin-left: 200px;
  display: flex;
  align-items: center;
}
.right-new {
  margin-right: 40px;
}
.size-icon {
  font-size: 30px;
  margin-right: 30px;
}
.img-list {
  margin-left: 50px;
}
.img-title {
  text-align: center;
  margin-bottom: 15px;
}
.img-item {
  display: block;
  width: 100px;
  margin: auto;
}
.img-tip {
  margin-left: 20px;
}
.img-tip:nth-child(2) {
  margin-top: 20px;
}

.avatar-uploader {
  .el-upload {
    display: block;
    border: 1px dashed #ccc;
    border-radius: 6px;
  }
  .el-icon-plus {
    border: 1px dashed #ccc;
    border-radius: 6px;
  }
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.labelTips {
  max-width: 100px;
  max-height: 20px;
}
.top_btn {
  margin-bottom: 20px;
}
</style>
