<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    if (self != top) {
      document.body.style.padding = '6px 16px 16px';
      window.addEventListener('message', this.handlerMessage, false);
      let projectCode = self.location.href
        .split('?')[1]
        ?.split('&')
        ?.map((el) => el.split('='))
        ?.find((el) => {
          return el[0] == 'projectCode';
        })[1];
      window.parent.postMessage(
        {
          projectCode: projectCode,
          sendName: 'getOpenedSidebar',
        },
        '*'
      );
    }
  },
  methods: {
    handlerMessage(e) {
      if (e && e.data) {
        if (
          Object.keys(e.data).length > 0 &&
          e.data.hasOwnProperty('openedSidebar')
        ) {
          this.$store.dispatch('app/updateSideBar', e.data.openedSidebar);
        }
      }
    },
  },
  beforeDestroy() {
    if (self != top) {
      window.removeEventListener('message', this.handlerMessage, false);
    }
  },
};
</script>
