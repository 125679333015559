var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "app-main",
      style: { "min-height": _vm.isIframe ? "100%" : "" },
    },
    [
      _c("transition", { attrs: { name: "fade-transform", mode: "out-in" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.$route.meta.noCache,
                expression: "!$route.meta.noCache",
              },
            ],
          },
          [
            _c(
              "keep-alive",
              { attrs: { include: _vm.cachedViews } },
              [
                !_vm.$route.meta.noCache
                  ? _c("router-view", { key: _vm.key })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [_vm.$route.meta.noCache ? _c("router-view") : _vm._e()],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }