import request from '@/utils/request'
const applicationConfig = {
  // 应用列表
  appList(data) {
    return request({
      url: `/admin/app/list`,
      method: 'get',
      params: data,
    })
  },

  applicationList(data) {
    return request({
      url: `/admin/internal/flydataDimension/appList`,
      method: 'get',
      params: data,
    })
  },

  // 应用添加
  applicationAdd(data) {
    return request({
      url: `/admin/app/appAdd`,
      method: 'post',
      data,
    })
  },

  // 应用置顶
  appPushTop(data) {
    return request({
      url: `/admin/app/appPushTop`,
      method: 'post',
      data,
    })
  },

  // 更新应用状态
  appUpdateStatus(data) {
    return request({
      url: `/admin/app/appUpdateStatus`,
      method: 'post',
      data,
    })
  },

  // 添加广告网络
  addAppNework(data) {
    return request({
      url: `/admin/appNework/addAppNework`,
      method: 'post',
      data,
    })
  },

  // 广告网络列表
  appNeworkList(data) {
    return request({
      url: `/admin/appNework/appNeworkList`,
      method: 'get',
      params: data,
    })
  },

  // 广告网络下拉列表
  appNeworkSelectList(data) {
    return request({
      url: `/admin/appNework/appNeworkSelectList`,
      method: 'get',
      params: data,
    })
  },

  // 广告位列表
  adverSpaceList(data) {
    return request({
      url: `/admin/appSpaceData/page`,
      method: 'get',
      params: data,
    })
  },

  // 广告位类型
  typeSelectList(data) {
    return request({
      url: `/admin/appSpaceData/typeSelectList`,
      method: 'get',
      params: data,
    })
  },

  // 广告位批量添加
  batchAdd(data) {
    return request({
      url: `/admin/appSpaceData/batchAdd`,
      method: 'post',
      data,
    })
  },

  // 广告位移除
  remove(data) {
    return request({
      url: `/admin/appSpaceData/remove`,
      method: 'post',
      data,
    })
  },

  // 流量分组列表
  trafficGroupPage(data) {
    return request({
      url: `/admin/flowSubsetRule/page`,
      method: 'get',
      params: data,
    })
  },

  // 流量分组-删除
  trafficGroupRemove(data) {
    return request({
      url: `/admin/flowSubsetRule/remove`,
      method: 'post',
      data,
    })
  },

  // 操作符
  operatorList(data) {
    return request({
      url: `/admin/flowSubsetRule/operatorList`,
      method: 'get',
      params: data,
    })
  },

  //
  fieldCodeList(data) {
    return request({
      url: `/admin/flowSubsetRule/fieldCodeList`,
      method: 'get',
      params: data,
    })
  },

  // 删除网络
  deleteAppNework(data) {
    return request({
      url: `/admin/appNework/deleteAppNework`,
      method: 'post',
      data,
    })
  },

  // 更新广告位状态
  updateStatus(data) {
    return request({
      url: `/admin/appSpaceData/updateStatus`,
      method: 'post',
      data,
    })
  },

  // 广告位更新
  adverUpdate(data) {
    return request({
      url: `/admin/appSpaceData/update`,
      method: 'post',
      data,
    })
  },

  // 流量分组规则配置-保存
  saveOrUpdate(data) {
    return request({
      url: `/admin/flowSubsetRule/saveOrUpdate`,
      method: 'post',
      data,
    })
  },

  // 流量分组规则配置-详情
  detail(data) {
    return request({
      url: `/admin/flowSubsetRule/detail`,
      method: 'get',
      params: data,
    })
  },

  // 广告位下拉列表
  forSelect(data) {
    return request({
      url: `/admin/appSpaceData/forSelect`,
      method: 'get',
      params: data,
    })
  },

  // 代码位分组tab及策略
  subsetList(data) {
    return request({
      url: `/admin/adBitsSubset/subsetList`,
      method: 'get',
      params: data,
    })
  },

  // 瀑布流管理列表
  dataList(data) {
    return request({
      url: `/admin/adBits/dataList`,
      method: 'post',
      data,
    })
  },

  // 广告位保存
  dataSave(data) {
    return request({
      url: `/admin/adBits/dataSave`,
      method: 'post',
      data,
    })
  },

  dataSave(data) {
    return request({
      url: `/admin/adBits/dataSave`,
      method: 'post',
      data,
    })
  },

  dataDelete(id) {
    return request({
      url: `/admin/adBits/dataDelete/${id}`,
      method: 'delete',
    })
  },

  // 广告位更新
  adBitsUpdateStatus(data) {
    return request({
      url: `/admin/adBits/updateStatus`,
      method: 'post',
      data,
    })
  },

  // 批量添加
  batchAddAd(data) {
    return request({
      url: `/admin/adBits/batchAdd`,
      method: 'post',
      data,
    })
  },

  // 流量分组删除
  subsetDelete(id) {
    return request({
      url: `/admin/adBitsSubset/subsetDelete/${id}`,
      method: 'delete',
    })
  },
  // 瀑布流策略设置
  getConfigByCode(data) {
    return request({
      url: `/admin/strategyTemplate/getConfigByCode`,
      method: 'get',
      params: data,
    })
  },
  // 瀑布流策略-下拉选择
  listForSelect(data) {
    return request({
      url: `/admin/strategyTemplate/listForSelect`,
      method: 'get',
      params: data,
    })
  },
  // 批量添加
  subsetStrategyUpdate(data) {
    return request({
      url: `/admin/adBitsSubset/subsetStrategyUpdate`,
      method: 'post',
      data,
    })
  },

  // 流量分组保存
  subsetSave(data) {
    return request({
      url: `/admin/adBitsSubset/subsetSave`,
      method: 'post',
      data,
    })
  },

  // 瀑布流策略模板
  listForSelect(data) {
    return request({
      url: `/admin/strategyTemplate/listForSelect`,
      method: 'get',
      params: data,
    })
  },

  // 流量分组-删除
  subsetUpdateStatus(data) {
    return request({
      url: `/admin/adBitsSubset/subsetUpdateStatus`,
      method: 'post',
      data,
    })
  },
}
export default applicationConfig
