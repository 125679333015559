<template>
  <div class="app-container">
    <el-dialog
      :visible="visible"
      :title="'编辑'"
      width="500px"
      @close="handleClose"
    >
      <el-row>
        <el-form
          ref="dialogForm"
          :model="dimensionDataSource"
          size="small"
          label-width="80px"
        >
          
          <el-form-item
            label="广告网络"
            prop="adNetworkCode"
            :rules="{
              required: true,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-select
                v-model="dimensionDataSource.adNetworkCode"
                placeholder="请选择"
                clearable
                style="width: 220px"
            >
                <el-option
                    v-for="item in adverNetList"
                    :key="item.adNetworkCode"
                    :label="item.adNetworkName"
                    :value="item.adNetworkCode"
                />
            </el-select>
          </el-form-item>
          <el-form-item
              label="类型"
              prop="type"
              :rules="{
                required: true,
                message: '请输入',
                trigger: 'blur',
              }"
            >
              <el-select
                v-model="dimensionDataSource.type"
                placeholder="请选择"
                clearable
                style="width: 220px"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          <el-form-item
            label="代码位名称"
            prop="adBitsName"
            :rules="{
              required: true,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model.trim="dimensionDataSource.adBitsName"
              placeholder="请输入"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="代码位ID"
            prop="adBitsCode"
            :rules="{
              required: true,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model.trim="dimensionDataSource.adBitsCode"
              placeholder="请输入"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="dimensionDataSource.type==2"
            label="排序价格"
            prop="price"
            :rules="{
              required: true,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model.trim="dimensionDataSource.price"
              placeholder="请输入"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          
          
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import applicationConfigApi from '@/api/pages/applicationConfig';

export default {
  name: 'EditLabelModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dimensionType: {
      type: String,
      default: null,
    },
    adSpaceDataId:{},
    dimensionData:{},
    adverNetList:{},
    itemData:{}
  },
  data() {
    return {
      dimensionDataSource: {
        adNetworkCode:'', 
        type: '',
        adBitsName:'',
        adBitsCode:'',
        price: ''
      },
      tacticsData:[],
      groupRuleData:[],
      typeList:[
        {code:  1, name: '客户端竞价'},
        {code:  2, name: '按价格'},
        {code:  3, name: '兜底'},
      ]
    };
  },

  watch: {
    visible: {
      handler: function (val, oldVal) {
        if (val && this.$parent.itemId) {
            this.dimensionDataSource = {
                adNetworkCode: this.itemData.adNetworkCode,
                type: this.itemData.type,
                adBitsName: this.itemData.adBitsName,
                adBitsCode: this.itemData.adBitsCode,
                price: this.itemData.price,
                ...this.itemData
            }
        }
      },
      deep: true,
    },
  },

  mounted() {
      this.getTacticsSelectData() //策略模板下拉
      this.getGroupData() //分组规则
 
  },

  methods: {
    //策略模板下拉
    getTacticsSelectData() {
        applicationConfigApi.listForSelect().then((res)=> {
            if(res && res.code == 1 && res.data) {
                this.tacticsData = res.data
            }
        })
    },

    //分组规则
    getGroupData() {
        applicationConfigApi.trafficGroupPage({
            pageNum: 1,
            pageSize: 1000,
            status: 1
        }).then((res) => {
            if (res && res.code === 1 && res.data) {
                this.groupRuleData = res.data.list;
            }
        })
        .catch((e) => {
            console.log(e);
        });
    },
    
    handleClose() {
      this.$refs.dialogForm.resetFields();
      this.$emit('close');
    },

    submitData() {
      let that = this;
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(that.dimensionDataSource));
          applicationConfigApi.dataSave(data).then((res) => {
            if (res && res.code == 1) {
              this.$message.success('保存成功');
              this.handleClose();
              this.$parent.getTabtacticsData();
            } else {
              this.$message.error('保存失败');
            }
          })
          .catch((e) => {
            console.log('e', e);
          });
        }
      });
    },

  },
};
</script>

<style scoped lang="scss">
.dialog-footer {
  text-align: center;
  display: block;
}
</style>
