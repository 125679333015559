var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: "添加应用",
            width: "1000px",
            inline: "",
          },
          on: { close: _vm.handleClose },
        },
        [
          _c(
            "el-row",
            { staticClass: "add-project", attrs: { type: "flex" } },
            [
              _c("div", { staticClass: "transfer" }, [
                _c(
                  "div",
                  { staticClass: "transfer-left" },
                  [
                    _c(
                      "div",
                      { staticClass: "search-box" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            type: "text",
                            placeholder: "请输入应用名称/ID搜索",
                          },
                          model: {
                            value: _vm.searchQuery,
                            callback: function ($$v) {
                              _vm.searchQuery = $$v
                            },
                            expression: "searchQuery",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "all-select",
                            on: { click: _vm.selectAll },
                          },
                          [_vm._v("全选")]
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.filteredOptions, function (item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "projectList" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "projectList-item",
                              attrs: { for: "option-" + item.id },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedValues,
                                    expression: "selectedValues",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "option-" + item.id,
                                },
                                domProps: {
                                  value: item.id,
                                  checked: Array.isArray(_vm.selectedValues)
                                    ? _vm._i(_vm.selectedValues, item.id) > -1
                                    : _vm.selectedValues,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.selectedValues,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = item.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectedValues = $$a.concat([
                                            $$v,
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectedValues = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectedValues = $$c
                                    }
                                  },
                                },
                              }),
                              _c("div", { staticClass: "project-name-box" }, [
                                _c("div", { staticClass: "project-name" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(_vm._s(item.appName)),
                                  ]),
                                  _c("div", { staticClass: "projectId" }, [
                                    _vm._v("id:" + _vm._s(item.id)),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "transfer-right" }, [
                _c("div", { staticClass: "right-title" }, [
                  _c("div", { staticClass: "title-left" }, [_vm._v("已选0")]),
                  _c(
                    "div",
                    {
                      staticClass: "title-right all-select",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clearSelect($event)
                        },
                      },
                    },
                    [_vm._v("清空全部")]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "right-project-list" },
                  _vm._l(_vm.selectedOptions, function (item) {
                    return _c(
                      "div",
                      { key: item.appName, staticClass: "list-item" },
                      [_vm._v(_vm._s(item.appName))]
                    )
                  }),
                  0
                ),
              ]),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitData } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }