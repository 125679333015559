import request from '@/utils/request';
const proApi = {
  /*
    数仓租户库
  */

  //有效列表
  tenantDbList(data) {
    return request({
      url: `/admin/tenantDb/activeList`,
      method: 'get',
      params: data,
    });
  },
  //列表
  tenantDbPage(data) {
    return request({
      url: `/admin/tenantDb/page`,
      method: 'get',
      params: data,
    });
  },
  //详情
  tenantDbDetail(data) {
    return request({
      url: `/admin/tenantDb/detail`,
      method: 'get',
      params: data,
    });
  },
  //新增编辑
  tenantDbSave(data) {
    return request({
      url: `/admin/tenantDb/save`,
      method: 'post',
      data,
    });
  },

  //更新状态
  tenantUpdateStatus(data) {
    return request({
      url: `/admin/tenantDb/updateStatus`,
      method: 'post',
      data,
    });
  },
  /*
    分流管理
  */
  //回调数据类型列表
  callbackDataTypeList(data) {
    return request({
      url: `/admin/splitflowConfig/callbackDataTypeList`,
      method: 'get',
      params: data,
    });
  },
  //详情
  splitflowConfigDetail(data) {
    return request({
      url: `/admin/splitflowConfig/detail`,
      method: 'get',
      params: data,
    });
  },
  //新增编辑
  splitflowConfigSave(data) {
    return request({
      url: `/admin/splitflowConfig/save`,
      method: 'post',
      data,
    });
  },
  //租户库分流列表
  tenantDbSplitflowList(data) {
    return request({
      url: `/admin/splitflowConfig/tenantDbSplitflowList`,
      method: 'get',
      params: data,
    });
  },
  //新增编辑
  splitflowConfigUpdateStatus(data) {
    return request({
      url: `/admin/splitflowConfig/updateStatus`,
      method: 'post',
      data,
    });
  },

  /*
    产品配置
  */

  //详情
  productDetail(data) {
    return request({
      url: `/admin/product/detail`,
      method: 'get',
      params: data,
    });
  },
  //列表
  productList(data) {
    return request({
      url: `/admin/product/list`,
      method: 'get',
      params: data,
    });
  },
  //产品类型列表
  productTypeList(data) {
    return request({
      url: `/admin/product/productTypeList`,
      method: 'get',
      params: data,
    });
  },
  //新增编辑
  productSave(data) {
    return request({
      url: `/admin/product/save`,
      method: 'post',
      data,
    });
  },
  //更新状态
  productUpdateStatus(data) {
    return request({
      url: `/admin/product/updateStatus`,
      method: 'post',
      data,
    });
  },

  /*
    app管理
  */
  //客户端类型列表
  clientTypeList(data) {
    return request({
      url: `/admin/app/clientTypeList`,
      method: 'get',
      params: data,
    });
  },
  //详情
  appDetail(data) {
    return request({
      url: `/admin/app/detail`,
      method: 'get',
      params: data,
    });
  },
  //产品app列表
  productAppList(data) {
    return request({
      url: `/admin/app/productAppList`,
      method: 'get',
      params: data,
    });
  },
  //新增、编辑
  appSave(data) {
    return request({
      url: `/admin/app/save`,
      method: 'post',
      data,
    });
  },
  //更新状态
  appUpdateStatus(data) {
    return request({
      url: `/admin/app/updateStatus`,
      method: 'post',
      data,
    });
  },

  /**
   * @description: 项目管理
   * @return {*}
   */

  //详情
  projectDetail(params) {
    return request({
      url: `/admin/project/detail`,
      method: 'get',
      params,
    });
  },
  //列表
  projectList(params) {
    return request({
      url: `/admin/project/list`,
      method: 'get',
      params,
    });
  },
  //新增、编辑
  projectSave(data) {
    return request({
      url: `/admin/project/save`,
      method: 'post',
      data,
    });
  },
  //下拉列表
  projectSelectList(params) {
    return request({
      url: `/admin/project/selectList`,
      method: 'get',
      params,
    });
  },
  //更新状态
  projectUpdateStatus(data) {
    return request({
      url: `/admin/project/updateStatus`,
      method: 'post',
      data,
    });
  },

  // 维度类型设置
  listProjectId(params) {
    return request({
      url: `/admin/dataDimType/listProjectId`,
      method: 'get',
      params,
    });
  },

  // 维度类型新增
  addSave(data) {
    return request({
      url: `/admin/dataDimType/add`,
      method: 'post',
      data,
    });
  },

  //维度类型详情
  dimDetailById(params) {
    return request({
      url: `/admin/dataDimType/detailById`,
      method: 'get',
      params,
    });
  },


  // 更新状态
  dataDimUpdateStatus(params) {
    return request({
      url: `/admin/dataDimType/updateStatus`,
      method: 'get',
      params,
    });
  },


  /**
   * @description: 数据源
   * @return {*}
   */
  // 数据源列表
  pageList(data) {
    return request({
      url: `/admin/dataSourceConfig/pageList`,
      method: 'post',
      data,
    });
  },


  // 数据源新增
  dataSourceAdd(data) {
    return request({
      url: `/admin/dataSourceConfig/add`,
      method: 'post',
      data,
    });
  },

  // 数据源类型
  listDataSourceType(params) {
    return request({
      url: `/admin/dataSourceConfig/listDataSourceType`,
      method: 'get',
      params,
    });
  },

  // 数据源详情
  detailById(params) {
    return request({
      url: `/admin/dataSourceConfig/detailById`,
      method: 'get',
      params,
    });
  },

  // 数据源更新状态
  dataSourceUpdateStatus(params) {
    return request({
      url: `/admin/dataSourceConfig/updateStatus`,
      method: 'get',
      params,
    });
  },


  // 数据源测试链接
  testConnect(data) {
    return request({
      url: `/admin/dataSourceConfig/testConnect`,
      method: 'post',
      data,
    });
  },

  
  // 查看日志
  logList(data) {
    return request({
      url: `/admin/data/DataDimSyncLog/list`,
      method: 'post',
      data,
    });
  },

  
  getSyncedFields(data) {
    return request({
      url: `/admin/data/DataDimConfig/getSyncedFields`,
      method: 'post',
      data,
    });
  },

  

  getSyncedData(data) {
    return request({
      url: `/admin/data/DataDimConfig/getSyncedData`,
      method: 'post',
      data,
    });
  },

  //获取项目初始化配置项列表
  getInitProjectConfigItemList(data) {
    return request({
      url: `/admin/initProject/getInitProjectConfigItemList`,
      method: 'get',
      params: data,
    });
  },

  //项目配置项的初始化操作
  initProjectConfigItem(data) {
    return request({
      url: `/admin/initProject/initProjectConfigItem`,
      method: 'post',
      data,
    });
  },

  //获取项目租户库表的配置和事件的配置的比对数据
  getTenantDbTableColCompareList(data) {
    return request({
      url: `/admin/initTenantDbTable/getTenantDbTableColCompareList`,
      method: 'get',
      params: data,
    });
  },

  //获取项目租户库主要表名
  getTenantDbTableNameList(data) {
    return request({
      url: `/admin/initTenantDbTable/getTenantDbTableNameList`,
      method: 'get',
      params: data,
    });
  },

   
  trafficUpdateStatus(data) {
    return request({
      url: `/admin/flowSubsetRule/updateStatus`,
      method: 'post',
      data,
    })
  },

  //生成回调密钥
  genCallbackSecret(data) {
    return request({
      url: `/admin/appSpaceData/genCallbackSecret`,
      method: 'get',
      params: data,
    });
  },

};

export default proApi;
