import { render, staticRenderFns } from "./EditAdverSpace.vue?vue&type=template&id=084cdbc4&scoped=true"
import script from "./EditAdverSpace.vue?vue&type=script&lang=js"
export * from "./EditAdverSpace.vue?vue&type=script&lang=js"
import style0 from "./EditAdverSpace.vue?vue&type=style&index=0&id=084cdbc4&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084cdbc4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data0/web/xyadmin.98du.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('084cdbc4')) {
      api.createRecord('084cdbc4', component.options)
    } else {
      api.reload('084cdbc4', component.options)
    }
    module.hot.accept("./EditAdverSpace.vue?vue&type=template&id=084cdbc4&scoped=true", function () {
      api.rerender('084cdbc4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/productAppConfig/productConfig/projectManage/components/EditAdverSpace.vue"
export default component.exports