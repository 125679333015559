// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "rgba(0, 0, 0, 0.65)",
	"menuActiveText": "#3D7FFF",
	"subMenuActiveText": "#3D7FFF",
	"menuBg": "#fff",
	"menuHover": "#F1F4FC",
	"subMenuBg": "#fff",
	"subMenuHover": "#3D7FFF",
	"sideBarWidth": "200px",
	"subActiveMenuBg": "#f3f7ff"
};
module.exports = exports;
