import store from '@/store'

function checkPermission(el, binding) {
  // console.log("binding:"+binding)
  // for(let x in binding){
  //   console.log(x+"--binding:"+binding[x])
  // }
  const { value } = binding
  const arr = ['addDemo']

  const permissionList = store.getters && store.getters.userPermission
  const i = permissionList.indexOf(value)
  if (i == -1) {
    el.parentNode && el.parentNode.removeChild(el)
  }

  // if (value && value instanceof Array) {
  //   if (value.length > 0) {
  //     const permissionRoles = value
  //
  //     const hasPermission = roles.some(role => {
  //       return permissionRoles.includes(role)
  //     })
  //
  //     if (!hasPermission) {
  //       el.parentNode && el.parentNode.removeChild(el)
  //     }
  //   }
  // } else {
  //   throw new Error(`need roles! Like v-permission="['admin','editor']"`)
  // }
}

export default {
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  update(el, binding) {
    checkPermission(el, binding)
  }
}
