var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "流量分组管理",
            visible: _vm.visible,
            direction: "rtl",
            "before-close": _vm.handleClose,
            size: 1200,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "drawerbox" },
            [
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  attrs: {
                    model: _vm.dataSource,
                    size: "small",
                    "label-width": "50px",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.editDimension },
                            },
                            [_vm._v("新增流量分组")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "1100px" },
                              attrs: {
                                size: "small",
                                data: _vm.dataList,
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  fixed: "left",
                                  width: "130",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editDimension(
                                                  scope.row,
                                                  "edit"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑")]
                                        ),
                                        _c(
                                          "el-popconfirm",
                                          {
                                            attrs: { title: "确定要删除吗？" },
                                            on: {
                                              confirm: function ($event) {
                                                return _vm.deleteData(
                                                  scope.row.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "el-button-text-danger",
                                                staticStyle: {
                                                  "margin-left": "30px",
                                                },
                                                attrs: {
                                                  slot: "reference",
                                                  type: "text",
                                                  size: "small",
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "status",
                                  label: "状态",
                                  align: "center",
                                  width: "100",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-popconfirm",
                                          {
                                            attrs: {
                                              title: "确定要更改状态吗？",
                                            },
                                            on: {
                                              confirm: function ($event) {
                                                return _vm.updateAdverStatus(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("el-switch", {
                                              staticStyle: {
                                                "margin-left": "30px",
                                              },
                                              attrs: {
                                                slot: "reference",
                                                value: scope.row.status,
                                                "active-value": 1,
                                                "inactive-value": 0,
                                              },
                                              slot: "reference",
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "id", label: "分组ID" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "name", label: "分组名称" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "sortNo", label: "优先级" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "waterfallStrategyName",
                                  label: "瀑布流策略",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "flowSubsetRuleName",
                                  label: "流量分组策略",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.flowSubsetRuleName
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "strategyName",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.traffGroupClick(
                                                      scope.row
                                                        .flowSubsetRuleId,
                                                      "edit"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.flowSubsetRuleName
                                                  ) +
                                                    "(" +
                                                    _vm._s(
                                                      scope.row.flowSubsetRuleId
                                                    ) +
                                                    ")"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "createTime",
                                  label: "创建时间",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("EditLabelModal", {
        attrs: {
          visible: _vm.labelConfigVisible,
          dimensionType: _vm.dimensionType,
          dimensionId: _vm.dimensionId,
          adSpaceDataId: _vm.adSpaceDataId,
          dimensionData: _vm.dimensionData,
        },
        on: {
          close: function ($event) {
            _vm.labelConfigVisible = false
          },
        },
      }),
      _c("AddtrafficGroupRule", {
        attrs: {
          visible: _vm.trafficGroupVisible,
          editType: _vm.editType,
          symbolList: _vm.symbolList,
          ruleConfigList: _vm.ruleConfigList,
        },
        on: {
          close: function ($event) {
            _vm.trafficGroupVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }