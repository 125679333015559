import request from '@/utils/request'
import requestNew from '@/utils/requestNew'

export function login(data) {
  return request({
    url: '/admin/main/login',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/admin/main/userInfo',
    method: 'get',
    params: { token }
  })
}
export function getUserPermission(token) {
  return request({
    url: '/admin/main/userPermission',
    method: 'get',
    params: { token }
  })
}

export function logout() {
  return request({
    url: '/admin/main/logout',
    method: 'post'
  })
}
