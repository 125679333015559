var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "share-dropdown-menu", class: { active: _vm.isActive } },
    [
      _c(
        "div",
        { staticClass: "share-dropdown-menu-wrapper" },
        [
          _c(
            "span",
            {
              staticClass: "share-dropdown-menu-title",
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.clickTitle($event)
                },
              },
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm._l(_vm.items, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "share-dropdown-menu-item" },
              [
                item.href
                  ? _c("a", { attrs: { href: item.href, target: "_blank" } }, [
                      _vm._v(_vm._s(item.title)),
                    ])
                  : _c("span", [_vm._v(_vm._s(item.title))]),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }