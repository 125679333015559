var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
        _vm._v(" Your roles: " + _vm._s(_vm.roles) + " "),
      ]),
      _vm._v(" Switch roles: "),
      _c(
        "el-radio-group",
        {
          model: {
            value: _vm.switchRoles,
            callback: function ($$v) {
              _vm.switchRoles = $$v
            },
            expression: "switchRoles",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: "editor" } }),
          _c("el-radio-button", { attrs: { label: "admin" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }