var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _vm._v(
        " Creating and editing pages cannot be cached by keep-alive because keep-alive include does not currently support caching based on routes, so it is currently cached based on component name. If you want to achieve a similar caching effect, you can use a browser caching scheme such as localStorage. Or do not use keep-alive include to cache all pages directly. See details "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://panjiachen.github.io/vue-element-admin-site/guide/essentials/tags-view.html",
            target: "_blank",
          },
        },
        [_vm._v("Document")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }