<template>
  <div class="app-container">
    <el-dialog
      :visible="visible"
      :title="'添加广告网络'"
      width="500px"
      @close="handleClose"
    >
      <el-row>
        <el-form
          ref="dialogForm"
          :model="dataSource"
          size="small"
          label-width="100px"
        >
            <el-form-item
                label="广告网络"
                prop="adNetworkCode"
                :rules="{
                    required: true,
                    message: '请选择',
                    trigger: 'change',
                }"
            >
                <el-select
                    v-model="dataSource.adNetworkCode"
                    clearable
                    style="width: 340px"
                >
                <el-option
                    v-for="item in adverNetpullDownList"
                    :key="item.code"
                    :value="item.code"
                    :label="item.name"
                ></el-option>
                </el-select>
            </el-form-item>
          <el-form-item
            label="接入应用ID"
            prop="adNetworkAppId"
            :rules="{
              required: true,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model.trim="dataSource.adNetworkAppId"
              placeholder="请输入"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import proApi from '@/api/pages/proApi';
import applicationConfigApi from '@/api/pages/applicationConfig';

export default {
  name: 'EditLabelModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dimensionType: {
      type: String,
      default: null,
    },
    configAppId:{
      type: Number,
      default: 0
    }
    
  },
  data() {
    return {
      dataSource: {
        adNetworkAppId: '',
        adNetworkCode: '',
      },
      
      adverNetpullDownList:[]
    };
  },

  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          this.getAdverSelectData()
        }
      },
      deep: true,
    },
  },

  mounted() {},

  methods: {
    getAdverSelectData() {
      applicationConfigApi.appNeworkSelectList().then((res) => {
        if (res && res.code == 1 && res.data) {
          this.adverNetpullDownList = res.data;
        }
      });
    },

    handleClose() {
      this.$refs.dialogForm.resetFields();
      this.$emit('close');
    },

    submitData() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.dataSource));
          data.configAppId = this.configAppId
          applicationConfigApi.addAppNework(data).then((res) => {
            if (res && res.code == 1) {
              this.$message.success('保存成功');
              this.handleClose();
              this.$parent.getAdverNetList();
            } else {
              this.$message.error('保存失败');
            }
          })
          .catch((e) => {
            console.log('e', e);
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-footer {
  text-align: center;
  display: block;
}
</style>
