<!-- 编辑 -->
<template>
  <div class="app-container">
    <el-drawer :title="`瀑布流策略`" :visible.sync="visible" direction="rtl" :before-close="handleClose" :size="900">
      <el-row class="drawerbox">
        <el-form ref="dialogForm" :model="dataSource" size="small" label-width="165px">
          <el-row v-for="(config, index) in WaterfallFlowConfig.configDataObj" :key="index">
            <el-row class="config_title">{{ config.title }}</el-row>
            <el-form-item
              v-for="(item, cindex) in config.items"
              :key="item.inputName"
              :label="item.inputType != 'radio' ? item.label : ''"
              label-width="200px"
              :prop="item.inputName"
              :rules="{
                required: item.star ? true : false,
                message: '请输入',
                trigger: 'blur',
              }"
            >
              <el-row v-if="item.inputType == 'number'">
                <el-input-number v-model.trim="dataSource[item.inputName]" :label="item.label"></el-input-number>
                <span class="item_unit">{{ item.unit }}</span>
              </el-row>
              <el-row v-else-if="item.inputType == 'radio'">
                <el-row class="config_describe">{{ item.label }}</el-row>
                <el-radio-group v-model="dataSource[item.inputName]">
                  <el-radio v-for="(ritem, rindex) in item.radioItems" :key="rindex" :label="ritem.value">{{ ritem.label }}</el-radio>
                </el-radio-group>
              </el-row>
              <!-- <el-row v-else-if="item.inputType == 'select '">
                <el-select
                  v-model="dataSource[item.inputName]"
                  placeholder="请选择"
                  clearable
                  :style="{ width: width + 'px' }"
                  @change="selectChange()"
                >
                  <el-option
                    v-for="item in projectList"
                    :key="item.projectCode"
                    :label="item.projectName"
                    :value="item.projectCode"
                  />
                </el-select>
              </el-row> -->
            </el-form-item>
          </el-row>
          <el-form-item style="text-align: center; margin-top: 80px">
            <el-button size="small" @click="handleClose">取消</el-button>
            <el-button size="small" type="primary" @click="save()">保存</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
import applicationConfigApi from '@/api/pages/applicationConfig'

export default {
  name: 'DimensionTypeSet',
  components: {
    // EditLabelModal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    waterfallStrategyCode: {
      type: String,
      default: null,
    },
    waterfallStrategyData: {},
    subsetListId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      dataSource: {},
      WaterfallFlowConfig: [],
    }
  },
  watch: {
    visible: {
      handler: function (val, oldVal) {
        if (val) {
          this.getWaterfallFlowConfigByCode()
        } else {
          this.WaterfallFlowConfig = []
        }
      },
      deep: true,
    },
  },
  mounted() {},

  methods: {
    // 获取瀑布流策略
    getWaterfallFlowConfigByCode() {
      const that = this
      applicationConfigApi.getConfigByCode({ code: this.waterfallStrategyCode }).then((res) => {
        if (res.code == 1 && res.data) {
          that.WaterfallFlowConfig = res.data
          that.dataSource = that.waterfallStrategyData ? JSON.parse(that.waterfallStrategyData) : {}
          if (that.dataSource && that.WaterfallFlowConfig) {
            that.WaterfallFlowConfig.configDataObj?.forEach((config) => {
              config.items?.forEach((item) => {
                if (item.inputType == 'number') {
                  that.dataSource[item.inputName] = parseFloat(that.dataSource[item.inputName] || 0)
                  console.log('9898989', that.dataSource)
                }
              })
            })
          }
          console.log(that.dataSource)
        }
      })
    },
    //保存广告位
    save() {
      let paramsObj = {}
      this.WaterfallFlowConfig.configDataObj?.forEach((childrenItem, index) => {
        childrenItem.items?.forEach((item, index) => {
          const fieldName = item.inputName
          paramsObj[fieldName] = this.dataSource[fieldName]
        })
      })
      console.log('that.dataSource', paramsObj)
      // return
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          applicationConfigApi
            .subsetStrategyUpdate({ strategyDataDto: paramsObj, id: this.subsetListId })
            .then((res) => {
              if (res && res.code == 1) {
                this.$message.success('保存成功')
                this.$refs.dialogForm.resetFields()
                this.$emit('close')
              } else {
                this.$message.error('保存失败')
              }
            })
            .catch((e) => {
              console.log('e', e)
            })
        }
      })
    },
    handleClose() {
      this.$refs.dialogForm.resetFields()
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-drawer__body {
  padding: 0 20px;
}
.config_title {
  margin-bottom: 10px;
  width: 182px;
  text-align: right;
}
.config_describe {
  font-size: 14px;
  margin-bottom: 10px;
}
.item_unit {
  margin-left: 10px;
}
.el-form-item.is-required .config_describe:before {
  content: '*';
  color: #ff4949;
  margin-right: 0;
  display: inline;
}
</style>
