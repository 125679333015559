<!-- 新增、编辑 -->
<template>
  <div class="app-container">
    <el-drawer
      :title="!editType ? `新建流量分组规则配置` : '编辑流量分组规则配置'"
      :visible.sync="visible"
      direction="rtl"
      :before-close="handleClose"
      :size="1000"
    >
      <!-- <el-row class="drawerbox"> -->
        <el-form
          ref="dialogForm"
          :model="dataSource"
          size="small"
          label-width="100px"
        >
            <el-form-item
                label="名称"
                prop="name"
                :rules="{
                    required: true,
                    message: '请选择',
                    trigger: 'change',
                }"
            >
                <el-input
                    v-model.trim="dataSource.name"
                    placeholder="请输入"
                    style="width: 400px"
                ></el-input>
            </el-form-item>
          <!-- <div> -->
            <el-form-item label='流量分组规则'>
              <el-button size="small" type="text" @click="addParams"
                >新增规则项</el-button
              >
            </el-form-item>

            <!-- <el-col
                class="select-templete"
                style="display: flex; align-items: center"
                >流量分组规则<i
                  class="el-icon-plus"
                  style="color: #46a6ff"
                  @click.stop="addParams"
                ></i
                ><el-button @click.stop="addParams" type="text" class="add-text"
                  >新增规则项</el-button
                ></el-col
              > -->

            <el-col
                style="
                  display: flex;
                  align-items: center;
                  margin-left: 88px;
                  margin-bottom: 15px;
                    width: 90%;
                "
                v-for="(item, index) in dataSource.items"
                :key="index"
              >
                <el-form-item
                  :prop="'items.' + index + '.fieldCode'"
                  label=""
                  style="margin-bottom: 0"
                >
                  <el-select
                    class="gap"
                    style="width: 180px; margin-left: -87px"
                    v-model="dataSource.items[index].fieldCode"
                    clearable
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(citem, cindex) in ruleConfigList"
                      :key="cindex"
                      :label="citem.name"
                      :value="citem.fieldCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="dataSource.items[index].fieldCode == ''"
                  :prop="'items.' + index + '.filterField'"
                  label=""
                  style="margin-bottom: 0"
                  class="formLable"
                  label-width="20px"
                >
                  <el-input
                    v-model="dataSource.items[index].filterField"
                    placeholder="请输入"
                    style="width: 180px"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :prop="'items.' + index + '.operatorChar'"
                  label=""
                  style="margin-bottom: 0"
                >
                  <el-select
                    class="gap"
                    style="width: 180px; margin-left: -87px"
                    v-model="dataSource.items[index].operatorChar"
                    clearable
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(citem, cindex) in symbolList"
                      :key="cindex"
                      :label="citem.name"
                      :value="citem.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  :prop="'items.' + index + '.filterVal'"
                  label=""
                  style="margin-bottom: 0"
                  class="formLable"
                  label-width="20px"
                >
                  <el-input
                    v-model="dataSource.items[index].filterVal"
                    placeholder="请输入（输入多个的话用逗号隔开）"
                    :style='{"margin-bottom": 0, "width": dataSource.items[index].fieldCode == "" ? "200px":"400px"}'
                    clearable
                  ></el-input>
                </el-form-item>
                <i
                  v-if="dataSource.items.length > 1"
                  class="el-icon-remove-outline"
                  @click.stop="delParams(index)"
                  style="
                    color: rgb(240, 12, 12);
                    font-size: 24px;
                    margin-left: 15px;
                    height: 24px;
                  "
                ></i>
              </el-col>

              <el-col>
                <el-form-item prop="status" label="状态">
                  <el-switch
                      v-model="dataSource.status"
                      :active-value="1"
                      :inactive-value="0"
                      />
                  </el-form-item>
              </el-col>

              <el-col>
                    <el-form-item
                        label="规则说明"
                        prop="remark"
                        :rules="{
                        required: true,
                        message: '请输入',
                        trigger: 'blur',
                        }"
                    >
                        <el-input
                            v-model.trim="dataSource.remark"
                            placeholder="请输入"
                            type="textarea"
                            rows="5"
                            clearable
                            style="width: 400px"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col class="save-btn">
                   <el-button size="small" @click="handleClose">取消</el-button>
                  <el-button size="small" type="primary" @click="save">保存</el-button>
                </el-col>
                
                <!-- <el-col>
                  <el-form-item style="text-align:center; margin-top: 80px">
                    <el-button size="small" @click="handleClose">取消</el-button>
                    <el-button size="small" type="primary" @click="save">保存</el-button>
                  </el-form-item>
                </el-col> -->
                
          <!-- </div> -->
          
        </el-form>
        
      <!-- </el-row> -->
    </el-drawer>
    <!-- <EditLabelModal
      :visible="labelConfigVisible"
      :dimensionType="dimensionType"
      :dimensionId="dimensionId"
      @close="labelConfigVisible = false"
    ></EditLabelModal> -->
  </div>
</template>

<script>
import proApi from '@/api/pages/proApi';
// import EditLabelModal from './EditLabelModal';
import applicationConfigApi from '@/api/pages/applicationConfig';

export default {
  name: 'DimensionTypeSet',
  components: {
    // EditLabelModal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    projectId:{
      type: Number,
      default: null
    },
    editType:{},
    ruleConfigList:{
      type: Array,
      default:[]
    },
    symbolList:{
      type: Array,
      default:[]
    }
  },

  data() {
    return {
      dataSource: {
        name: '',
        status: 0,
         items: [
        {
          fieldCode:'',
          operatorChar:'',
          filterVal: '',
          filterField:''
        },
      ],
        remark:''
      },
     
      projectList: [],
      labelConfigVisible: false,
      dimensionList: [],
      dimensionType: '',
      dimensionId: '',
      adverTypeList:[
          {code: 1, name: '插全屏1'},
          {code: 2, name: '插全屏2'},
      ],
      appResourceDetailsList: [
        {
            adverType:'',
            name:''
        },
      ],
      
     
      
    };
  },
  watch: {
    visible: {
      handler: function (val, oldVal) {
        if (val&& this.editType && this.$parent.detailId) {
          this.getTraffGroupDetail()
        } 
      },
      deep: true,
    },
    
  },
  mounted() {

  },

  methods: {
    getTraffGroupDetail() {
      applicationConfigApi.detail({
        id: this.$parent.detailId
      }).then((res) => {
        if (res && res.code == 1 && res.data) {
          this.dataSource = res.data
          // if(this.dataSource.items && this.dataSource.items.length && this.dataSource.items.length > 0) {
          //   this.dataSource.items = this.dataSource.items
          // }else {
          //   this.dataSource.items=[
          //     {
          //       fieldCode:'',
          //       operatorChar:'',
          //       filterVal: '',
          //       filterField:''
          //     }
          //   ]
          // }
          
        } 
      })
    },

    save() {
      let that = this;
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(that.dataSource));
          data.id =this.$parent.detailId
          data.items = data.items.map((item, index)=> {
            return {
              fieldCode: item.fieldCode,
              // "filterField": "",
              filterVal: item.filterVal,
              id: item.id,
              operatorChar: item.operatorChar,
               filterField:item.filterField
            }
          })
          applicationConfigApi.saveOrUpdate(data).then((res) => {
            if (res && res.code == 1) {
              this.$message.success('保存成功');
              this.handleClose();
              this.$parent.initData();
            } else {
              this.$message.error('保存失败');
            }
          })
          .catch((e) => {
            console.log('e', e);
          });
        }
      });
     
    },

    handleClose() {
      this.dataSource= {
        name: '',
        status: 0,
         items: [
        {
          fieldCode:'',
          operatorChar:'',
          filterVal: '',
          // filterField:''
        },
      ],
        remark:''
      },
      this.$emit('close');
    },

    

    addParams() {
      console.log('898988=========', this.dataSource.items)
      this.dataSource.items.push(
        {
          fieldCode:'',
          operatorChar:'',
          filterVal: '',
          filterField:''
        }
      );
      console.log('898988========111=', this.dataSource.items)

    },
    delParams(index) {
      this.dataSource.items.splice(index, 1);
    },

     //新增
    addTable() {
      this.appResourceDetailsList.push({
        adverType:'',
        name:''
      });
    },

    //删除
    delTable(index) {
      this.appResourceDetailsList.splice(index, 1);
    },

    editDimension(id, type) {
      this.labelConfigVisible = true
      this.dimensionId = id
      this.dimensionType = type
    },

    //操作状态
    dimUpdateStatus(e, row, index) {
      let msg = row.status == 1 ? '确定关闭？' : '确定开启？';
      let val = row.status == 1 ? 0 : 1;
      this.$confirm(msg, '提示', { type: 'warning' }).then(() => {
        proApi.dataDimUpdateStatus({ id: row.id, status: val })
          .then((res) => {
            if (res && res.code === 1) {
              this.$message.success('修改成功');
              this.getDimensionData();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },

    

    handleProject() {
      this.dataSource.appId = '';
    },
    
    // 获取名称
    getFieldName(list, key, val) {
      if (list && list.length > 0) {
        let obj = list.filter((item) => {
          return item[key] == val;
        });
        if (obj.length > 0) {
          return obj[0].name;
        }
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-footer {
  text-align: center;
  display: block;
  margin-top: 20px;
}
.txt {
  color: red;
  font-size: 12px;
  margin-left: 10px;
}
.txt1 {
  font-size: 12px;
  margin: 0 10px;
}
.flex {
  display: flex;
}
.tips_icon {
  margin: 0 10px;
  position: relative;
  top: 6px;
}
.labelCenter {
  align-items: center;
}
.lab_title {
  width: 130px;
  text-align: right;
  padding-right: 18px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.mon_box {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 20px;
}
.drawerbox {
  padding-bottom: 40px;
}
.time_tips {
  font-size: 12px;
  color: red;
  line-height: 20px;
}
.save-btn {
  text-align: center;
  margin-top: 50px;
} 
</style>
