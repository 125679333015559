var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: !_vm.dimensionType ? "新建" : "编辑",
            width: "500px",
          },
          on: { close: _vm.handleClose },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  attrs: {
                    model: _vm.dimensionDataSource,
                    size: "small",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "名称",
                        prop: "name",
                        rules: {
                          required: true,
                          message: "请输入",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.dimensionDataSource.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dimensionDataSource,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "dimensionDataSource.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "瀑布流策略模板",
                        prop: "waterfallStrategyCode",
                        rules: {
                          required: true,
                          message: "请输入",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value:
                              _vm.dimensionDataSource.waterfallStrategyCode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dimensionDataSource,
                                "waterfallStrategyCode",
                                $$v
                              )
                            },
                            expression:
                              "dimensionDataSource.waterfallStrategyCode",
                          },
                        },
                        _vm._l(_vm.tacticsData, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "流量分组规则",
                        prop: "flowSubsetRuleId",
                        rules: {
                          required: false,
                          message: "请输入",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.dimensionDataSource.flowSubsetRuleId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dimensionDataSource,
                                "flowSubsetRuleId",
                                $$v
                              )
                            },
                            expression: "dimensionDataSource.flowSubsetRuleId",
                          },
                        },
                        _vm._l(_vm.groupRuleData, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "权重",
                        prop: "sortNo",
                        rules: {
                          required: true,
                          message: "请输入",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.dimensionDataSource.sortNo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dimensionDataSource,
                              "sortNo",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "dimensionDataSource.sortNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          type: "textarea",
                          rows: "5",
                        },
                        model: {
                          value: _vm.dimensionDataSource.remark,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dimensionDataSource,
                              "remark",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "dimensionDataSource.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitData } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }