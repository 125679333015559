<!-- 新增、编辑 -->
<template>
  <div class="app-container">
    <el-drawer
      :title="`批量添加广告位`"
      :visible.sync="visible"
      direction="rtl"
      :before-close="handleClose"
      :size="1200"
    >
      <el-row class="drawerbox">
        <div class="batch-add">
          <div class="title">应用: {{configAppData? configAppData.platformTypeName: ''}} | {{configAppData? configAppData.name: ""}} | id:{{configAppData ? configAppData.id: ''}}</div>
          <div class="title">广告位: {{adSpaceData ? adSpaceData.name: ''}} | id:{{adSpaceData ? adSpaceData.id: ''}}</div>
          <div class="title">广告位类型: {{adSpaceData ? adSpaceData.typeName: ''}}</div>
          <!-- <div>A/B实验: 对照组(B)</div> -->
        </div>
        <el-form
          ref="dialogForm"
          :model="dataSource"
          size="small"
          label-width="50px"
        >
          <div>
            <el-form-item >
              <el-button size="small" type="primary" @click="addTable"
                >新建</el-button
              >
            </el-form-item>

            <el-form-item label="">
              <el-table
                size="small"
                :data="adverSpaceList"
                style="width: 1100px"
                border
              >
                <el-table-column prop="opera" label="操作" width="100" align="center">
                  <template slot-scope="scope">
                    <el-button
                      class="el-button-text-danger"
                      type="text"
                      size="small"
                      @click="delTable(scope.$index)"
                    >
                      {{
                        adverSpaceList.length === 1 ? '' : '删除'
                      }}</el-button
                    >
                  </template>
                </el-table-column>

                <el-table-column prop="status" label="状态" align="center" width="100">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.status"
                      :active-value="1"
                      :inactive-value="0"
                    />
                  </template>
                </el-table-column>

                <el-table-column prop="adNetworkCode" label="广告网络" align="center">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.adNetworkCode"
                      placeholder="请选择"
                      clearable
                    >
                      <el-option
                        v-for="item in adverNetList"
                        :key="item.adNetworkCode"
                        :label="item.adNetworkName"
                        :value="item.adNetworkCode"
                      />
                    </el-select>
                  </template>
                </el-table-column>

                <el-table-column prop="type" label="类型" align="center">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.type"
                      placeholder="请选择"
                      clearable
                    >
                      <el-option
                        v-for="item in typeList"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="adBitsCode" label="代码位ID" width="220" align="center">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.adBitsCode"
                      type="text"
                      placeholder="请输入"
                      clearable
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="adBitsName" label="代码位名称"  width="200" align="center">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.adBitsName"
                      type="text"
                      placeholder="请输入"
                      clearable
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="排序价格" align="center">
                  <template slot-scope="scope">
                    <el-input
                      v-if="scope.row.type == 2"
                      v-model="scope.row.price"
                      type="text"
                      placeholder="请输入"
                      clearable
                    />
                    <div v-else>/</div>
                  </template>
                </el-table-column>
                
              </el-table>
            </el-form-item>

            <el-form-item style="text-align:center; margin-top: 80px">
              <el-button size="small" @click="handleClose">取消</el-button>
              <el-button size="small" type="primary" @click="saveBatch">保存</el-button>
            </el-form-item>

          </div>
        </el-form>
      </el-row>
    </el-drawer>
    
  </div>
</template>

<script>
import proApi from '@/api/pages/proApi';
import applicationConfigApi from '@/api/pages/applicationConfig';

export default {
  name: 'BatchAdd',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    configAppId:{},
    adverNetList:{
      type:Array,
      default:[]
    },
    adSpaceDataId:{},
    adBitsSubsetId:{},
    adSpaceData:{},
    configAppData:{}
  },

  data() {
    return {
        dataSource: {},
        dimensionType: '',
        dimensionId: '',
        adverSpaceList: [
          {
            adNetworkCode:'',
            type:'',
            adBitsCode:'',
            adBitsName:'',
            price:'',
            status:0,
          },
        ],
        typeList:[
          {code: 1, name: '客户端竞价'},
          {code: 2, name: '按价格'},
          {code: 3, name: '兜底'},
        ]
    };
  },
  watch: {
    visible: {
      handler: function (val, oldVal) {
        if (val) {
          this.adverSpaceList = [
            {
              adNetworkCode:'',
              type:'',
              adBitsCode:'',
              adBitsName:'',
              price:'',
              status:0,
            },
          ]
        }
      },
      deep: true,
    },
    
  },
  mounted() {

  },

  methods: {
     //新增
    addTable() {
      this.adverSpaceList.push({
        adNetworkCode:'',
        type:'',
        adBitsCode:'',
        adBitsName:'',
        price:'',
        status: 0,
      });
    },

    //删除
    delTable(index) {
      this.adverSpaceList.splice(index, 1);
    },

    //保存广告位
    saveBatch() {
        let data = {}
        data.adBitsDataList = this.adverSpaceList
        for (let i = 0; i < data.adBitsDataList.length; i++) {
          let item = data.adBitsDataList[i];
          if (!item.type || !item.adNetworkCode || !item.adBitsCode) {
            this.$message.error('广告网络或类型、代码位ID不能为空');
            return;
          }
        
          // 如果为按价格的话 需要校验排序价格不能为空
          if (item.type == 2){
            if (
              !item.price ||
              item.price == '' ||
              item.prizeCount == 0
            ) {
              this.$message.error('排序价格不能为空');
              return;
            }
          }
        }
        
        data.adBitsSubsetId = this.adBitsSubsetId
        data.adSpaceDataId = this.adSpaceDataId
        data.configAppId = this.configAppId
        applicationConfigApi.batchAddAd(data).then((res) => {
          if (res && res.code == 1) {
            this.handleClose()
            this.$parent.getTabtacticsData()
          }
        });
    },

    // 关闭
    handleClose() {
      // this.$refs.dialogForm.resetFields();
      this.$emit('close');
    },

    // 获取名称
    getFieldName(list, key, val) {
      if (list && list.length > 0) {
        let obj = list.filter((item) => {
          return item[key] == val;
        });
        if (obj.length > 0) {
          return obj[0].name;
        }
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.batch-add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 20px;
}
.title {
  color: #333;
  font-size: 15px;
}
.dialog-footer {
  text-align: center;
  display: block;
  margin-top: 20px;
}
.txt {
  color: red;
  font-size: 12px;
  margin-left: 10px;
}
.txt1 {
  font-size: 12px;
  margin: 0 10px;
}
.flex {
  display: flex;
}
.tips_icon {
  margin: 0 10px;
  position: relative;
  top: 6px;
}
.labelCenter {
  align-items: center;
}
.lab_title {
  width: 130px;
  text-align: right;
  padding-right: 18px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.mon_box {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 20px;
}
.drawerbox {
  padding-bottom: 40px;
}
.time_tips {
  font-size: 12px;
  color: red;
  line-height: 20px;
}
</style>
