var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("switch-roles", { on: { change: _vm.handleRolesChange } }),
      _c("div", { key: _vm.key, staticStyle: { "margin-top": "30px" } }, [
        _c(
          "div",
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin"],
                    expression: "['admin']",
                  },
                ],
                staticClass: "permission-alert",
              },
              [
                _vm._v(" Only "),
                _c(
                  "el-tag",
                  { staticClass: "permission-tag", attrs: { size: "small" } },
                  [_vm._v("admin")]
                ),
                _vm._v(" can see this "),
              ],
              1
            ),
            _c(
              "el-tag",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin"],
                    expression: "['admin']",
                  },
                ],
                staticClass: "permission-sourceCode",
                attrs: { type: "info" },
              },
              [_vm._v(" v-permission=\"['admin']\" ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["editor"],
                    expression: "['editor']",
                  },
                ],
                staticClass: "permission-alert",
              },
              [
                _vm._v(" Only "),
                _c(
                  "el-tag",
                  { staticClass: "permission-tag", attrs: { size: "small" } },
                  [_vm._v("editor")]
                ),
                _vm._v(" can see this "),
              ],
              1
            ),
            _c(
              "el-tag",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["editor"],
                    expression: "['editor']",
                  },
                ],
                staticClass: "permission-sourceCode",
                attrs: { type: "info" },
              },
              [_vm._v(" v-permission=\"['editor']\" ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "editor"],
                    expression: "['admin','editor']",
                  },
                ],
                staticClass: "permission-alert",
              },
              [
                _vm._v(" Both "),
                _c(
                  "el-tag",
                  { staticClass: "permission-tag", attrs: { size: "small" } },
                  [_vm._v("admin")]
                ),
                _vm._v(" and "),
                _c(
                  "el-tag",
                  { staticClass: "permission-tag", attrs: { size: "small" } },
                  [_vm._v("editor")]
                ),
                _vm._v(" can see this "),
              ],
              1
            ),
            _c(
              "el-tag",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "editor"],
                    expression: "['admin','editor']",
                  },
                ],
                staticClass: "permission-sourceCode",
                attrs: { type: "info" },
              },
              [_vm._v(" v-permission=\"['admin','editor']\" ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          key: "checkPermission" + _vm.key,
          staticStyle: { "margin-top": "60px" },
        },
        [
          _vm._m(0),
          _c(
            "el-tabs",
            { staticStyle: { width: "550px" }, attrs: { type: "border-card" } },
            [
              _vm.checkPermission(["admin"])
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "Admin" } },
                    [
                      _vm._v(" Admin can see this "),
                      _c(
                        "el-tag",
                        {
                          staticClass: "permission-sourceCode",
                          attrs: { type: "info" },
                        },
                        [_vm._v(" v-if=\"checkPermission(['admin'])\" ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.checkPermission(["editor"])
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "Editor" } },
                    [
                      _vm._v(" Editor can see this "),
                      _c(
                        "el-tag",
                        {
                          staticClass: "permission-sourceCode",
                          attrs: { type: "info" },
                        },
                        [_vm._v(" v-if=\"checkPermission(['editor'])\" ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.checkPermission(["admin", "editor"])
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "Admin-OR-Editor" } },
                    [
                      _vm._v(" Both admin or editor can see this "),
                      _c(
                        "el-tag",
                        {
                          staticClass: "permission-sourceCode",
                          attrs: { type: "info" },
                        },
                        [
                          _vm._v(
                            " v-if=\"checkPermission(['admin','editor'])\" "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _vm._v(
        " In some cases, using v-permission will have no effect. For example: Element-UI's Tab component or el-table-column and other scenes that dynamically render dom. You can only do this with v-if. "
      ),
      _c("br"),
      _vm._v(" e.g. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }