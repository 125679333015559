var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        [
          _c(
            "el-row",
            [
              _c(
                "el-form",
                {
                  ref: "templeteForm",
                  attrs: {
                    model: _vm.requestParams,
                    size: "small",
                    inline: true,
                  },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { type: "flex" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "el-custom-grid" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "configAppId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "el-select-darkness",
                                  attrs: {
                                    filterable: "",
                                    placeholder: "应用",
                                    clearable: "",
                                  },
                                  on: { change: _vm.changeApp },
                                  model: {
                                    value: _vm.requestParams.configAppId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.requestParams,
                                        "configAppId",
                                        $$v
                                      )
                                    },
                                    expression: "requestParams.configAppId",
                                  },
                                },
                                _vm._l(_vm.projectList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label:
                                        item.platformTypeName +
                                        " | " +
                                        item.name +
                                        " | id:" +
                                        item.id,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "adSpaceDataId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "el-select-darkness",
                                  attrs: {
                                    filterable: "",
                                    placeholder: "广告位",
                                    clearable: "",
                                  },
                                  on: { change: _vm.changeAd },
                                  model: {
                                    value: _vm.requestParams.adSpaceDataId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.requestParams,
                                        "adSpaceDataId",
                                        $$v
                                      )
                                    },
                                    expression: "requestParams.adSpaceDataId",
                                  },
                                },
                                _vm._l(_vm.adverSpaceList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.name + " | id:" + item.id,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "adNetworkCodeList" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "el-select-darkness",
                                  attrs: {
                                    multiple: "",
                                    placeholder: "广告网络",
                                    clearable: "",
                                  },
                                  on: { change: _vm.changeNet },
                                  model: {
                                    value: _vm.requestParams.adNetworkCodeList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.requestParams,
                                        "adNetworkCodeList",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "requestParams.adNetworkCodeList",
                                  },
                                },
                                _vm._l(_vm.adverNetList, function (item) {
                                  return _c("el-option", {
                                    key: item.adNetworkCode,
                                    attrs: {
                                      label: item.adNetworkName,
                                      value: item.adNetworkCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      style: {
                        "margin-bottom": "10px",
                        display: "flex",
                        "justify-content":
                          _vm.tabList &&
                          _vm.tabList.length &&
                          _vm.tabList.length > 0
                            ? "space-between"
                            : "end",
                      },
                      attrs: { span: 24 },
                    },
                    [
                      _vm.tabList &&
                      _vm.tabList.length &&
                      _vm.tabList.length > 0
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.handerTab },
                                  model: {
                                    value: _vm.requestParams.adBitsSubsetId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.requestParams,
                                        "adBitsSubsetId",
                                        $$v
                                      )
                                    },
                                    expression: "requestParams.adBitsSubsetId",
                                  },
                                },
                                _vm._l(_vm.tabList, function (item, index) {
                                  return _c(
                                    "el-radio-button",
                                    { key: index, attrs: { label: item.id } },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.trafficGroup($event)
                            },
                          },
                        },
                        [_vm._v("流量分组管理")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "el-dropdown",
                    { on: { command: _vm.handleMenuClick } },
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "primary", size: "small" } },
                        [
                          _vm._v(" 添加代码位 "),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("el-dropdown-item", { attrs: { command: "1" } }, [
                            _vm._v("添加单个广告位"),
                          ]),
                          _c("el-dropdown-item", { attrs: { command: "2" } }, [
                            _vm._v("批量添加广告位"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handleWaterfallFlowConfig },
                    },
                    [_vm._v("瀑布流策略设置")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "10px",
                    color: "#333",
                    "font-weight": "bold",
                  },
                },
                [_vm._v("竞价")]
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-bottom": "50px" },
                  attrs: {
                    size: "small",
                    data: _vm.biddingTypeList,
                    "header-cell-style": {
                      height: "50px",
                      fontSize: "14px",
                      color: "#333",
                      fontweight: 400,
                    },
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "left", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editData(scope.row, "edit")
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确定要删除吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteData(scope.row.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "el-button-text-danger",
                                    staticStyle: { "margin-left": "30px" },
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "small",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确定要更改状态吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.updateAdverStatus(scope.row)
                                  },
                                },
                              },
                              [
                                _c("el-switch", {
                                  staticStyle: { "margin-left": "30px" },
                                  attrs: {
                                    slot: "reference",
                                    value: scope.row.status,
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  slot: "reference",
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "adNetworkName",
                      label: "广告网络",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "adBitsCode",
                      label: "代码位名称/id",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.adBitsName) +
                                      "-" +
                                      _vm._s(scope.row.adTypeName) +
                                      "-" +
                                      _vm._s(scope.row.adBitsCode)
                                  ),
                                ]),
                                _c("i", {
                                  staticClass: "el-icon-document-copy",
                                  staticStyle: {
                                    color: "#409eff",
                                    "margin-left": "5px",
                                    cursor: "pointer",
                                    "vertical-align": "top",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyLink(
                                        scope.row.adBitsName +
                                          "-" +
                                          scope.row.adTypeName +
                                          "-" +
                                          scope.row.adBitsCode
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "price",
                      label: "排序价格",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(scope.row.price ? scope.row.price : "/")
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "10px",
                    color: "#333",
                    "font-weight": "bold",
                  },
                },
                [_vm._v("按价格")]
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-bottom": "50px" },
                  attrs: {
                    size: "small",
                    data: _vm.priceTypeList,
                    "header-cell-style": {
                      height: "50px",
                      fontSize: "14px",
                      color: "#333",
                      fontweight: 400,
                    },
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "left", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editData(scope.row, "edit")
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确定要删除吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteData(scope.row.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "el-button-text-danger",
                                    staticStyle: { "margin-left": "30px" },
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "small",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确定要更改状态吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.updateAdverStatus(scope.row)
                                  },
                                },
                              },
                              [
                                _c("el-switch", {
                                  staticStyle: { "margin-left": "30px" },
                                  attrs: {
                                    slot: "reference",
                                    value: scope.row.status,
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  slot: "reference",
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "adNetworkName",
                      label: "广告网络",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "adBitsCode",
                      label: "代码位名称/id",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.adBitsName) +
                                      "-" +
                                      _vm._s(scope.row.adTypeName) +
                                      "-" +
                                      _vm._s(scope.row.adBitsCode)
                                  ),
                                ]),
                                _c("i", {
                                  staticClass: "el-icon-document-copy",
                                  staticStyle: {
                                    color: "#409eff",
                                    "margin-left": "5px",
                                    cursor: "pointer",
                                    "vertical-align": "top",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyLink(
                                        scope.row.adBitsName +
                                          "-" +
                                          scope.row.adTypeName +
                                          "-" +
                                          scope.row.adBitsCode
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "price",
                      label: "排序价格",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "10px",
                    color: "#333",
                    "font-weight": "bold",
                  },
                },
                [_vm._v("兜底")]
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "small",
                    data: _vm.bottomTypeList,
                    "header-cell-style": {
                      height: "50px",
                      fontSize: "14px",
                      color: "#333",
                      fontweight: 400,
                    },
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "left", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editData(scope.row, "edit")
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确定要删除吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteData(scope.row.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "el-button-text-danger",
                                    staticStyle: { "margin-left": "30px" },
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "small",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确定要更改状态吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.updateAdverStatus(scope.row)
                                  },
                                },
                              },
                              [
                                _c("el-switch", {
                                  staticStyle: { "margin-left": "30px" },
                                  attrs: {
                                    slot: "reference",
                                    value: scope.row.status,
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  slot: "reference",
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "adNetworkName",
                      label: "广告网络",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "adBitsCode",
                      label: "代码位名称/id",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.adBitsName) +
                                      "-" +
                                      _vm._s(scope.row.adTypeName) +
                                      "-" +
                                      _vm._s(scope.row.adBitsCode)
                                  ),
                                ]),
                                _c("i", {
                                  staticClass: "el-icon-document-copy",
                                  staticStyle: {
                                    color: "#409eff",
                                    "margin-left": "5px",
                                    cursor: "pointer",
                                    "vertical-align": "top",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyLink(
                                        scope.row.adBitsName +
                                          "-" +
                                          scope.row.adTypeName +
                                          "-" +
                                          scope.row.adBitsCode
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "price",
                      label: "排序价格",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(scope.row.price ? scope.row.price : "/")
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("WaterfallFlowConfig", {
        attrs: {
          visible: _vm.waterfallFlowConfigVisible,
          waterfallStrategyCode: _vm.waterfallStrategyCode,
          subsetListId: _vm.subsetListId,
          waterfallStrategyData: _vm.waterfallStrategyData,
        },
        on: { close: _vm.handleWaterfallFlowConfigRefresh },
      }),
      _c("EditModal", {
        attrs: {
          visible: _vm.adVerVisible,
          adverNetList: _vm.adverNetList,
          configAppId: _vm.requestParams.configAppId,
          adSpaceDataId: _vm.requestParams.adSpaceDataId,
          adBitsSubsetId: _vm.requestParams.adBitsSubsetId,
          adSpaceData: _vm.adSpaceData,
          configAppData: _vm.configAppData,
          itemData: _vm.itemData,
        },
        on: {
          close: function ($event) {
            _vm.adVerVisible = false
          },
        },
      }),
      _c("EditorPrice", {
        attrs: {
          visible: _vm.editVisible,
          editType: _vm.editType,
          symbolList: _vm.symbolList,
          ruleConfigList: _vm.ruleConfigList,
          adverNetList: _vm.adverNetList,
          configAppId: _vm.requestParams.configAppId,
          adSpaceDataId: _vm.requestParams.adSpaceDataId,
          adBitsSubsetId: _vm.requestParams.adBitsSubsetId,
          adSpaceData: _vm.adSpaceData,
          configAppData: _vm.configAppData,
        },
        on: {
          close: function ($event) {
            _vm.editVisible = false
          },
        },
      }),
      _c("BatchAdd", {
        attrs: {
          visible: _vm.batchAddVisible,
          adverNetList: _vm.adverNetList,
          configAppId: _vm.requestParams.configAppId,
          adSpaceDataId: _vm.requestParams.adSpaceDataId,
          adBitsSubsetId: _vm.requestParams.adBitsSubsetId,
          adSpaceData: _vm.adSpaceData,
          configAppData: _vm.configAppData,
        },
        on: {
          close: function ($event) {
            _vm.batchAddVisible = false
          },
        },
      }),
      _c("TrafficGroupManage", {
        attrs: {
          visible: _vm.traffGroupVisible,
          adverNetList: _vm.adverNetList,
          configAppId: _vm.requestParams.configAppId,
          adSpaceDataId: _vm.requestParams.adSpaceDataId,
          adBitsSubsetId: _vm.requestParams.adBitsSubsetId,
        },
        on: {
          close: function ($event) {
            _vm.traffGroupVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }