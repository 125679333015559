import Cookies from 'js-cookie'
import store from '@/store'
const TokenKey = 'Admin-Token'

export function getToken() {
  if (self == top) {
    return Cookies.get(TokenKey)
  } else {
    return store && store.getters && store.getters.token || ''
  }
}

export function setToken(token) {
  if (self == top) {
    return Cookies.set(TokenKey, token)
  } else {
    store && store.dispatch && store.dispatch('user/setToken', token)
  }
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
