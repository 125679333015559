var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _vm.collapse
          ? _c(
              "div",
              { staticClass: "sidebar-logo-link" },
              [
                !_vm.logo
                  ? _c("router-link", { key: "collapse", attrs: { to: "" } }, [
                      _c("div", { staticClass: "sidebar-logo-link-label" }, [
                        _c("img", {
                          staticClass: "sidebar-logo",
                          attrs: {
                            src: require("../../../assets/logo-new.png"),
                          },
                        }),
                      ]),
                    ])
                  : _c("span", {
                      staticClass: "iconfont icon98du-menu-unfold fold",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$store.dispatch("app/updateSideBar", true)
                        },
                      },
                    }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "sidebar-logo-link" },
              [
                _vm.logo
                  ? _c("router-link", { key: "expand", attrs: { to: "" } }, [
                      _c("div", { staticClass: "sidebar-logo-link-label" }, [
                        _c("img", {
                          staticClass: "sidebar-logo",
                          attrs: {
                            src: require("../../../assets/logo-new.png"),
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _c("span", {
                  staticClass: "iconfont icon98du-menu-fold fold",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$store.dispatch("app/updateSideBar", false)
                    },
                  },
                }),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }