var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      false
        ? _c("hamburger", {
            staticClass: "hamburger-container",
            attrs: {
              id: "hamburger-container",
              "is-active": _vm.sidebar.opened,
            },
            on: { toggleClick: _vm.toggleSideBar },
          })
        : _vm._e(),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      !_vm.isIframe
        ? _c(
            "div",
            { staticClass: "right-menu" },
            [
              _vm.device !== "mobile" ? void 0 : _vm._e(),
              _c(
                "el-dropdown",
                {
                  staticClass: "avatar-container right-menu-item hover-effect",
                  attrs: { trigger: "click" },
                },
                [
                  _c("div", { staticClass: "avatar-wrapper" }, [
                    _c("i", {
                      staticClass: "el-icon-s-custom",
                      staticStyle: { "font-size": "26px" },
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout($event)
                            },
                          },
                        },
                        [
                          _c("span", { staticStyle: { display: "block" } }, [
                            _vm._v("退出"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }