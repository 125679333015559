/**
 * 系统管理模块
 */
import Layout from '@/layout';
const productAppConfigRouter = {
  path: '/productAppConfig',
  component: Layout,
  name: 'productAppConfig',
  redirect: 'noRedirect',
  permission: 0,
  meta: {
    title: '应用管理',
    icon: 'iconfont icon98du-xitongguanli',
  },
  children: [
    {
      path: 'projectManage',
      component: () =>
        import('@/views/pages/productAppConfig/productConfig/projectManage'),
      name: 'projectManage',
      permission: 0,
      meta: {
        title: '项目配置',
        noCache: false,
        scrollTop: 0,
      },
    },
    {
      path: 'trafficGroupRule',
      component: () =>
        import(
          '@/views/pages/productAppConfig/productConfig/trafficGroupRule'
        ),
      name: 'trafficGroupRule',
      permission: 0,
      meta: {
        title: '流量分组规则配置',
        noCache: false,
        scrollTop: 0,
      },
    },
  ],
};
export default productAppConfigRouter;
