<!-- 新增、编辑 -->
<template>
  <div class="app-container">
    <el-drawer
      :title="`新建广告位`"
      :visible.sync="visible"
      direction="rtl"
      :before-close="handleClose"
      :size="900"
    >
      <el-row class="drawerbox">
        <el-form
          ref="dialogForm"
          :model="dataSource"
          size="small"
          label-width="100px"
        >
          <div>
            <el-form-item >
              <el-button size="small" type="primary" @click="addTable"
                >新建广告位</el-button
              >
            </el-form-item>

            <el-form-item label="">
              <el-table
                size="small"
                :data="adverSpaceList"
                style="width: 800px"
                border
              >
                <el-table-column prop="type" label="类型">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.type"
                      placeholder="请选择"
                      clearable
                    >
                      <el-option
                        v-for="item in adverTypeList"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="名称">
                  <template slot-scope="scope">
                    <el-input
                        v-model="scope.row.name"
                        type="text"
                        placeholder="请输入"
                        clearable
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      class="el-button-text-danger"
                      @click="delTable(scope.$index)"
                    >
                      {{
                        adverSpaceList.length === 1 ? '' : '删除'
                      }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <el-form-item style="text-align:center; margin-top: 80px">
              <el-button size="small" @click="handleClose">取消</el-button>
              <el-button size="small" type="primary" @click="saveAdverSpace">保存</el-button>
            </el-form-item>

          </div>
        </el-form>
      </el-row>
    </el-drawer>
    <EditLabelModal
      :visible="labelConfigVisible"
      :dimensionType="dimensionType"
      :dimensionId="dimensionId"
      @close="labelConfigVisible = false"
    ></EditLabelModal>
  </div>
</template>

<script>
import proApi from '@/api/pages/proApi';
import EditLabelModal from './EditLabelModal';
import applicationConfigApi from '@/api/pages/applicationConfig';

export default {
  name: 'DimensionTypeSet',
  components: {
    EditLabelModal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    projectId:{
      type: Number,
      default: null
    },
    adverTypeList:{
      type: Array,
      default: []
    },
    configAppId:{
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      dataSource: {
        
      },
      projectList: [],
      labelConfigVisible: false,
      dimensionList: [],
      dimensionType: '',
      dimensionId: '',
      adverSpaceList: [
        {
          type:'',
          name:''
        },
      ],
    };
  },
  watch: {
    visible: {
      handler: function (val, oldVal) {
        if (val) {
          this.adverSpaceList = [
            {
              type:'',
              name:''
            },
          ]
        } else {
          this.dimensionList = []
        }
      },
      deep: true,
    },
    
  },
  mounted() {

  },

  methods: {
    getDimensionData() {
      proApi.listProjectId({ projectId: this.projectId }).then((res) => {
        if (res.code == 1 && res.data) {
          this.dimensionList = res.data
        }
      });
    },

     //新增
    addTable() {
      this.adverSpaceList.push({
        type: '',
        name:''
      });
    },

    //删除
    delTable(index) {
      this.adverSpaceList.splice(index, 1);
    },

    editDimension(id, type) {
      this.labelConfigVisible = true
      this.dimensionId = id
      this.dimensionType = type
    },

    //保存广告位
    saveAdverSpace() {
      applicationConfigApi.batchAdd({
        configAppId: this.configAppId,
        list: this.adverSpaceList
      }).then((res) => {
        if (res && res.code == 1) {
          this.handleClose()
          this.$parent.adverSpaceList()
        }
      });
    },

    //操作状态
    dimUpdateStatus(e, row, index) {
      let msg = row.status == 1 ? '确定关闭？' : '确定开启？';
      let val = row.status == 1 ? 0 : 1;
      this.$confirm(msg, '提示', { type: 'warning' }).then(() => {
        proApi.dataDimUpdateStatus({ id: row.id, status: val })
          .then((res) => {
            if (res && res.code === 1) {
              this.$message.success('修改成功');
              this.getDimensionData();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },

    handleClose() {
      // this.$refs.dialogForm.resetFields();
      this.$emit('close');
    },

    handleProject() {
      this.dataSource.appId = '';
    },
    
    // 获取名称
    getFieldName(list, key, val) {
      if (list && list.length > 0) {
        let obj = list.filter((item) => {
          return item[key] == val;
        });
        if (obj.length > 0) {
          return obj[0].name;
        }
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-footer {
  text-align: center;
  display: block;
  margin-top: 20px;
}
.txt {
  color: red;
  font-size: 12px;
  margin-left: 10px;
}
.txt1 {
  font-size: 12px;
  margin: 0 10px;
}
.flex {
  display: flex;
}
.tips_icon {
  margin: 0 10px;
  position: relative;
  top: 6px;
}
.labelCenter {
  align-items: center;
}
.lab_title {
  width: 130px;
  text-align: right;
  padding-right: 18px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.mon_box {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 20px;
}
.drawerbox {
  padding-bottom: 40px;
}
.time_tips {
  font-size: 12px;
  color: red;
  line-height: 20px;
}
</style>
