<!-- 新增、编辑 -->
<template>
  <div class="app-container">
    <el-drawer
      :title="`添加单个广告位`"
      :visible.sync="visible"
      direction="rtl"
      :before-close="handleClose"
      :size="900"
    >
      <el-row class="drawerbox">
        <el-form
          ref="dialogForm"
          :model="dataSource"
          size="small"
          label-width="165px"
        >
          <div>
            <div>
              <div class="header-info">
                <div class="header-items">应用: {{configAppData? configAppData.platformTypeName: ''}} | {{configAppData? configAppData.name: ""}} | id:{{configAppData ? configAppData.id: ''}}</div>
                <div class="header-items">广告位: {{adSpaceData ? adSpaceData.name: ''}} | id:{{adSpaceData ? adSpaceData.id: ''}}</div>
                <div class="header-items">广告位类型: {{adSpaceData ? adSpaceData.typeName: ''}}</div>
                <!-- <div class="header-items">A/B测试: 对照组</div> -->
              </div>
            </div>
            <el-form-item
              align="center"
              label="广告网络"
              prop="adNetworkCode"
              :rules="{
                required: true,
                message: '请输入',
                trigger: 'blur',
              }"
            >
              <el-select
                v-model="dataSource.adNetworkCode"
                placeholder="请选择"
                clearable
                style="width: 400px"
              >
                <el-option
                  v-for="item in adverNetList"
                  :key="item.adNetworkCode"
                  :label="item.adNetworkName"
                  :value="item.adNetworkCode"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              align="center"
              label="类型"
              prop="type"
              :rules="{
                required: true,
                message: '请输入',
                trigger: 'blur',
              }"
            >
              <el-select
                v-model="dataSource.type"
                placeholder="请选择"
                clearable
                style="width: 400px"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              align="center"
              label="代码位ID"
              prop="adBitsCode"
              :rules="{
                required: true,
                message: '请输入',
                trigger: 'blur',
              }"
            >
              <el-input
                v-model.trim="dataSource.adBitsCode"
                placeholder="请输入"
                style="width: 400px"
              ></el-input>
            </el-form-item>
            <el-form-item
              align="center"
              label="代码位名称"
              prop="adBitsName"
              :rules="{
                required: true,
                message: '请输入',
                trigger: 'blur',
              }"
            >
              <el-input
                v-model.trim="dataSource.adBitsName"
                placeholder="请输入"
                style="width: 400px"
              ></el-input>
            </el-form-item>
            <el-form-item
              align="center"
              v-if="dataSource.type == 2"
              label="排序价格"
              prop="price"
              :rules="{
                required: true,
                message: '请输入',
                trigger: 'blur',
              }"
            >
              <el-input
                v-model.trim="dataSource.price"
                placeholder="请输入"
                style="width: 400px"
                maxlength="6"
              ></el-input>
            </el-form-item>
            
            <el-form-item prop="status" label="状态" align="center">
              <el-switch
                v-model="dataSource.status"
                :active-value="1"
                :inactive-value="0"
              />
            </el-form-item>

            <el-form-item style="text-align:center; margin-top: 80px">
              <el-button size="small" @click="handleClose">取消</el-button>
              <el-button size="small" type="primary" @click="saveAdverSpace(1)">保存</el-button>
              <el-button size="small" type="primary" @click="saveAdverSpace(2)">保存并添加</el-button>
            </el-form-item>

          </div>
        </el-form>
      </el-row>
    </el-drawer>
    
  </div>
</template>

<script>
import proApi from '@/api/pages/proApi';
import applicationConfigApi from '@/api/pages/applicationConfig';

export default {
  name: 'DimensionTypeSet',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    adverNetList:{
      type: Array,
      default: []
    },
    configAppId:{},
    adSpaceDataId:{},
    adBitsSubsetId:{},
    adSpaceData:{},
    configAppData:{}
  },

  data() {
    return {
      dataSource: {
        adNetworkCode:'',
        type: '',
        adBitsCode: '',
        adBitsName: '',
        price: '',
        status: ''
      },
      projectList: [],
      labelConfigVisible: false,
      dimensionList: [],
      dimensionType: '',
      dimensionId: '',
      adverSpaceList: [
        {
          type:'',
          name:''
        },
      ],
      typeList:[
        {code:  1, name: '客户端竞价'},
        {code:  2, name: '按价格'},
        {code:  3, name: '兜底'},
      ]
    };
  },
  watch: {
    visible: {
      handler: function (val, oldVal) {
        if (val) {
          this.adverSpaceList = [
            {
              type:'',
              name:''
            },
          ]
        } else {
          this.dimensionList = []
        }
      },
      deep: true,
    },
    
  },
  mounted() {},

  methods: {
     //新增
    addTable() {
      this.adverSpaceList.push({
        type: '',
        name:''
      });
    },

    //删除
    delTable(index) {
      this.adverSpaceList.splice(index, 1);
    },

    //保存广告位
    saveAdverSpace(val) {
        this.$refs.dialogForm.validate((valid) => {
            if (valid) {
                let data = JSON.parse(JSON.stringify(this.dataSource));
                data.adBitsSubsetId= this.adBitsSubsetId
                data.adSpaceDataId= this.adSpaceDataId
                data.configAppId= this.configAppId
                applicationConfigApi.dataSave(data).then((res) => {
                    if (res && res.code == 1) {
                        this.$message.success('保存成功');
                        if(val == 1) {
                            this.handleClose()
                            this.$parent.getTabtacticsData()
                        }else {
                            this.$parent.getTabtacticsData()
                            this.$refs.dialogForm.resetFields();
                        }
                        
                    }else {
                        this.$message.error('保存失败');
                    }
                }).catch((e) => {
                    console.log('e', e);
                });
            }
        });

     
    },

    // 保存并添加
    saveAdd() {
        this.$refs.dialogForm.resetFields();
    },

    handleClose() {
      this.$refs.dialogForm.resetFields();
      this.$emit('close');
    },

    handleProject() {
      this.dataSource.appId = '';
    },
    
    // 获取名称
    getFieldName(list, key, val) {
      if (list && list.length > 0) {
        let obj = list.filter((item) => {
          return item[key] == val;
        });
        if (obj.length > 0) {
          return obj[0].name;
        }
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.header-info {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    padding: 0 90px 0px
    // padding: 0 50px 20px
}
.header-items {
    font-size: 12px;
    color:#333;
    padding-bottom: 20px;
}
.dialog-footer {
  text-align: center;
  display: block;
  margin-top: 20px;
}
.txt {
  color: red;
  font-size: 12px;
  margin-left: 10px;
}
.txt1 {
  font-size: 12px;
  margin: 0 10px;
}
.flex {
  display: flex;
}
.tips_icon {
  margin: 0 10px;
  position: relative;
  top: 6px;
}
.labelCenter {
  align-items: center;
}
.lab_title {
  width: 130px;
  text-align: right;
  padding-right: 18px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.mon_box {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 20px;
}
.drawerbox {
  padding-bottom: 40px;
}
.time_tips {
  font-size: 12px;
  color: red;
  line-height: 20px;
}

</style>
 