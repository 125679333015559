var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "components-container" },
    [
      _vm._m(0),
      _c("pan-thumb", { attrs: { image: _vm.image } }),
      _c(
        "el-button",
        {
          staticStyle: {
            position: "absolute",
            bottom: "15px",
            "margin-left": "40px",
          },
          attrs: { type: "primary", icon: "el-icon-upload" },
          on: {
            click: function ($event) {
              _vm.imagecropperShow = true
            },
          },
        },
        [_vm._v(" Change Avatar ")]
      ),
      _c("image-cropper", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.imagecropperShow,
            expression: "imagecropperShow",
          },
        ],
        key: _vm.imagecropperKey,
        attrs: {
          width: 300,
          height: 300,
          url: "https://httpbin.org/post",
          "lang-type": "en",
        },
        on: { close: _vm.close, "crop-upload-success": _vm.cropSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _vm._v("This is based on "),
      _c(
        "a",
        {
          staticClass: "link-type",
          attrs: { href: "//github.com/dai-siki/vue-image-crop-upload" },
        },
        [_vm._v(" vue-image-crop-upload")]
      ),
      _vm._v(
        ". Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version. "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }