var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: !_vm.detailId ? "新建" : "编辑",
            width: "600px",
          },
          on: { close: _vm.handleClose },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  attrs: {
                    model: _vm.dataSource,
                    size: "small",
                    "label-width": "110px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "名称",
                        prop: "name",
                        rules: {
                          required: false,
                          message: "请输入",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.dataSource.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dataSource,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "dataSource.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "广告类型",
                        prop: "type",
                        rules: {
                          required: true,
                          message: "请选择",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "340px" },
                          attrs: {
                            clearable: "",
                            disabled: _vm.detailId ? true : false,
                          },
                          model: {
                            value: _vm.dataSource.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataSource, "type", $$v)
                            },
                            expression: "dataSource.type",
                          },
                        },
                        _vm._l(_vm.adverTypeList, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { value: item.code, label: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.dataSource.type == "rewardVideoAd"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "服务端回调",
                            prop: "callbackFlag",
                            rules: {
                              required: true,
                              message: "请选择",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            on: { change: _vm.switchChange },
                            model: {
                              value: _vm.dataSource.callbackFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataSource, "callbackFlag", $$v)
                              },
                              expression: "dataSource.callbackFlag",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataSource.callbackFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "奖励名称",
                            prop: "rewardName",
                            rules: {
                              required: _vm.dataSource.callbackFlag
                                ? true
                                : false,
                              message: "请输入",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.dataSource.rewardName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataSource,
                                  "rewardName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "dataSource.rewardName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataSource.callbackFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "奖励数量",
                            prop: "rewardNum",
                            rules: {
                              required: _vm.dataSource.callbackFlag
                                ? true
                                : false,
                              message: "请输入",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "440px" },
                            attrs: { min: 1, "step-strictly": "" },
                            model: {
                              value: _vm.dataSource.rewardNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataSource,
                                  "rewardNum",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "dataSource.rewardNum",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataSource.callbackFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "回调URL",
                            prop: "callbackUrl",
                            rules: {
                              required: _vm.dataSource.callbackFlag
                                ? true
                                : false,
                              message: "请输入",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.dataSource.callbackUrl,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataSource,
                                  "callbackUrl",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "dataSource.callbackUrl",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataSource.callbackFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "回调密钥",
                            prop: "callbackSecret",
                            rules: {
                              required: false,
                              message: "请输入",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请输入", disabled: true },
                              model: {
                                value: _vm.dataSource.callbackSecret,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataSource,
                                    "callbackSecret",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "dataSource.callbackSecret",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "append" }, slot: "append" },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-document-copy",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyLink(
                                          "" + _vm.dataSource.callbackSecret
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "refesh",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.refreshCipher($event)
                                },
                              },
                            },
                            [_vm._v("刷新")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitData } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }