<!-- 查看数据 -->
<template>
  <div class="app-container">
    <el-drawer
      :title="`查看数据`"
      :visible.sync="visible"
      direction="rtl"
      :before-close="handleClose"
      :size="900"
    >
      <el-row class="drawerbox">
        <el-table
            style="width: 840px"
            :data="dataList"
            border
        >
          <el-table-column v-for="(item, index) in fieldsList" :label="item.title" :key="index" :prop="item.name" />
        </el-table>
        <div class="page_block">
          <el-pagination
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="pageSizeChange"
            @current-change="pageIndexChange"
          />
        </div>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
import proApi from '@/api/pages/proApi';

export default {
  name: 'ViewData',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    editType: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    configId:{
      type: String,
      default: 2
    }
  },

  data() {
    return {
        dataSource: {},
        dataList:[],
        pageParam: {
            pageNum: 1,
            pageSize: 10,
            total: 0,
        },
        fieldsList:[]

    };
  },
  watch: {
    visible: {
      handler: function (val, oldVal) {
        if (val) {
          this.pageParam.pageNum = 1
          this.getDataList();
          this.getDataResult()
        } else {
          this.dataList = []
        }
      },
      deep: true,
    },
    
  },
  mounted() {},

  methods: {
    //  获取列表
    getDataList() {
      proApi.getSyncedFields({ id: this.configId }).then((res) => {
        if (res && res.code == 1 && res.data) {
          this.fieldsList = res.data
        }
      });
    },


    getDataResult(){
      proApi.getSyncedData({ ...this.pageParam, dimConfigId: this.configId }).then((res) => {
        if (res && res.code == 1 && res.data) {
          this.dataList = res.data.list
          this.pageParam.total = res.data.total;
        }
      });
    },

    handleClose() {
      this.$refs.dialogForm.resetFields();
      this.$emit('close');
    },
  
    // 获取名称
    getFieldName(list, key, val) {
      if (list && list.length > 0) {
        let obj = list.filter((item) => {
          return item[key] == val;
        });
        if (obj.length > 0) {
          return obj[0].name;
        }
      }
      return null;
    },

    // 分页
    pageSizeChange(val) {
      this.pageParam.pageSize = val;
      this.pageParam.pageNum = 1;
      this.getDataResult();
    },
    // 分页
    pageIndexChange(val) {
      this.pageParam.pageNum = val;
      this.getDataResult();
    },

    handleClose() {
      this.$emit('close');
    },


  },
};
</script>

<style scoped lang="scss">
.dialog-footer {
  text-align: center;
  display: block;
  margin-top: 20px;
}
.txt {
  color: red;
  font-size: 12px;
  margin-left: 10px;
}
.txt1 {
  font-size: 12px;
  margin: 0 10px;
}
.flex {
  display: flex;
}
.tips_icon {
  margin: 0 10px;
  position: relative;
  top: 6px;
}
.labelCenter {
  align-items: center;
}
.lab_title {
  width: 130px;
  text-align: right;
  padding-right: 18px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.mon_box {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 20px;
}
.drawerbox {
    padding-left: 40px;
}
.time_tips {
  font-size: 12px;
  color: red;
  line-height: 20px;
}
</style>
