import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}
/**
 * 过滤用户的权限
 * @param userRouter
 * @param allRouter
 * @returns {Array}
 */
export function recursionRouter(userRouter = [], allRouter = []) {
  var realRoutes = []
  allRouter.forEach((v, i) => {
    if (v.permission == 1) {
      const idx = userRouter.indexOf(v.name)
      if (idx > -1) {
        v.children = recursionRouter(userRouter, v.children)
        realRoutes.push(v)
      }
    } else if (v.permission == 0) {
      v.children = recursionRouter(userRouter, v.children)
      realRoutes.push(v)
    }

    // userRouter.forEach((item, index) => {
    //   if (item.name === v.name) {
    //     if (item.children && item.children.length > 0) {
    //       v.children = recursionRouter(item.children, v.children)
    //     }
    //     realRoutes.push(v)
    //   }
    // })
  })
  return realRoutes
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, userRoutes) {
    return new Promise(resolve => {
      // let accessedRoutes
      // if (roles.includes('admin')) {
      //   accessedRoutes = asyncRoutes || []
      // } else {
      //   accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      // }
      // commit('SET_ROUTES', accessedRoutes)
      // resolve(accessedRoutes)
      //
      const accessedRoutes = recursionRouter(userRoutes, asyncRoutes)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
