<template>
  <div class="app-container">
    <el-dialog
      :visible="visible"
      :title="!dimensionType ? '新建': '编辑'"
      width="500px"
      @close="handleClose"
    >
      <el-row>
        <el-form
          ref="dialogForm"
          :model="dimensionDataSource"
          size="small"
          label-width="80px"
        >
          <el-form-item
            label="名称"
            prop="name"
            :rules="{
              required: true,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model.trim="dimensionDataSource.name"
              placeholder="请输入"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="瀑布流策略模板"
            prop="waterfallStrategyCode"
            :rules="{
              required: true,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-select
                v-model="dimensionDataSource.waterfallStrategyCode"
                placeholder="请选择"
                clearable
                style="width: 220px"
            >
                <el-option
                    v-for="item in tacticsData"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                />
            </el-select>
          </el-form-item>
           <el-form-item
            label="流量分组规则"
            prop="flowSubsetRuleId"
            :rules="{
              required: false,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-select
                v-model="dimensionDataSource.flowSubsetRuleId"
                placeholder="请选择"
                clearable
                style="width: 220px"
            >
                <el-option
                    v-for="item in groupRuleData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
            </el-select>
          </el-form-item>
          <el-form-item
            label="权重"
            prop="sortNo"
            :rules="{
              required: true,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model.trim="dimensionDataSource.sortNo"
              placeholder="请输入"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model.trim="dimensionDataSource.remark"
              placeholder="请输入"
              type="textarea"
              rows="5"
            ></el-input>
          </el-form-item>
          
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import applicationConfigApi from '@/api/pages/applicationConfig';

export default {
  name: 'EditLabelModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dimensionType: {
      type: String,
      default: null,
    },
    adSpaceDataId:{},
    dimensionData:{}
  },
  data() {
    return {
      dimensionDataSource: {
        name: '',
        flowSubsetRuleId: '',
        sortNo:'',
        waterfallStrategyCode: '',
        remark: '',
      },
      tacticsData:[],
      groupRuleData:[]
    };
  },

  watch: {
    visible: {
      handler: function (val, oldVal) {
        if (val && this.$parent.dimensionId) {
            this.dimensionDataSource.name = this.dimensionData.name
            this.dimensionDataSource.flowSubsetRuleId = this.dimensionData.flowSubsetRuleId
            this.dimensionDataSource.sortNo = this.dimensionData.sortNo
            this.dimensionDataSource.waterfallStrategyCode = this.dimensionData.waterfallStrategyCode
            this.dimensionDataSource.remark = this.dimensionData.remark
        }else {
            this.dimensionDataSource= {
                name: '',
                flowSubsetRuleId: '',
                sortNo:'',
                waterfallStrategyCode: '',
                remark: '',
            }
        }
      },
      deep: true,
    },
  },

  mounted() {
      this.getTacticsSelectData() //策略模板下拉
      this.getGroupData() //分组规则
 
  },

  methods: {
    //策略模板下拉
    getTacticsSelectData() {
        applicationConfigApi.listForSelect().then((res)=> {
            if(res && res.code == 1 && res.data) {
                this.tacticsData = res.data
            }
        })
    },

    //分组规则
    getGroupData() {
        applicationConfigApi.trafficGroupPage({
            pageNum: 1,
            pageSize: 1000,
            status: 1
        }).then((res) => {
            if (res && res.code === 1 && res.data) {
                this.groupRuleData = res.data.list;
            }
        })
        .catch((e) => {
            console.log(e);
        });
    },
    
    handleClose() {
      this.$refs.dialogForm.resetFields();
      this.$emit('close');
    },

    submitData() {
      // 编辑时要传id
      let that = this;
      if (this.dimensionType) {
        this.dimensionDataSource.id = this.$parent.dimensionId;
      }else {
        this.dimensionDataSource.id = ''
      }
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(that.dimensionDataSource));
          data.adSpaceDataId = this.adSpaceDataId
          applicationConfigApi.subsetSave(data).then((res) => {
            if (res && res.code == 1) {
              this.$message.success('保存成功');
              this.handleClose();
              this.$parent.getTraffGroupData();
              this.$parent.$parent.getTabtacticsData();
            } else {
              this.$message.error('保存失败');
            }
          })
          .catch((e) => {
            console.log('e', e);
          });
        }
      });
    },

  },
};
</script>

<style scoped lang="scss">
.dialog-footer {
  text-align: center;
  display: block;
}
</style>
