<template>
  <div class="app-container">
    <el-dialog
      :visible="visible"
      :title="'添加应用'"
      width="1000px"
      inline
      @close="handleClose"
    >
      <el-row type="flex" class="add-project">
        <div class="transfer">
          <div class="transfer-left">
            <div class="search-box">
              <el-input type="text" style="width: 400px" v-model="searchQuery" placeholder="请输入应用名称/ID搜索" />
              <div class="all-select" @click="selectAll">全选</div>
            </div>
            <div class="projectList" v-for="item in filteredOptions" :key="item.id">
              <label :for="`option-${item.id}`" class="projectList-item">
                <input
                  type="checkbox"
                  :value="item.id"
                  :id="`option-${item.id}`"
                  v-model="selectedValues"
                />
                <div class="project-name-box">
                  <!-- <div class="text">应用</div> -->
                  <div class="project-name">
                    <div class="name">{{ item.appName }}</div>
                    <div class="projectId">id:{{item.id}}</div>
                  </div>
                </div>
              </label>
              <!-- <div class="projectList-item-right">测试</div> -->
            </div>
          </div>
         
        </div>
        <div class="transfer-right">
          <div class="right-title">
            <div class="title-left">已选0</div>
            <div class="title-right all-select" @click.stop="clearSelect">清空全部</div>
          </div>
          <div class="right-project-list">
            <div class="list-item" v-for="item in selectedOptions" :key="item.appName">{{ item.appName }}</div>
          </div>
        </div>
       

      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import proApi from '@/api/pages/proApi';
import applicationConfigApi from '@/api/pages/applicationConfig';

export default {
  name: 'EditModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    editType: {
      type: String,
      default: null,
    },
  },

 

  data() {
    return {
      options: [], //存储应用列表数据
      selectedValues: [], 
      searchQuery: '',
    };
  },

   
  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          this.getApplicationList()
        } 
      },
      deep: true,
    },
  },

  computed: {
    filteredOptions() {
      return this.options.filter((option) =>
        option.appName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    selectedOptions() {
      return this.options.filter((option) =>
        this.selectedValues.includes(option.id)
      );
    },
  },

  mounted() {},

  methods: {
    // 应用列表
    getApplicationList() {
      applicationConfigApi.applicationList({}).then(res=> {
        if(res && res.code == 1 && res.data) {
          this.options = res.data
        }
      })
    },

    // 全选
    selectAll() {
      this.selectedValues = this.filteredOptions.map((option) => option.id);
    },

    clearSelect() {
      this.selectedValues = []
    },
    
    handleClose() {
      this.$emit('close');
    },
    
    // 添加应用
    submitData() {
      console.log('确定~', this.selectedOptions)
      applicationConfigApi.applicationAdd({data: this.selectedOptions}).then(res=> {
        if(res && res.code == 1) {
          this.$emit('close');
          this.$parent.getProjectList()
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">
.transfer {
  width: 450px;
}
.transfer-left {
  padding-top: 8px;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.all-select {
  color: #4C7BE5;
  cursor: pointer;
}
.container {
  width: 400px;
  padding: 10px 20px;
  border: 1px solid #ececec;
}
.container-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-project {
  display: flex;
  border: 1px solid #EAEAEA;
  padding: 0 15px;
}
.projectList {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EAEAEA;
}
.projectList-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.projectList-item-right {
  border: 1px solid #EAEAEA;
  width: 45px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 4px;
  box-sizing: content-box;
}
.transfer-right {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  border-left: 1px solid #EAEAEA;
  flex: 1;
}
.right-title {
  display: flex;
  justify-content: space-between;
  height: 34px;
  line-height: 34px;
  background: #EAEAEA;
  padding: 0 15px;
  margin-bottom: 25px;
}
.right-project-list {
  display: flex;
  flex-direction: column;
  padding: 0 20px
}
.list-item {
  padding-bottom: 20px;
}
.name {
  padding-bottom: 5px;
}
.project-name-box {
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.text {
  margin-right: 15px;
  width: 40px;
  height: 35px;
  line-height: 35px;
  background: #EAEAEA;
  text-align: center;
  border-radius: 4px;
  color: #999999;
}
.projectId {
  color: #999999;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0;
}

.el-input--medium .el-input__inner {
  height: 33px;
}

.dialog-footer {
  text-align: center;
  display: block;
}

::v-deep .allSelect .el-checkbox__input {
  display: none;
}
</style>
