const requestParamsStore = {
  state: {
    requestParams:{}
  },

  mutations: {
    Search_Params: (state, data) => {
      state.requestParams = data
    },
  },

  actions: {
    ceshi({ commit, state }, data) {
      commit('Search_Params', data)
    },
  }
}

export default requestParamsStore
