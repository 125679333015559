import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken, setToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

import { getQueryToken } from './utils/utils'
NProgress.configure({
  showSpinner: false,
}) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist
const isIframe = top != self
router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title)
  if (getQueryToken()) {
    setToken(getQueryToken())
    // store.commit('SET_TOKEN',getQueryToken())
  }
  if (to.query && to.query.authToken) {
    setToken(to.query.authToken)
  }

  // determine whether the user has logged in
  const hasToken = getToken()
  // const hasToken = getQueryString('authToken')
  // const hasRoles = store.getters.roles && store.getters.roles.length > 0
  // if (hasRoles) {
  //   next()
  // } else {
  //   try {
  //     // get user info
  //     // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
  //     const { roles } = await store.dispatch('user/getInfo')

  //     const userPermissionList = await store.dispatch('user/getUserPermission')

  //     console.log('userPermissionList:' + userPermissionList)

  //     // generate accessible routes map based on roles
  //     const accessRoutes = await store.dispatch('permission/generateRoutes', userPermissionList)
  //     // alert("--per-"+accessRoutes.length);
  //     // console.log(accessRoutes)

  //     // dynamically add accessible routes
  //     router.addRoutes(accessRoutes)

  //     // hack method to ensure that addRoutes is complete
  //     // set the replace: true, so the navigation will not leave a history record
  //     next({ ...to, replace: true })
  //   } catch (error) {
  //     console.log('111111')
  //     // remove token and go to login page to re-login
  //     await store.dispatch('user/resetToken')
  //     Message.error(error || 'Has Error')
  //     // next(`/login?redirect=${to.path}`)
  //   next()
  //     NProgress.done()
  //   }
  // }

  // const hasToken = getQueryString('authToken')
  if (hasToken) {
    // return
    if (to.path == '/login') {
      // if is logged in, redirect to the home page
      next({
        // path: '/',
        path: '/productAppConfig/projectManage',
      })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const { roles } = await store.dispatch('user/getInfo')

          const userPermissionList = await store.dispatch('user/getUserPermission')

          console.log('userPermissionList:' + userPermissionList)

          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes', userPermissionList)
          // alert("--per-"+accessRoutes.length);
          // console.log(accessRoutes)

          // dynamically add accessible routes
          router.addRoutes(accessRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          let option = {
            ...to,
            replace: true,
          }
          if (isIframe || !sessionStorage.getItem('firstRoute')) {
            if (true || !sessionStorage.getItem('firstRoute')) {
              if (accessRoutes && accessRoutes.length > 0 && accessRoutes[0] && accessRoutes[0].path) {
                if (accessRoutes[0].children && accessRoutes[0].children.length > 0 && accessRoutes[0].children[0] && accessRoutes[0].children[0].path) {
                  option.path = accessRoutes[0].path + '/' + accessRoutes[0].children[0].path
                } else {
                  option.path = accessRoutes[0].path
                }
              }
              if (!isIframe) {
                sessionStorage.setItem('firstRoute', 'true')
              }
            }
            // next({
            //   ...to,
            //   replace: true,
            // });
          }
          next(option)
        } catch (error) {
          if (isIframe) {
            window.parent.postMessage(
              {
                logout: true,
              },
              '*'
            )
          } else {
            try {
              let hash = location.hash.split('?')[1]

              location.hash =
                location.hash.split('?')[0] +
                '?' +
                hash
                  .split('&')
                  .filter((el) => {
                    return el.split('=')[0] != 'authToken'
                  })
                  .reduce((a, b) => {
                    return a + '&' + b
                  }, '')
            } catch (err) {}
            // remove token and go to login page to re-login
            await store.dispatch('user/resetToken')
            Message.error(error || 'Has Error')
            next(`/login?redirect=${to.path}`)
            NProgress.done()
          }
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
