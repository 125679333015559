<template>
  <div class="app-container" style="height: 100%">
    <el-card style="height: 100%">
      <div style="display: flex; height: 100%">
        <div>
          <div
            style="
              width: 380px;
              flex-shrink: 0;
              margin-right: 15px;
              border: 1px solid #ccc;
              height: 93%;
              border-radius: 4px
            "
          >
            <div class="project-box" 
              v-for="(item, index) in projectList" 
              :key="index"
              :style="{
                color:
                  projectType == index || project && project.id == item.id 
                    ? 'var(--98du-primary-color)'
                    : '',
              }"
              @click.stop="itemClick(item, index)
              "
            >
              <div class="project-name">{{item.name}}</div>
              <div class="project-android">{{item.platformTypeName}}</div>
              <div class="project-icon" @click.stop="pushTop(item.id)">
                <img src="../../../../../assets/sortIcon.png" class="sortIcon"/>
              </div>
              <div class="project-func" :style='{"background": item.status == 1 ? "#00FF00": ""}'></div>
            </div>
          </div>
          <div class="add-project">
            <el-button type="primary" size="small" @click.stop="addProject">添加应用</el-button>
          </div>
        </div>
        <div style="flex: 1; min-width: 0">
          <div class="content-one-box">
            <div class="content-one">
              <div class="content-name" v-if="projectList[projectType]">{{projectList[projectType].name}}&nbsp;&nbsp;(id:{{projectList[projectType].id}})</div>
              <div class="content-platform" v-if="projectList[projectType]">{{projectList[projectType].platformTypeName}}</div>
            </div>
            <div class="content-two" v-if="projectList[projectType]">创建时间: {{projectList[projectType].createdAt || '2024-05-15 23:59:59'}}</div>
            <el-popconfirm title="确定更改APP开关吗？" @confirm="updateAppStatus($event, projectList[projectType])">
              <el-switch 
                
                slot="reference"
                class="projectSwitch"
                :value="projectList[projectType] && projectList[projectType].status"
                :active-value="1"
                :inactive-value="0"               
              />
            </el-popconfirm>
          </div>

          <div class="content-two-box" :style='{"height": adverNetList&& adverNetList.length && adverNetList.length > 0 ? "": "63px"}'>
            <div class="content-two">
              <div class="content-name">广告网络({{adverNetList.length}})
                <el-button
                  class="adButton"
                  @click.stop="addAdverNetWork"
                  type="primary"
                  size="small">添加广告网络</el-button>
              </div>
              <div class="netList">
                <div class="content-platform-b" v-for="(item, index) in adverNetList" :key="index">
                  <!-- <img class="imgstyle" src="../../../../../assets/csj.png"/> -->
                  <div class="net-name">
                    <div>{{item.adNetworkName}}</div>
                    
                    <el-popconfirm title="确定要删除吗？" @confirm="delNet(item.id)">
                      <img 
                        slot="reference"
                        class="del-icon" 
                        src="../../../../../assets/del-icon.png" 
                      />
                    </el-popconfirm>

                  </div>

                </div>
              </div>
              
            </div>
          </div>

          <el-form
            ref="templeteForm"
            :model="requestParams"
            size="small"
            :inline="true"
          >
            <el-row style="flex-wrap: wrap">
              <div class="el-custom-grid">
                <el-form-item prop="type">
                  <el-select
                    class="el-select-darkness"
                    v-model="requestParams.type"
                    placeholder="广告位类型"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in adverTypeList"
                      :key="index"
                      :value="item.code"
                      :label="item.name"
                      >{{ item.name }}</el-option
                    >
                  </el-select>
                  <!-- <el-input
                    class="el-input-darkness"
                    v-model.trim="requestParams.name"
                    placeholder="ID/名称"
                    clearable
                  ></el-input> -->
                </el-form-item>

                <el-form-item prop="status">
                  <el-select
                    class="el-select-darkness"
                    v-model="requestParams.status"
                    placeholder="状态"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in statusList"
                      :key="index"
                      :value="item.type"
                      :label="item.name"
                      >{{ item.name }}</el-option
                    >
                  </el-select>
                </el-form-item>

                <div style="display: flex">
                  <el-button
                    size="small"
                    @click="handleSearch()"
                    class="el-button-search"
                    >查询</el-button
                  >
                  <el-button
                    size="small"
                    @click="handleReset()"
                    class="el-button-reset"
                    >重置</el-button
                  >
                  <el-button
                  @click.stop="addAdverSpace"
                    size="small"
                    type="primary"
                    >新增广告位</el-button
                  >
                </div>
              </div>
            </el-row>
          </el-form>

          <!-- 表格 -->
          <el-table
            size="small"
            :data="adverSpaceDataList"
            style="width: 100%"
            :header-cell-style="{
              height: '50px',
              fontSize: '14px',
              color: '#333',
              fontweight: 400,
            }"
          >
            <el-table-column label="操作" width="150" fixed="left">
              <template slot-scope="scope">
                <div>
                  <el-button
                    style="margin-right:20px"
                    type="text"
                    size="small"
                    @click="editData(scope.row)"
                    >编辑</el-button
                  >
                  <el-popconfirm title="确定要删除吗？" @confirm="delAdverSpace(scope.row.id)">
                    <el-button
                      slot="reference"
                      type="text"
                      size="small"
                      class="el-button-text-danger"
                      >删除</el-button>
                  </el-popconfirm>
                  
                  
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" width="150" fixed="left" align="center">
              <template slot-scope="scope">
                <el-popconfirm title="确定要更新吗？" @confirm="updateItemStatus(scope.row)">
                  <el-switch
                    slot="reference"
                    :value="scope.row.status"
                    :active-value="1"
                    :inactive-value="0"
                  />
                </el-popconfirm>
                
              </template>
            </el-table-column>
            <el-table-column prop="name" label="广告位名称" width="280" align="center"/>
            <el-table-column prop="typeName" label="广告类型" align="center"/> 
            <el-table-column prop="createTime" label="创建时间" align="center">
              <template slot-scope="scope">
                <!-- <div>{{ scope.row.createUserName }}</div> -->
                <div>{{ scope.row.createTime }}</div>
              </template>
            </el-table-column>
          </el-table>

          <div class="page_block">
            <el-pagination
              :current-page="pageParam.pageNum"
              :page-sizes="[10, 20, 30, 40, 50]"
              :page-size="pageParam.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageParam.total"
              @size-change="pageSizeChange"
              @current-change="pageIndexChange"
            />
          </div>
        </div>
      </div>
      
    </el-card>

    <!-- 新建、编辑套餐 -->
    <EditModal
      :visible="editVisible"
      @close="editVisible = false"
    ></EditModal>

    <AdverNetwork
      :visible="adverNetVisible"
      :configAppId="projectList[projectType] && projectList[projectType].id"
      @close="adverNetVisible = false"
    ></AdverNetwork>

    <AddAdver
      :visible="addAdverVisible"
      :configAppId="projectList[projectType] && projectList[projectType].id"
      :adverTypeList="adverTypeList"
      @close="addAdverVisible = false"
    ></AddAdver>

    <EditAdverSpace
      :visible="adverspaceVisible"
      :data="itemData"
      :adverTypeList="adverTypeList"
      :detailId="detailId"
      @close="adverspaceVisible = false"
    ></EditAdverSpace>

    <!-- <EditModal
      :show="editVisible"
      @close="editVisible = false"
      @refresh="loadData()"
      :editType="editType"
      :addTypeList="addTypeList"
      :project="project"
      :syncTypeList="syncTypeList"
      :dimTypeList="dimTypeList"
      :detailId="detailId"
    ></EditModal> -->

    <!-- <ViewData
      :visible="showData"
      :configId="detailId"
      @close="showData = false"
    />

    <ViewLog :visible="showLog" :configId="detailId" @close="showLog = false" />

    <init-dialog
      :visible="showInitDialog"
      :configId="detailId"
      :list="projectList.length > 1 ? projectList[1].list : []"
      @close="showInitDialog = false"
      @confirm="
        showInitDialog = false;
        loadData();
      "
    /> -->
  </div>
</template>

<script>
import {
  dataDimConfigList,
  getProjectList,
  listProjectId,
  dataDimConfigUpdateDelete,
  dataDimConfigUpdateStatus,
  initTableData,
  dataDimExecuteSave,
  dataDimExecuteDetails,
  syncOnce,
} from '@/api/dimensionManage';
import EditModal from './components/EditModal.vue';
import AdverNetwork from './components/AdverNetwork.vue';
import AddAdver from './components/AddAdver.vue';
import EditAdverSpace from './components/EditAdverSpace.vue';
import ViewData from '@/components/ViewData/index.vue';
// import ViewLog from '@/components/ViewLog/index.vue';
// import initDialog from './components/initDialog.vue';
import applicationConfigApi from '@/api/pages/applicationConfig';
export default {
  name: 'projectManage',
  components: {
    EditModal,
    AdverNetwork,
    AddAdver,
    EditAdverSpace
    // ViewData,
    // ViewLog,
    // initDialog,
  },
  data() {
    return {
      activeNames: [0, 1],
      dataList: [],
      requestParams: {
        status: '',
        type: '',
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      statusList: [
        { type: 0, name: '关闭' },
        { type: 1, name: '开启' },
      ],
      editType: '',
      editVisible: false,
      detailId: '',
      projectList:[
      ],
      project: '',
      syncTypeList: [
        {
          code: 1,
          label: '监听业务库Binlog',
        },
        {
          code: 2,
          label: '业务侧主动推送到API',
        },
        {
          code: 3,
          label: '定时调用业务侧API拉取',
        },
        {
          code: 4,
          label: '定时SQL从业务库拉取',
        },
      ],
      dimTypeList: [],
      addTypeList: [
        {
          code: 1,
          label: '增量',
        },
        {
          code: 2,
          label: '全量',
        },
      ],
      showData: false,
      showLog: false,
      showInitDialog: false,
      projectType: 0,
      adverNetVisible: false,
      addAdverVisible: false,
      adverNetList:[],
      adverTypeList:[], //广告位类型
      adverSpaceDataList:[],
      adverspaceVisible: false,
      itemData:{}
    };
  },
  created() {
    this.getProjectList();

  },
  mounted() {},
  watch: {
    project(newV) {
      // this.initData();
      // this.listProjectId();
    },
  },
  methods: {
    pushTop(val) {
      applicationConfigApi.appPushTop({id: val}).then((res) => {
        if (res && res.code === 1) {
          this.getProjectList()
          this.$message.success('置顶成功')
        }
      })
    },

    addProject() {
      console.log('ddddd')
      this.editVisible = true
    },

    getProjectList() {
      applicationConfigApi.appList().then((res) => {
        if (res && res.code === 1 && res.data) {
          this.projectList = res.data
          this.getAdverNetList()
          this.adverSpaceList() 
          this.getadverSpaceType()
        }
      })
      .catch((e) => {
        console.log(e);
      });
    },

     getAdverNetList() {
      applicationConfigApi.appNeworkList({
        configAppId: this.projectList[this.projectType].id
      }).then((res) => {
        if (res && res.code == 1 && res.data) {
          this.adverNetList = res.data || []
        }
      })
    },

    // 广告位列表
    adverSpaceList() {
      applicationConfigApi.adverSpaceList({
        configAppId: this.projectList[this.projectType].id,
        ...this.requestParams,
        ...this.pageParam
      }).then((res) => {
        if (res && res.code == 1 && res.data) {
          this.adverSpaceDataList = res.data.list || []
          this.pageParam.total = res.data.total;
        }
      })
    },

    // 获取广告位类型
    getadverSpaceType() {
      applicationConfigApi.typeSelectList().then((res) => {
        if (res && res.code == 1 && res.data) {
          this.adverTypeList = res.data || []
        }
      })
    },

    //广告位删除
    delAdverSpace(val) {
      applicationConfigApi.remove({id: val}).then((res) => {
        if (res && res.code == 1) {
          this.$message.success('删除成功')
          this.adverSpaceList()
        }
      })
    },

    itemClick(item, index) {
      this.adverNetList = []
      this.project = item;
      this.projectType = index;
      this.getAdverNetList()
      this.adverSpaceList() 
    },

    // 更新APP状态
    updateAppStatus(e, row,) {
      let msg = row.status == 1 ? '确定关闭？' : '确定开启？';
      let val = row.status == 1 ? 0 : 1;
      applicationConfigApi.appUpdateStatus({ id: row.id, status: val })
        .then((res) => {
          if (res && res.code === 1) {
            this.$message.success('修改成功');
            this.getProjectList();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //操作广告位列表状态
    updateItemStatus(row) {
      let val = row.status == 1 ? 0 : 1;
        applicationConfigApi.updateStatus({ id: row.id, status: val })
          .then((res) => {
            if (res && res.code === 1) {
              this.$message.success('修改成功');
              this.adverSpaceList();
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },

    delNet(val) {
      applicationConfigApi.deleteAppNework({ id: val })
        .then((res) => {
          if (res && res.code === 1) {
            this.$message.success('删除成功');
            this.getAdverNetList();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    addAdverNetWork(){
      this.adverNetVisible = true
    },

    addAdverSpace() {
      this.addAdverVisible = true
    },

    listProjectId() {
      listProjectId({ projectId: this.project.id }).then((res) => {
        if (res && res.code == 1 && res.data) {
          this.dimTypeList = res.data || [];
        }
      });
    },
    handleChange(val) {},
    initData() {
      this.pageParam = {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      };
      this.loadData();
    },
    // 获取列表
    loadData() {
      let that = this;
      dataDimConfigList({
        ...that.requestParams,
        ...that.pageParam,
        projectId: this.project.id,
      })
        .then((res) => {
          if (res && res.code === 1 && res.data) {
            that.dataList = res.data.list;
            that.pageParam.total = res.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // 分页
    pageSizeChange(val) {
      this.pageParam.pageSize = val;
      this.pageParam.pageNum = 1;
      this.adverSpaceList();
    },
    // 分页
    pageIndexChange(val) {
      this.pageParam.pageNum = val;
      this.adverSpaceList();
    },

    // 编辑、新增、复制
    editData(row, type) {
      this.adverspaceVisible = true;
      this.itemData = row
      this.detailId = row.id;
    },

    //删除
    del(id) {
      this.$confirm('确定删除？', '提示', { type: 'warning' }).then(() => {
        applicationConfigApi.remove({ id }).then((res) => {
          if (res && res.code == 1) {
            this.$message.success('删除成功');
            this.adverSpaceList();
          }
        });
      });
    },

    //查看数据
    watchData(id) {
      this.detailId = id;
      this.showData = true;
    },

    //日志
    watchLog(id) {
      this.detailId = id;
      this.showLog = true;
    },

    //调用
    callThat(item) {
      syncOnce({ id: item.id }).then((res) => {
        if (res && res.code == 1) {
          this.$message.success('调用成功');
          this.loadData();
        }
      });
      // dataDimExecuteDetails({ id: item.id }).then((res) => {
      //   if (res && res.code == 1 && res.data) {
      //     if(res.data.ddlSql){
      //       dataDimExecuteSave({
      //         ddlSql: res.data.ddlSql,
      //         dimConfigId: item.id,
      //       }).then((res) => {
      //         if (res && res.code == 1) {
      //           this.$message.success('执行成功');
      //           this.loadData();
      //         }
      //       });
      //     }else{
      //       this.$message.error('执行sql不能为空')
      //     }
      //   }
      // });
    },

    handleSearch() {
      this.adverSpaceList();
    },
    handleReset() {
      this.$refs.templeteForm.resetFields();
      this.initData();
    },

    // 获取平台名称
    getFieldName(val) {
      let list = this.platformList;
      if (list && list.length > 0) {
        let obj = list.filter((item) => {
          return item.code == val;
        });
        if (obj.length > 0) {
          return obj[0].name;
        }
      }
      return null;
    },

    goConfig(id) {
      this.$router.push({
        path: '/productAppConfig/appManage',
        query: { id },
      });
    },
    handleInit(id) {
      if (this.projectType == 0) {
        this.detailId = id;
        this.showInitDialog = true;
      } else {
        this.initTableData(id);
      }
    },
    initTableData(id) {
      initTableData({ id, projectId: this.project.id }).then((res) => {
        if (res && res.code == 1) {
          this.$message.success('初始化成功');
          this.loadData();
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.project-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  font-size: 13px;
  position: relative;
  cursor: pointer;
}
.add-project {
  text-align: center;
  margin-top: 15px;
}
.project-name {
  width: 110px;
}

.project-icon {
  width: 20px;
  height: 20px;
}
.sortIcon {
  width: 100%;
  height: 100%;
}

.project-func {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
}

.projectSwitch {
    margin-left: 50px;
}

.project-box::after {
    content: "";
    width: 378px;
    height: 0.02rem;
    background: #f4f4f4;
    display: block;
    position: absolute;
    right: 0;
    top: -2px;
}
.project-box:hover {
  // color: var(--98du-primary-color);
    // background: #409eff
}
.content-one {
  display: flex;
  margin-right: 50px;
}
.content-name {
  margin-right: 20px;
}
.adButton{
  margin-left: 15px;
}
.content-platform-a {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-top: 20px;
}

.content-platform {
  display: flex;
  align-items: center;
}

.netList {
  display: flex;
  align-items: center;
  padding-top: 30px;
}
.content-platform-b {
  width: 60px;
  height: 28px;
  background: #EAEAEA;
  border-radius: 4px;
  line-height: 28px;
  font-size: 15px;
  text-align: center;
  margin-right: 25px;
}
.imgstyle {
  width: 40px;
  margin-right: 5px;
}
.net-name {
  position: relative;
  // transform: translateY(5px);
  // font-size: 15px;
}
.del-icon {
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.content-one-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px; 
  color: #333;
  height: 60px;
  border: 1px solid #ccc;
  padding: 0 20px;
  border-radius: 4px;
}
.content-two-box {
  height: 120px;
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin-bottom: 40px;
  border-radius: 4px;

}
.qy_img {
  display: flex;
  .qy_img_item {
    margin-right: 20px;
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
    background: #000;
    border-radius: 10px;
    position: relative;
    img {
      width: 55px;
      margin: auto;
      display: block;
    }
  }
  .qy_img_name {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
  }
  .qy_img_name1 {
    text-align: center;
    font-size: 8px;
    margin-top: 5px;
    color: #fff;
  }
}
.top_title {
  margin-bottom: 20px;
  .txt {
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
  }
}
.page_block {
  text-align: center;
  margin-top: 20px;
}
.button-box {
  text-align: right;
  margin-top: 40px;
}
.title-tip {
  display: flex;
}
.rightTitle {
  margin-left: 8px;
  font-weight: bold;
}
.text {
  color: #999;
  font-size: 13px;
}
.imgs {
  width: 80px;
  height: 80px;
}
.top-container {
  display: flex;
  justify-content: space-between;
}
.top-right {
  margin-left: 200px;
  display: flex;
  align-items: center;
}
.right-new {
  margin-right: 40px;
}
.size-icon {
  font-size: 30px;
  margin-right: 30px;
}
.img-list {
  margin-left: 50px;
}
.img-title {
  text-align: center;
  margin-bottom: 15px;
}
.img-item {
  display: block;
  width: 100px;
  margin: auto;
}
.img-tip {
  margin-left: 20px;
}
.img-tip:nth-child(2) {
  margin-top: 20px;
}

.avatar-uploader {
  .el-upload {
    display: block;
    border: 1px dashed #ccc;
    border-radius: 6px;
  }
  .el-icon-plus {
    border: 1px dashed #ccc;
    border-radius: 6px;
  }
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.labelTips {
  max-width: 100px;
  max-height: 20px;
}
.top_btn {
  margin-bottom: 20px;
}
::v-deep .el-card__body {
  height: 100%;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0;
}
</style>
