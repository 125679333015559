<template>
  <div class="login-container">
    <img :src="require('@/assets/login/bg.png')" alt="" class="login-container-bg" />
    <img :src="require('@/assets/login/logo.png')" alt="" class="logo" />
    <img :src="require('@/assets/login/big-logo.png')" alt="" class="big-logo" />
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form el-form-block el-form-no-check" autocomplete="on" label-position="left">
      <div class="title-container">
        <h3 class="title">轩辕后台</h3>
      </div>

      <el-form-item prop="username">
        <el-input ref="username" v-model="loginForm.username" placeholder="账号" name="username" type="text" tabindex="1" autocomplete="on" />
      </el-form-item>

      <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
        <el-form-item prop="password">
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="密码"
            name="password"
            tabindex="2"
            autocomplete="on"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
          <span class="show-pwd" @click="showPwd">
            <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
          </span>
        </el-form-item>
      </el-tooltip>
      <div class="auto-login">
        <img @click.stop="isAutoLogin = !isAutoLogin" :src="require(`@/assets/login/${isAutoLogin ? 'select' : 'normal'}.png`)" alt="" class="big-logo" /><span
          @click.stop="isAutoLogin = !isAutoLogin"
          >自动登录</span
        >
      </div>
      <el-button :loading="loading" type="primary" style="width: 100%; margin-top: 1.39vw" @click.native.prevent="handleLogin">登录</el-button>

      <!-- <div style="position:relative">
        <div class="tips">
          <span>Username : admin</span>
          <span>Password : any</span>
        </div>
        <div class="tips">
          <span style="margin-right:18px;">Username : editor</span>
          <span>Password : any</span>
        </div>

        <el-button class="thirdparty-button" type="primary" @click="showDialog=true">
          Or connect with
        </el-button>
      </div> -->
    </el-form>

    <el-dialog title="Or connect with" :visible.sync="showDialog">
      Can not be simulated on local, so please combine you own business simulation! ! !
      <br />
      <br />
      <br />
      <social-sign />
    </el-dialog>
  </div>
</template>

<script>
import { validUsername } from '@/utils/validate'
import SocialSign from './components/SocialSignin'

export default {
  name: 'Login',
  components: { SocialSign },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value == null || value == '') {
        callback(new Error('请输入账号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: '',
        password: '',
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      isAutoLogin: false,
      isLoad: false,
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true,
    },
    isAutoLogin(newV) {
      if (this.isLoad) {
        if (newV) {
          localStorage.setItem('isAutoLogin', 1)
        } else {
          localStorage.removeItem('isAutoLogin')
        }
      }
    },
  },
  created() {
    let isAutoLogin = localStorage.getItem('isAutoLogin')
    this.isAutoLogin = isAutoLogin ? true : false
    this.isLoad = true
    // window.addEventListener('storage', this.afterQRScan)
  },
  mounted() {
    if (!this.isAutoLogin) {
      if (this.loginForm.username === '') {
        this.$refs.username.focus()
      } else if (this.loginForm.password === '') {
        this.$refs.password.focus()
      }
    } else {
      this.$nextTick(() => {
        let userinfo = localStorage.getItem('userinfo')
        try {
          let obj = JSON.parse(userinfo)
          this.loginForm.username = obj.username
          this.loginForm.password = obj.password
          this.handleLogin()
        } catch (err) {}
      })
    }
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store
            .dispatch('user/login', this.loginForm)
            .then(() => {
              if (this.isAutoLogin) {
                localStorage.setItem('userinfo', JSON.stringify(this.loginForm))
              } else {
                localStorage.removeItem('userinfo')
              }
              this.$router.push({
                path: this.redirect || '/',
                query: this.otherQuery,
              })
              // this.$router.push({ path: 'productAppConfig/projectManage' });
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }
  },
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: #333;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 2.6vw;
    width: 240px;
    border: 0;
    input {
      background: #f2f4f7 !important;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: #333;
      height: 2.6vw;
      caret-color: #333;
      font-size: 0.97vw;
      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #f2f4f7 inset !important;
        -webkit-text-fill-color: #999 !important;
      }
      &::-webkit-input-placeholder {
        color: #999;
        font-size: 0.97vw;
      }
    }
  }

  .el-form-item {
    border-radius: 0.28vw;
    color: #454545;
    border: 0px !important;
    margin-bottom: 1.25vw;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;

  overflow: hidden;
  position: relative;

  &-bg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .logo {
    position: absolute;
    top: 3.6vw;
    left: 4.8vw;
    width: 6.9vw;
    z-index: 1;
    height: auto;
  }
  .big-logo {
    position: absolute;
    bottom: 4vw;
    left: 0;
    width: 64vw;
    z-index: 1;
    height: auto;
  }
  .login-form {
    position: absolute;
    z-index: 1;
    width: 22.2vw;
    max-width: 100%;
    padding: 2.4vw 2.7vw 3.2vw;
    right: 13vw;
    margin: 34vh 0 0;
    overflow: hidden;
    background: #fff;
    border-radius: 0.28vw;
    .auto-login {
      display: flex;
      margin-top: 1.1vw;
      align-items: center;
      img {
        cursor: pointer;
        width: 0.83vw;
        height: auto;
        position: relative;
        top: 0;
        bottom: 0;
      }
      span {
        cursor: pointer;
        margin-left: 0.28vw;
        font-size: 0.83vw;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 1.18vw;
        color: #585e6d;
      }
    }
    button {
      height: 2.36vw;
      font-size: 0.9vw;
      line-height: 2.36vw;
    }
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      margin: 0 0 1.8vw;
      text-align: center;
      font-weight: bold;
      font-size: 1.4vw;
      line-height: 1.94vw;
      color: #333333;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
::v-deep .el-form-item__content {
  display: flex;
}
::v-deep .el-input {
  flex: 1;
}
::v-deep .el-form-item.is-error.is-required .el-form-item__error {
  font-size: 0.69vw !important;
}
::v-deep .el-input__inner {
  vertical-align: top;
}
::v-deep .show-pwd {
  height: 100% !important;
  top: 0 !important;
  font-size: 1.1vw !important;
  line-height: 2.5vw;
}
</style>
