var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-container" },
    [
      _c("el-tag", [_vm._v("mounted times ：" + _vm._s(_vm.createdTimes))]),
      _c("el-alert", {
        staticStyle: {
          width: "200px",
          display: "inline-block",
          "vertical-align": "middle",
          "margin-left": "30px",
        },
        attrs: {
          closable: false,
          title: "Tab with keep-alive",
          type: "success",
        },
      }),
      _c(
        "el-tabs",
        {
          staticStyle: { "margin-top": "15px" },
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tabMapOptions, function (item) {
          return _c(
            "el-tab-pane",
            { key: item.key, attrs: { label: item.label, name: item.key } },
            [
              _c(
                "keep-alive",
                [
                  _vm.activeName == item.key
                    ? _c("tab-pane", {
                        attrs: { type: item.key },
                        on: { create: _vm.showCreatedTimes },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }