var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: !_vm.editType
              ? "新建流量分组规则配置"
              : "编辑流量分组规则配置",
            visible: _vm.visible,
            direction: "rtl",
            "before-close": _vm.handleClose,
            size: 1000,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dialogForm",
              attrs: {
                model: _vm.dataSource,
                size: "small",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "名称",
                    prop: "name",
                    rules: {
                      required: true,
                      message: "请选择",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.dataSource.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dataSource,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "dataSource.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "流量分组规则" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "text" },
                      on: { click: _vm.addParams },
                    },
                    [_vm._v("新增规则项")]
                  ),
                ],
                1
              ),
              _vm._l(_vm.dataSource.items, function (item, index) {
                return _c(
                  "el-col",
                  {
                    key: index,
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "margin-left": "88px",
                      "margin-bottom": "15px",
                      width: "90%",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "0" },
                        attrs: {
                          prop: "items." + index + ".fieldCode",
                          label: "",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "gap",
                            staticStyle: {
                              width: "180px",
                              "margin-left": "-87px",
                            },
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.dataSource.items[index].fieldCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataSource.items[index],
                                  "fieldCode",
                                  $$v
                                )
                              },
                              expression: "dataSource.items[index].fieldCode",
                            },
                          },
                          _vm._l(_vm.ruleConfigList, function (citem, cindex) {
                            return _c("el-option", {
                              key: cindex,
                              attrs: {
                                label: citem.name,
                                value: citem.fieldCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.dataSource.items[index].fieldCode == ""
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "formLable",
                            staticStyle: { "margin-bottom": "0" },
                            attrs: {
                              prop: "items." + index + ".filterField",
                              label: "",
                              "label-width": "20px",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "180px" },
                              attrs: { placeholder: "请输入", clearable: "" },
                              model: {
                                value: _vm.dataSource.items[index].filterField,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataSource.items[index],
                                    "filterField",
                                    $$v
                                  )
                                },
                                expression:
                                  "dataSource.items[index].filterField",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "0" },
                        attrs: {
                          prop: "items." + index + ".operatorChar",
                          label: "",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "gap",
                            staticStyle: {
                              width: "180px",
                              "margin-left": "-87px",
                            },
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.dataSource.items[index].operatorChar,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataSource.items[index],
                                  "operatorChar",
                                  $$v
                                )
                              },
                              expression:
                                "dataSource.items[index].operatorChar",
                            },
                          },
                          _vm._l(_vm.symbolList, function (citem, cindex) {
                            return _c("el-option", {
                              key: cindex,
                              attrs: { label: citem.name, value: citem.code },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "formLable",
                        staticStyle: { "margin-bottom": "0" },
                        attrs: {
                          prop: "items." + index + ".filterVal",
                          label: "",
                          "label-width": "20px",
                        },
                      },
                      [
                        _c("el-input", {
                          style: {
                            "margin-bottom": 0,
                            width:
                              _vm.dataSource.items[index].fieldCode == ""
                                ? "200px"
                                : "400px",
                          },
                          attrs: {
                            placeholder: "请输入（输入多个的话用逗号隔开）",
                            clearable: "",
                          },
                          model: {
                            value: _vm.dataSource.items[index].filterVal,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dataSource.items[index],
                                "filterVal",
                                $$v
                              )
                            },
                            expression: "dataSource.items[index].filterVal",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.dataSource.items.length > 1
                      ? _c("i", {
                          staticClass: "el-icon-remove-outline",
                          staticStyle: {
                            color: "rgb(240, 12, 12)",
                            "font-size": "24px",
                            "margin-left": "15px",
                            height: "24px",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delParams(index)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "status", label: "状态" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.dataSource.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataSource, "status", $$v)
                          },
                          expression: "dataSource.status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "规则说明",
                        prop: "remark",
                        rules: {
                          required: true,
                          message: "请输入",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          placeholder: "请输入",
                          type: "textarea",
                          rows: "5",
                          clearable: "",
                        },
                        model: {
                          value: _vm.dataSource.remark,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dataSource,
                              "remark",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "dataSource.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "save-btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.save },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }