<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <div v-if="collapse" class="sidebar-logo-link">
        <router-link v-if="!logo" key="collapse" to="">
          <div class="sidebar-logo-link-label">
            <img src="../../../assets/logo-new.png" class="sidebar-logo" />
            <!-- <h1 class="sidebar-title">门户系统</h1> -->
          </div>
        </router-link>
        <span 
          v-else
          class='iconfont icon98du-menu-unfold fold'
          @click.stop="$store.dispatch('app/updateSideBar', true)"
        ></span>
      </div>
      <div v-else class="sidebar-logo-link">
        <router-link key="expand" to="" v-if="logo">
          <div class="sidebar-logo-link-label">
            <img src="../../../assets/logo-new.png" class="sidebar-logo" />
            <!-- <h1 class="sidebar-title">门户系统</h1> -->
          </div>
        </router-link>
        <span 
          @click.stop="$store.dispatch('app/updateSideBar', false)"
          class='iconfont icon98du-menu-fold fold'
        ></span>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: '门户系统',
      logo: '../../../assets/logo.png',
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}
.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 45px;
  line-height: 45px;
  background: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 16px 0 20px;
  &.collapse{
    justify-content: center;
    .sidebar-logo-link{
      width: auto;
    }
  }
  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    .sidebar-logo-link-label {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    & .sidebar-logo {
      width: 132px;
      height: 45px;
      vertical-align: middle;
      margin-right: 2px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #333;
      font-weight: 600;
      line-height: 20px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
    .iconfont{
      color: #333;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
  .fold {
    cursor: pointer;
  }
}
</style>
