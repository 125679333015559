var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          staticClass: "form-container",
          attrs: { model: _vm.postForm, rules: _vm.rules },
        },
        [
          _c(
            "sticky",
            {
              attrs: {
                "z-index": 10,
                "class-name": "sub-navbar " + _vm.postForm.status,
              },
            },
            [
              _c("CommentDropdown", {
                model: {
                  value: _vm.postForm.comment_disabled,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "comment_disabled", $$v)
                  },
                  expression: "postForm.comment_disabled",
                },
              }),
              _c("PlatformDropdown", {
                model: {
                  value: _vm.postForm.platforms,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "platforms", $$v)
                  },
                  expression: "postForm.platforms",
                },
              }),
              _c("SourceUrlDropdown", {
                model: {
                  value: _vm.postForm.source_uri,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "source_uri", $$v)
                  },
                  expression: "postForm.source_uri",
                },
              }),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "success" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v(" Publish ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { type: "warning" },
                  on: { click: _vm.draftForm },
                },
                [_vm._v(" Draft ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "createPost-main-container" },
            [
              _c(
                "el-row",
                [
                  _c("Warning"),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "40px" },
                          attrs: { prop: "title" },
                        },
                        [
                          _c(
                            "MDinput",
                            {
                              attrs: {
                                maxlength: 100,
                                name: "name",
                                required: "",
                              },
                              model: {
                                value: _vm.postForm.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.postForm, "title", $$v)
                                },
                                expression: "postForm.title",
                              },
                            },
                            [_vm._v(" Title ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "postInfo-container" },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "postInfo-container-item",
                                      attrs: {
                                        "label-width": "60px",
                                        label: "Author:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            "remote-method":
                                              _vm.getRemoteUserList,
                                            filterable: "",
                                            "default-first-option": "",
                                            remote: "",
                                            placeholder: "Search user",
                                          },
                                          model: {
                                            value: _vm.postForm.author,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postForm,
                                                "author",
                                                $$v
                                              )
                                            },
                                            expression: "postForm.author",
                                          },
                                        },
                                        _vm._l(
                                          _vm.userListOptions,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: item + index,
                                              attrs: {
                                                label: item,
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "postInfo-container-item",
                                      attrs: {
                                        "label-width": "120px",
                                        label: "Publish Time:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "datetime",
                                          format: "yyyy-MM-dd HH:mm:ss",
                                          placeholder: "Select date and time",
                                        },
                                        model: {
                                          value: _vm.displayTime,
                                          callback: function ($$v) {
                                            _vm.displayTime = $$v
                                          },
                                          expression: "displayTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "postInfo-container-item",
                                      attrs: {
                                        "label-width": "90px",
                                        label: "Importance:",
                                      },
                                    },
                                    [
                                      _c("el-rate", {
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                        attrs: {
                                          max: 3,
                                          colors: [
                                            "#99A9BF",
                                            "#F7BA2A",
                                            "#FF9900",
                                          ],
                                          "low-threshold": 1,
                                          "high-threshold": 3,
                                        },
                                        model: {
                                          value: _vm.postForm.importance,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.postForm,
                                              "importance",
                                              $$v
                                            )
                                          },
                                          expression: "postForm.importance",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "40px" },
                  attrs: { "label-width": "70px", label: "Summary:" },
                },
                [
                  _c("el-input", {
                    staticClass: "article-textarea",
                    attrs: {
                      rows: 1,
                      type: "textarea",
                      autosize: "",
                      placeholder: "Please enter the content",
                    },
                    model: {
                      value: _vm.postForm.content_short,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "content_short", $$v)
                      },
                      expression: "postForm.content_short",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.contentShortLength,
                          expression: "contentShortLength",
                        },
                      ],
                      staticClass: "word-counter",
                    },
                    [_vm._v(_vm._s(_vm.contentShortLength) + "words")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: { prop: "content" },
                },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.postForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "content", $$v)
                      },
                      expression: "postForm.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: { prop: "image_uri" },
                },
                [
                  _c("Upload", {
                    model: {
                      value: _vm.postForm.image_uri,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "image_uri", $$v)
                      },
                      expression: "postForm.image_uri",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }