/**
 * 系统管理模块
 */
 import Layout from '@/layout';
 const productAppConfigRouter = {
   path: '/fallsFlowManageConfig',
   component: Layout,
   name: 'fallsFlowManageConfig',
   redirect: 'noRedirect',
   permission: 0,
   meta: {
     title: '瀑布流管理',
     icon: 'iconfont icon98du-xitongguanli',
   },
   children: [
     {
       path: 'fallsFlowManage',
       component: () =>
         import('@/views/pages/fallsFlowManageConfig/fallsFlowManage'),
       name: 'fallsFlowManage',
       permission: 0,
       meta: {
         title: '瀑布流管理',
         noCache: false,
         scrollTop: 0,
       },
     },
    //  {
    //    path: 'trafficGroupRule',
    //    component: () =>
    //      import(
    //        '@/views/pages/productAppConfig/productConfig/trafficGroupRule'
    //      ),
    //    name: 'trafficGroupRule',
    //    permission: 0,
    //    meta: {
    //      title: '流量分组规则配置',
    //      noCache: false,
    //      scrollTop: 0,
    //    },
    //  },
   ],
 };
 export default productAppConfigRouter;
 