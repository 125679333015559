<template>
  <div class="app-container">
    <el-card>
      <el-row>
        <el-form
          ref="templeteForm"
          :model="requestParams"
          size="small"
          :inline="true"
        >
          <el-row type="flex" style="flex-wrap: wrap">
            <div class="el-custom-grid">
                <el-form-item prop="id">
                    <el-input
                        class="el-input-darkness"
                        v-model.trim="requestParams.id"
                        placeholder="分组规则ID"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item prop="name">
                    <el-input
                        class="el-input-darkness"
                        v-model.trim="requestParams.name"
                        placeholder="分组规则名称"
                        clearable
                    ></el-input>
                </el-form-item>
              <el-form-item prop="status">
                <el-select
                  class="el-select-darkness"
                  v-model="requestParams.status"
                  placeholder="状态"
                  clearable
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>

              <div>
                <el-button
                  size="small"
                  @click="handleSearch()"
                  class="el-button-search"
                  >查询</el-button
                >
                <el-button
                  size="small"
                  @click="handleReset()"
                  class="el-button-reset"
                  >重置</el-button
                >
              </div>
            </div>
          </el-row>
          <el-col :span="24">
            <el-button
              type="primary"
              size="small"
              @click="editData()"
              class="top_btn"
              ><i class="el-icon-plus"></i>新建</el-button
            >
          </el-col>
        </el-form>

        <!-- 表格 -->
        <el-table
          size="small"
          :data="dataList"
          style="width: 100%"
          :header-cell-style="{
            height: '50px',
            fontSize: '14px',
            color: '#333',
            fontweight: 400,
          }"
          border
        >
          <el-table-column label="操作" fixed="left" width="200">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="editData(scope.row.id, 'edit')"
                >编辑</el-button
              >

              <el-popconfirm title="确定要删除吗？" @confirm="delTraffGroup(scope.row.id)">
                <el-button
                  style="margin-left:30px"
                  slot="reference"
                  type="text"
                  size="small"
                  class="el-button-text-danger"
                  >删除</el-button>
              </el-popconfirm>
              <!-- <el-button
                type="text"
                size="small"
                @click="testLinks(scope.row)"
                >查看关联流量分组</el-button
              > -->
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" width="120" align="center">
            <template slot-scope="scope">
              <el-switch
                :value="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="updateStatus($event, scope.row, scope.$index)"
              />
            </template>
          </el-table-column>
          <el-table-column prop="id" label="ID" align="center"/>
          <el-table-column prop="name" label="名称" align="center"/>
          <el-table-column prop="remark" label="规则说明" align="center"/>
        </el-table>

        <div class="page_block">
          <el-pagination
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="pageSizeChange"
            @current-change="pageIndexChange"
          />
        </div>
      </el-row>
    </el-card>

    <!-- 新建、编辑套餐 -->
    <AddtrafficGroupRule
      :visible="trafficGroupVisible"
      :editType="editType"
      :symbolList="symbolList"
      :ruleConfigList="ruleConfigList"
      @close="trafficGroupVisible = false"
    ></AddtrafficGroupRule>
    
  </div>
</template>

<script>
import proApi from '@/api/pages/proApi';
import AddtrafficGroupRule from './components/AddtrafficGroupRule';
import applicationConfigApi from '@/api/pages/applicationConfig';
export default {
  name: 'dataSourceConfigList',
  components: {
    AddtrafficGroupRule,
  },
  data() {
    return {
      dataList: [],
      requestParams: {
        id: '',
        name: '',
        status: '',
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      
      editType: '',
      editVisible: false,
      detailId: '',
      tenantDbList: [],
      typeList: [],
      dimensionSetVisible: false,
      seteditType: '',
      dimensionTitle: '',
      dimensionId: '',
      projectList:[],
      databaseTypeList:[],
      statusList:[
        {code: 1, name: '启用'},
        {code: 0, name: '关闭'},
      ],
      trafficGroupVisible: false,
      ruleConfigList:[],
      symbolList:[]
    };
  },

  mounted() {
    // this.getTraffGroupData()
    this.initData();
    this.getTraffGroupOperatorList();
    this.getsymbolList()
    // this.getDataSourceType()
  },
  methods: {

      addTraffGroup() {
        this.trafficGroupVisible = true
      },

    getProjectList() {
      proApi.projectSelectList({isOnlyBiz:0}).then((res) => {
        this.projectList = res.data;
      });
    },

    getDataSourceType() {
      proApi.listDataSourceType({}).then((res) => {
        if(res && res.code == 1 && res.data) {
          this.databaseTypeList = res.data;
        }
        
      });
    },
   
    initData() {
      this.pageParam = {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      };
      this.loadData();
    },
    // 获取列表
    loadData() {
      let that = this;
      applicationConfigApi.trafficGroupPage({
        ...that.requestParams,
        ...that.pageParam,
      }).then((res) => {
        if (res && res.code === 1 && res.data) {
          that.dataList = res.data.list;
          that.pageParam.total = res.data.total;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    },

    //流量分组删除
    delTraffGroup(val) {
      applicationConfigApi.trafficGroupRemove({id: val}).then((res) => {
        if (res && res.code == 1) {
          this.$message.success('删除成功')
          this.loadData()
        }
      })
    },

    getTraffGroupOperatorList() {
      applicationConfigApi.fieldCodeList().then((res) => {
        if (res.code == 1 && res.data) {
          this.ruleConfigList = res.data
        }
      });
    },

    getsymbolList() {
      applicationConfigApi.operatorList().then((res) => {
        if (res.code == 1 && res.data) {
          this.symbolList = res.data
        }
      });
    },

    // 测试链接
    testLinks(row) {
      proApi.testConnect({
        dsUrl: row.dsUrl,
        dsPassword: row.dsPassword,
        dsAccount: row.dsAccount,
        dsType: row.dsType
      }).then(res =>{
        if(res && res.code == 1 && res.data) {
          this.$message.success(res.data)
        }else if(res && res.code == 0){
          this.$message.error(res.data)
        }
      })
    },

    //操作状态
    updateStatus(e, row, index) {
      let msg = row.status == 1 ? '确定关闭？' : '确定开启？';
      let val = row.status == 1 ? 0 : 1;
      this.$confirm(msg, '提示', { type: 'warning' }).then(() => {
        proApi.trafficUpdateStatus({ id: row.id, status: val }).then((res) => {
          if (res && res.code == 1) {
            this.$message.success('修改成功');
            this.initData();
          }
        })
        .catch((e) => {
          console.log(e);
        });
      });
    },

    // 分页
    pageSizeChange(val) {
      this.pageParam.pageSize = val;
      this.pageParam.pageNum = 1;
      this.loadData();
    },
    // 分页
    pageIndexChange(val) {
      this.pageParam.pageNum = val;
      this.loadData();
    },

    // 编辑、新增、复制
    editData(id, type) {
      this.editType = type;
      this.detailId = id;
      this.trafficGroupVisible = true;
      
    },

    dimensionTypeSet(row) {
      this.dimensionSetVisible = true
      this.dimensionTitle = row.name
      this.dimensionId = row.id
    },

    handleSearch() {
      this.initData();
    },
    handleReset() {
      this.$refs.templeteForm.resetFields();
      this.initData();
    },

    // 获取名称
    getFieldName(list, key, val) {
      if (list && list.length > 0) {
        let obj = list.filter((item) => {
          return item[key] == val;
        });
        if (obj.length > 0) {
          return obj[0].name;
        }
      }
      return null;
    },

  },
};
</script>
<style scoped lang="scss">
.qy_img {
  display: flex;
  .qy_img_item {
    margin-right: 20px;
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
    background: #000;
    border-radius: 10px;
    position: relative;
    img {
      width: 55px;
      margin: auto;
      display: block;
    }
  }
  .qy_img_name {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
  }
  .qy_img_name1 {
    text-align: center;
    font-size: 8px;
    margin-top: 5px;
    color: #fff;
  }
}
.top_title {
  margin-bottom: 20px;
  .txt {
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
  }
}
.page_block {
  text-align: center;
  margin-top: 20px;
}
.button-box {
  text-align: right;
  margin-top: 40px;
}
.title-tip {
  display: flex;
}
.rightTitle {
  margin-left: 8px;
  font-weight: bold;
}
.text {
  color: #999;
  font-size: 13px;
}
.imgs {
  width: 80px;
  height: 80px;
}
.top-container {
  display: flex;
  justify-content: space-between;
}
.top-right {
  margin-left: 200px;
  display: flex;
  align-items: center;
}
.right-new {
  margin-right: 40px;
}
.size-icon {
  font-size: 30px;
  margin-right: 30px;
}
.img-list {
  margin-left: 50px;
}
.img-title {
  text-align: center;
  margin-bottom: 15px;
}
.img-item {
  display: block;
  width: 100px;
  margin: auto;
}
.img-tip {
  margin-left: 20px;
}
.img-tip:nth-child(2) {
  margin-top: 20px;
}

.avatar-uploader {
  .el-upload {
    display: block;
    border: 1px dashed #ccc;
    border-radius: 6px;
  }
  .el-icon-plus {
    border: 1px dashed #ccc;
    border-radius: 6px;
  }
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.labelTips {
  max-width: 100px;
  max-height: 20px;
}
.top_btn {
  margin-bottom: 20px;
}
</style>
