import { render, staticRenderFns } from "./index.vue?vue&type=template&id=45b198c0&functional=true"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data0/web/xyadmin.98du.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45b198c0')) {
      api.createRecord('45b198c0', component.options)
    } else {
      api.rerender('45b198c0', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=45b198c0&functional=true", function () {
      api.rerender('45b198c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/nested/menu1/menu1-3/index.vue"
export default component.exports