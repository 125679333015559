var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "批量添加广告位",
            visible: _vm.visible,
            direction: "rtl",
            "before-close": _vm.handleClose,
            size: 1200,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "drawerbox" },
            [
              _c("div", { staticClass: "batch-add" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    "应用: " +
                      _vm._s(
                        _vm.configAppData
                          ? _vm.configAppData.platformTypeName
                          : ""
                      ) +
                      " | " +
                      _vm._s(_vm.configAppData ? _vm.configAppData.name : "") +
                      " | id:" +
                      _vm._s(_vm.configAppData ? _vm.configAppData.id : "")
                  ),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    "广告位: " +
                      _vm._s(_vm.adSpaceData ? _vm.adSpaceData.name : "") +
                      " | id:" +
                      _vm._s(_vm.adSpaceData ? _vm.adSpaceData.id : "")
                  ),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    "广告位类型: " +
                      _vm._s(_vm.adSpaceData ? _vm.adSpaceData.typeName : "")
                  ),
                ]),
              ]),
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  attrs: {
                    model: _vm.dataSource,
                    size: "small",
                    "label-width": "50px",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.addTable },
                            },
                            [_vm._v("新建")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "1100px" },
                              attrs: {
                                size: "small",
                                data: _vm.adverSpaceList,
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "opera",
                                  label: "操作",
                                  width: "100",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass:
                                              "el-button-text-danger",
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delTable(
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.adverSpaceList.length ===
                                                    1
                                                    ? ""
                                                    : "删除"
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "status",
                                  label: "状态",
                                  align: "center",
                                  width: "100",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-switch", {
                                          attrs: {
                                            "active-value": 1,
                                            "inactive-value": 0,
                                          },
                                          model: {
                                            value: scope.row.status,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "status", $$v)
                                            },
                                            expression: "scope.row.status",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "adNetworkCode",
                                  label: "广告网络",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择",
                                              clearable: "",
                                            },
                                            model: {
                                              value: scope.row.adNetworkCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "adNetworkCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.adNetworkCode",
                                            },
                                          },
                                          _vm._l(
                                            _vm.adverNetList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.adNetworkCode,
                                                attrs: {
                                                  label: item.adNetworkName,
                                                  value: item.adNetworkCode,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "type",
                                  label: "类型",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择",
                                              clearable: "",
                                            },
                                            model: {
                                              value: scope.row.type,
                                              callback: function ($$v) {
                                                _vm.$set(scope.row, "type", $$v)
                                              },
                                              expression: "scope.row.type",
                                            },
                                          },
                                          _vm._l(_vm.typeList, function (item) {
                                            return _c("el-option", {
                                              key: item.code,
                                              attrs: {
                                                label: item.name,
                                                value: item.code,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "adBitsCode",
                                  label: "代码位ID",
                                  width: "220",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: "请输入",
                                            clearable: "",
                                          },
                                          model: {
                                            value: scope.row.adBitsCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "adBitsCode",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.adBitsCode",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "adBitsName",
                                  label: "代码位名称",
                                  width: "200",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: "请输入",
                                            clearable: "",
                                          },
                                          model: {
                                            value: scope.row.adBitsName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "adBitsName",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.adBitsName",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "price",
                                  label: "排序价格",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.type == 2
                                          ? _c("el-input", {
                                              attrs: {
                                                type: "text",
                                                placeholder: "请输入",
                                                clearable: "",
                                              },
                                              model: {
                                                value: scope.row.price,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "price",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.price",
                                              },
                                            })
                                          : _c("div", [_vm._v("/")]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "margin-top": "80px",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.handleClose },
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.saveBatch },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }