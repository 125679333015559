var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "新建广告位",
            visible: _vm.visible,
            direction: "rtl",
            "before-close": _vm.handleClose,
            size: 900,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "drawerbox" },
            [
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  attrs: {
                    model: _vm.dataSource,
                    size: "small",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.addTable },
                            },
                            [_vm._v("新建广告位")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "800px" },
                              attrs: {
                                size: "small",
                                data: _vm.adverSpaceList,
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { prop: "type", label: "类型" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择",
                                              clearable: "",
                                            },
                                            model: {
                                              value: scope.row.type,
                                              callback: function ($$v) {
                                                _vm.$set(scope.row, "type", $$v)
                                              },
                                              expression: "scope.row.type",
                                            },
                                          },
                                          _vm._l(
                                            _vm.adverTypeList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.code,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.code,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "name", label: "名称" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: "请输入",
                                            clearable: "",
                                          },
                                          model: {
                                            value: scope.row.name,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "name", $$v)
                                            },
                                            expression: "scope.row.name",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "status", label: "操作" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass:
                                              "el-button-text-danger",
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delTable(
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.adverSpaceList.length ===
                                                    1
                                                    ? ""
                                                    : "删除"
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "margin-top": "80px",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.handleClose },
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.saveAdverSpace },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("EditLabelModal", {
        attrs: {
          visible: _vm.labelConfigVisible,
          dimensionType: _vm.dimensionType,
          dimensionId: _vm.dimensionId,
        },
        on: {
          close: function ($event) {
            _vm.labelConfigVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }