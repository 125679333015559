var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "添加单个广告位",
            visible: _vm.visible,
            direction: "rtl",
            "before-close": _vm.handleClose,
            size: 900,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "drawerbox" },
            [
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  attrs: {
                    model: _vm.dataSource,
                    size: "small",
                    "label-width": "165px",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("div", [
                        _c("div", { staticClass: "header-info" }, [
                          _c("div", { staticClass: "header-items" }, [
                            _vm._v(
                              "应用: " +
                                _vm._s(
                                  _vm.configAppData
                                    ? _vm.configAppData.platformTypeName
                                    : ""
                                ) +
                                " | " +
                                _vm._s(
                                  _vm.configAppData
                                    ? _vm.configAppData.name
                                    : ""
                                ) +
                                " | id:" +
                                _vm._s(
                                  _vm.configAppData ? _vm.configAppData.id : ""
                                )
                            ),
                          ]),
                          _c("div", { staticClass: "header-items" }, [
                            _vm._v(
                              "广告位: " +
                                _vm._s(
                                  _vm.adSpaceData ? _vm.adSpaceData.name : ""
                                ) +
                                " | id:" +
                                _vm._s(
                                  _vm.adSpaceData ? _vm.adSpaceData.id : ""
                                )
                            ),
                          ]),
                          _c("div", { staticClass: "header-items" }, [
                            _vm._v(
                              "广告位类型: " +
                                _vm._s(
                                  _vm.adSpaceData
                                    ? _vm.adSpaceData.typeName
                                    : ""
                                )
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            align: "center",
                            label: "广告网络",
                            prop: "adNetworkCode",
                            rules: {
                              required: true,
                              message: "请输入",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.dataSource.adNetworkCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataSource, "adNetworkCode", $$v)
                                },
                                expression: "dataSource.adNetworkCode",
                              },
                            },
                            _vm._l(_vm.adverNetList, function (item) {
                              return _c("el-option", {
                                key: item.adNetworkCode,
                                attrs: {
                                  label: item.adNetworkName,
                                  value: item.adNetworkCode,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            align: "center",
                            label: "类型",
                            prop: "type",
                            rules: {
                              required: true,
                              message: "请输入",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.dataSource.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataSource, "type", $$v)
                                },
                                expression: "dataSource.type",
                              },
                            },
                            _vm._l(_vm.typeList, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: { label: item.name, value: item.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            align: "center",
                            label: "代码位ID",
                            prop: "adBitsCode",
                            rules: {
                              required: true,
                              message: "请输入",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.dataSource.adBitsCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataSource,
                                  "adBitsCode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "dataSource.adBitsCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            align: "center",
                            label: "代码位名称",
                            prop: "adBitsName",
                            rules: {
                              required: true,
                              message: "请输入",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.dataSource.adBitsName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataSource,
                                  "adBitsName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "dataSource.adBitsName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.dataSource.type == 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                align: "center",
                                label: "排序价格",
                                prop: "price",
                                rules: {
                                  required: true,
                                  message: "请输入",
                                  trigger: "blur",
                                },
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  placeholder: "请输入",
                                  maxlength: "6",
                                },
                                model: {
                                  value: _vm.dataSource.price,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataSource,
                                      "price",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "dataSource.price",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "status",
                            label: "状态",
                            align: "center",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.dataSource.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataSource, "status", $$v)
                              },
                              expression: "dataSource.status",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "margin-top": "80px",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.handleClose },
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveAdverSpace(1)
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveAdverSpace(2)
                                },
                              },
                            },
                            [_vm._v("保存并添加")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }