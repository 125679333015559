<template>
  <div class="app-container">
    <el-dialog
      :visible="visible"
      :title="!dimensionType ? '新建': '编辑'"
      width="500px"
      @close="handleClose"
    >
      <el-row>
        <el-form
          ref="dialogForm"
          :model="dimensionDataSource"
          size="small"
          label-width="80px"
        >
          <el-form-item
            label="名称"
            prop="name"
            :rules="{
              required: true,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model.trim="dimensionDataSource.name"
              placeholder="请输入"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="编码"
            prop="code"
            :rules="{
              required: false,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model.trim="dimensionDataSource.code"
              placeholder="请输入"
              style="width: 300px"
              maxlength="6"
              :disabled="dimensionType ? true: false"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-switch
              v-model="dimensionDataSource.status"
              :active-value="1"
              :inactive-value="0"
            />
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model.trim="dimensionDataSource.remark"
              placeholder="请输入"
              type="textarea"
              rows="5"
            ></el-input>
          </el-form-item>
          
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import proApi from '@/api/pages/proApi';
export default {
  name: 'EditLabelModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dimensionType: {
      type: String,
      default: null,
    },
    
  },
  data() {
    return {
      dimensionDataSource: {
        name: '',
        status: '',
        code: '',
        remark: '',
      },
    };
  },

  watch: {
    visible: {
      handler: function (val, oldVal) {
        if (val && this.$parent.dimensionId) {
          this.getDimensionDetail(this.$parent.dimensionId)
        }
      },
      deep: true,
    },
  },

  mounted() {},

  methods: {
    getDimensionDetail(id) {
      proApi.dimDetailById({ id }).then((res) => {
        if (res && res.code == 1) {
          this.dimensionDataSource = res.data;
        }
      });
    },

    handleClose() {
      this.$refs.dialogForm.resetFields();
      this.$emit('close');
    },

    submitData() {
      // 编辑时要传id
      let that = this;
      if (this.dimensionType) {
        this.dimensionDataSource.id = this.$parent.dimensionId;
      }else {
        this.dimensionDataSource.id = ''
      }
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(that.dimensionDataSource));
          data.projectId = that.$parent.projectId
          proApi.addSave(data).then((res) => {
            if (res && res.code == 1) {
              this.$message.success('保存成功');
              this.handleClose();
              this.$parent.getDimensionData();
            } else {
              this.$message.error('保存失败');
            }
          })
          .catch((e) => {
            console.log('e', e);
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-footer {
  text-align: center;
  display: block;
}
</style>
