var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { height: "100%" } },
    [
      _c("el-card", { staticStyle: { height: "100%" } }, [
        _c("div", { staticStyle: { display: "flex", height: "100%" } }, [
          _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  width: "380px",
                  "flex-shrink": "0",
                  "margin-right": "15px",
                  border: "1px solid #ccc",
                  height: "93%",
                  "border-radius": "4px",
                },
              },
              _vm._l(_vm.projectList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "project-box",
                    style: {
                      color:
                        _vm.projectType == index ||
                        (_vm.project && _vm.project.id == item.id)
                          ? "var(--98du-primary-color)"
                          : "",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.itemClick(item, index)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "project-name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("div", { staticClass: "project-android" }, [
                      _vm._v(_vm._s(item.platformTypeName)),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "project-icon",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.pushTop(item.id)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "sortIcon",
                          attrs: {
                            src: require("../../../../../assets/sortIcon.png"),
                          },
                        }),
                      ]
                    ),
                    _c("div", {
                      staticClass: "project-func",
                      style: { background: item.status == 1 ? "#00FF00" : "" },
                    }),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "add-project" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.addProject($event)
                      },
                    },
                  },
                  [_vm._v("添加应用")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticStyle: { flex: "1", "min-width": "0" } },
            [
              _c(
                "div",
                { staticClass: "content-one-box" },
                [
                  _c("div", { staticClass: "content-one" }, [
                    _vm.projectList[_vm.projectType]
                      ? _c("div", { staticClass: "content-name" }, [
                          _vm._v(
                            _vm._s(_vm.projectList[_vm.projectType].name) +
                              " (id:" +
                              _vm._s(_vm.projectList[_vm.projectType].id) +
                              ")"
                          ),
                        ])
                      : _vm._e(),
                    _vm.projectList[_vm.projectType]
                      ? _c("div", { staticClass: "content-platform" }, [
                          _vm._v(
                            _vm._s(
                              _vm.projectList[_vm.projectType].platformTypeName
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm.projectList[_vm.projectType]
                    ? _c("div", { staticClass: "content-two" }, [
                        _vm._v(
                          "创建时间: " +
                            _vm._s(
                              _vm.projectList[_vm.projectType].createdAt ||
                                "2024-05-15 23:59:59"
                            )
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "el-popconfirm",
                    {
                      attrs: { title: "确定更改APP开关吗？" },
                      on: {
                        confirm: function ($event) {
                          return _vm.updateAppStatus(
                            $event,
                            _vm.projectList[_vm.projectType]
                          )
                        },
                      },
                    },
                    [
                      _c("el-switch", {
                        staticClass: "projectSwitch",
                        attrs: {
                          slot: "reference",
                          value:
                            _vm.projectList[_vm.projectType] &&
                            _vm.projectList[_vm.projectType].status,
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        slot: "reference",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "content-two-box",
                  style: {
                    height:
                      _vm.adverNetList &&
                      _vm.adverNetList.length &&
                      _vm.adverNetList.length > 0
                        ? ""
                        : "63px",
                  },
                },
                [
                  _c("div", { staticClass: "content-two" }, [
                    _c(
                      "div",
                      { staticClass: "content-name" },
                      [
                        _vm._v(
                          "广告网络(" + _vm._s(_vm.adverNetList.length) + ") "
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "adButton",
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.addAdverNetWork($event)
                              },
                            },
                          },
                          [_vm._v("添加广告网络")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "netList" },
                      _vm._l(_vm.adverNetList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "content-platform-b" },
                          [
                            _c(
                              "div",
                              { staticClass: "net-name" },
                              [
                                _c("div", [_vm._v(_vm._s(item.adNetworkName))]),
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: { title: "确定要删除吗？" },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.delNet(item.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "del-icon",
                                      attrs: {
                                        slot: "reference",
                                        src: require("../../../../../assets/del-icon.png"),
                                      },
                                      slot: "reference",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              ),
              _c(
                "el-form",
                {
                  ref: "templeteForm",
                  attrs: {
                    model: _vm.requestParams,
                    size: "small",
                    inline: true,
                  },
                },
                [
                  _c("el-row", { staticStyle: { "flex-wrap": "wrap" } }, [
                    _c(
                      "div",
                      { staticClass: "el-custom-grid" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "type" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "el-select-darkness",
                                attrs: {
                                  placeholder: "广告位类型",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.requestParams.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.requestParams, "type", $$v)
                                  },
                                  expression: "requestParams.type",
                                },
                              },
                              _vm._l(_vm.adverTypeList, function (item, index) {
                                return _c(
                                  "el-option",
                                  {
                                    key: index,
                                    attrs: {
                                      value: item.code,
                                      label: item.name,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "status" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "el-select-darkness",
                                attrs: { placeholder: "状态", clearable: "" },
                                model: {
                                  value: _vm.requestParams.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.requestParams, "status", $$v)
                                  },
                                  expression: "requestParams.status",
                                },
                              },
                              _vm._l(_vm.statusList, function (item, index) {
                                return _c(
                                  "el-option",
                                  {
                                    key: index,
                                    attrs: {
                                      value: item.type,
                                      label: item.name,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "el-button-search",
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSearch()
                                  },
                                },
                              },
                              [_vm._v("查询")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "el-button-reset",
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReset()
                                  },
                                },
                              },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.addAdverSpace($event)
                                  },
                                },
                              },
                              [_vm._v("新增广告位")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "small",
                    data: _vm.adverSpaceDataList,
                    "header-cell-style": {
                      height: "50px",
                      fontSize: "14px",
                      color: "#333",
                      fontweight: 400,
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "150", fixed: "left" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-right": "20px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editData(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: { title: "确定要删除吗？" },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.delAdverSpace(scope.row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "el-button-text-danger",
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                          size: "small",
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "状态",
                      width: "150",
                      fixed: "left",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确定要更新吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.updateItemStatus(scope.row)
                                  },
                                },
                              },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    slot: "reference",
                                    value: scope.row.status,
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  slot: "reference",
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "广告位名称",
                      width: "280",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "typeName",
                      label: "广告类型",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.createTime))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "page_block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pageParam.pageNum,
                      "page-sizes": [10, 20, 30, 40, 50],
                      "page-size": _vm.pageParam.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam.total,
                    },
                    on: {
                      "size-change": _vm.pageSizeChange,
                      "current-change": _vm.pageIndexChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("EditModal", {
        attrs: { visible: _vm.editVisible },
        on: {
          close: function ($event) {
            _vm.editVisible = false
          },
        },
      }),
      _c("AdverNetwork", {
        attrs: {
          visible: _vm.adverNetVisible,
          configAppId:
            _vm.projectList[_vm.projectType] &&
            _vm.projectList[_vm.projectType].id,
        },
        on: {
          close: function ($event) {
            _vm.adverNetVisible = false
          },
        },
      }),
      _c("AddAdver", {
        attrs: {
          visible: _vm.addAdverVisible,
          configAppId:
            _vm.projectList[_vm.projectType] &&
            _vm.projectList[_vm.projectType].id,
          adverTypeList: _vm.adverTypeList,
        },
        on: {
          close: function ($event) {
            _vm.addAdverVisible = false
          },
        },
      }),
      _c("EditAdverSpace", {
        attrs: {
          visible: _vm.adverspaceVisible,
          data: _vm.itemData,
          adverTypeList: _vm.adverTypeList,
          detailId: _vm.detailId,
        },
        on: {
          close: function ($event) {
            _vm.adverspaceVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }