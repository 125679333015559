<!-- 新增、编辑 -->
<template>
  <div class="app-container">
    <el-drawer
      :title="`流量分组管理`"
      :visible.sync="visible"
      direction="rtl"
      :before-close="handleClose"
      :size="1200"
    >
      <el-row class="drawerbox">
        <el-form
          ref="dialogForm"
          :model="dataSource"
          size="small"
          label-width="50px"
        >
          <div>
            <el-form-item >
              <el-button size="small" type="primary" @click="editDimension"
                >新增流量分组</el-button
              >
            </el-form-item>
            <el-form-item label="">
              <el-table
                size="small"
                :data="dataList"
                style="width: 1100px"
                border
              >
                <el-table-column label="操作" fixed="left" width="130">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="editDimension(scope.row, 'edit')"
                      >编辑</el-button
                    >

                    <el-popconfirm title="确定要删除吗？" @confirm="deleteData(scope.row.id)">
                      <el-button
                        style="margin-left:30px"
                        slot="reference"
                        type="text"
                        size="small"
                        class="el-button-text-danger"
                      >删除</el-button>
                    </el-popconfirm>
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" align="center" width="100">
                    <template slot-scope="scope">
                        <el-popconfirm title="确定要更改状态吗？" @confirm="updateAdverStatus(scope.row)">
                            <el-switch
                                slot="reference"
                                style="margin-left:30px"
                                :value="scope.row.status"
                                :active-value="1"
                                :inactive-value="0"
                            />
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="分组ID" />
                <el-table-column prop="name" label="分组名称"/>
                <el-table-column prop="sortNo" label="优先级" />
                <el-table-column prop="waterfallStrategyName" label="瀑布流策略"/>
                <el-table-column prop="flowSubsetRuleName" label="流量分组策略" >
                  <template slot-scope="scope">
                    <div v-if="scope.row.flowSubsetRuleName" class="strategyName" @click.stop="traffGroupClick(scope.row.flowSubsetRuleId, 'edit')">{{scope.row.flowSubsetRuleName}}({{scope.row.flowSubsetRuleId}})</div>
                  </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" />
                
              </el-table>
            </el-form-item>

          </div>
        </el-form>
      </el-row>
    </el-drawer>
    <EditLabelModal
      :visible="labelConfigVisible"
      :dimensionType="dimensionType"
      :dimensionId="dimensionId"
      :adSpaceDataId="adSpaceDataId"
      :dimensionData="dimensionData"
      @close="labelConfigVisible = false"
    ></EditLabelModal>

    <AddtrafficGroupRule
      :visible="trafficGroupVisible"
      :editType="editType"
      :symbolList="symbolList"
      :ruleConfigList="ruleConfigList"
      @close="trafficGroupVisible = false"
    ></AddtrafficGroupRule>
  </div>
</template>

<script>
import proApi from '@/api/pages/proApi';
import EditLabelModal from './EditLabelModal';
import AddtrafficGroupRule from '../../../productAppConfig/productConfig/trafficGroupRule/components/AddtrafficGroupRule';

import applicationConfigApi from '@/api/pages/applicationConfig';

export default {
  name: 'DimensionTypeSet',
  components: {
    EditLabelModal,
    AddtrafficGroupRule
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    projectId:{
      type: Number,
      default: null
    },
    configAppId:{
    //   type: Number,
    //   default: 0
    },
    adverNetList:{
        type:Array,
        default:[]
    },
    adSpaceDataId:{
        // type: Number,
        // default: 0
    },
    adBitsSubsetId:{
        // type: Number,
        // default: 0
    },
  },

  data() {
    return {
        dataSource: {
            
        },
        projectList: [],
        labelConfigVisible: false,
        dimensionList: [],
        dimensionType: '',
        dimensionId: '',
        adverSpaceList: [
            {
                adNetworkCode:'',
                type:'',
                adBitsCode:'',
                adBitsName:'',
                price:'',
                status:0,
            },
        ],
        typeList:[
            {code: 1, name: '客户端竞价'},
            {code: 2, name: '按价格'},
            {code: 3, name: '兜底'},
        ],
        dataList:[],
        dimensionData:{},
        trafficGroupVisible: false,
        ruleConfigList:[],
        symbolList:[],
        editType:'',
        detailId:''
    };
  },
  watch: {
    visible: {
      handler: function (val, oldVal) {
        if (val) {
            this.getTraffGroupData()
        } 
      },
      deep: true,
    },
    
  },
  mounted() {
    this.getTraffGroupOperatorList()
    this.getsymbolList()
  },

  methods: {
    //获取流量分组-列表
    getTraffGroupData() {
        applicationConfigApi.subsetList({
            adSpaceDataId: this.adSpaceDataId
        }).then((res) => {
            if (res && res.code === 1 && res.data) {
                this.dataList = res.data
            }
        }).catch((e) => {
            console.log(e);
        });
    },

    //流量分组-删除
    deleteData(val) {
      applicationConfigApi.subsetDelete(val).then((res) => {
        if (res && res.code === 1) {
          this.$message.success('删除成功')
          this.getTraffGroupData()
          this.getTabtacticsData()
        }
      }).catch((e) => {
          console.log(e);
      });
    },

    updateAdverStatus(row) {
      let val = row.status == 1 ? 0 : 1;
       applicationConfigApi.subsetUpdateStatus({id: row.id, status: val})
        .then((res) => {
          if (res && res.code == 1) {
            this.$message.success('修改成功');
            this.getTraffGroupData()
          }
        }).catch((e) => {
          console.log(e);
        });
    },

    getTraffGroupOperatorList() {
      applicationConfigApi.fieldCodeList().then((res) => {
        if (res.code == 1 && res.data) {
          this.ruleConfigList = res.data
        }
      });
    },

    getsymbolList() {
      applicationConfigApi.operatorList().then((res) => {
        if (res.code == 1 && res.data) {
          this.symbolList = res.data
        }
      });
    },

     //新增
    addTable() {
      this.adverSpaceList.push({
        adNetworkCode:'',
        type:'',
        adBitsCode:'',
        adBitsName:'',
        price:'',
        status: 0,
      });
    },

    traffGroupClick(id, type){
      this.detailId =id
      this.trafficGroupVisible = true
      this.editType = type 
    },

    //删除
    delTable(index) {
      this.adverSpaceList.splice(index, 1);
    },

    editDimension(row, type) {
      this.labelConfigVisible = true
      this.dimensionId = row.id
      this.dimensionType = type
      this.dimensionData=row
    },

    //保存广告位
    saveBatch() {
        let data = {}
        data.adBitsDataList = this.adverSpaceList
        data.adBitsSubsetId = this.adBitsSubsetId
        data.adSpaceDataId = this.adSpaceDataId
        data.configAppId = this.configAppId
        applicationConfigApi.batchAddAd(data).then((res) => {
            if (res && res.code == 1) {
                this.handleClose()
                this.$parent.getTabtacticsData()
            }
        });
    },

    //操作状态
    updateStatus(e, row, index) {
      let msg = row.status == 1 ? '确定关闭？' : '确定开启？';
      let val = row.status == 1 ? 0 : 1;
    //   this.$confirm(msg, '提示', { type: 'warning' }).then(() => {
        // proApi.dataDimUpdateStatus({ id: row.id, status: val })
        //   .then((res) => {
        //     if (res && res.code === 1) {
        //       this.$message.success('修改成功');
        //       this.getDimensionData();
        //     }
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   });
    //   });
    },

    handleClose() {
      // this.$refs.dialogForm.resetFields();
      this.$emit('close');
    },

    handleProject() {
      this.dataSource.appId = '';
    },
    
    // 获取名称
    getFieldName(list, key, val) {
      if (list && list.length > 0) {
        let obj = list.filter((item) => {
          return item[key] == val;
        });
        if (obj.length > 0) {
          return obj[0].name;
        }
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.strategyName {
    color: #3D7FFF;
    text-decoration: underline;
    cursor: pointer;
}
.dialog-footer {
  text-align: center;
  display: block;
  margin-top: 20px;
}
.txt {
  color: red;
  font-size: 12px;
  margin-left: 10px;
}
.txt1 {
  font-size: 12px;
  margin: 0 10px;
}
.flex {
  display: flex;
}
.tips_icon {
  margin: 0 10px;
  position: relative;
  top: 6px;
}
.labelCenter {
  align-items: center;
}
.lab_title {
  width: 130px;
  text-align: right;
  padding-right: 18px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.mon_box {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 20px;
}
.drawerbox {
  padding-bottom: 40px;
}
.time_tips {
  font-size: 12px;
  color: red;
  line-height: 20px;
}
</style>
