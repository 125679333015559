import request from '@/utils/request';

//维度列表
export function dataDimConfigList(data) {
  return request({
    url: '/admin/data/DataDimConfig/dataDimConfigList',
    method: 'post',
    data,
  });
}

//维度保存
export function dataDimConfigSave(data) {
  return request({
    url: '/admin/data/DataDimConfig/dataDimConfigSave',
    method: 'post',
    data,
  });
}

//维度执行详情
export function dataDimExecuteDetails(data) {
  return request({
    url: '/admin/data/DataDimConfig/dataDimExecuteDetails',
    method: 'post',
    data,
  });
}

//维度执行操作
export function dataDimExecuteSave(data) {
  return request({
    url: '/admin/data/DataDimConfig/dataDimExecuteSave',
    method: 'post',
    data,
  });
}

//维度字段列表信息
export function dataDimFieldDetails(data) {
  return request({
    url: '/admin/data/DataDimConfig/dataDimFieldDetails',
    method: 'post',
    data,
  });
}

//维度字段保存
export function dataDimFieldSave(data) {
  return request({
    url: '/admin/data/DataDimConfig/dataDimFieldSave',
    method: 'post',
    data,
  });
}

//项目列表
export function getProjectList(data) {
  return request({
    url: '/admin/project/list',
    method: 'get',
    params: data,
  });
}

//同步日志列表
export function dataDimSyncLogList(data) {
  return request({
    url: '/admin/data/DataDimSyncLog/dataDimSyncLogList',
    method: 'post',
    data,
  });
}

//维度类别
export function listProjectId(data) {
  return request({
    url: '/admin/dataDimType/listProjectId',
    method: 'get',
    params: data,
  });
}

//根据项目ID获取数据源配置列表
export function listByProjectId(data) {
  return request({
    url: '/admin/dataSourceConfig/listByProjectId',
    method: 'post',
    data,
  });
}

//根据数据源ID获取数据源表数据列表
export function getTablelistBytId(data) {
  return request({
    url: '/admin/dataSourceConfig/getTablelistBytId',
    method: 'post',
    data,
  });
}

//根据项目ID获取产品配置列表
export function getDimBasicProductListByProjectId(data) {
  return request({
    url: '/admin/product/getDimBasicProductListByProjectId',
    method: 'post',
    data,
  });
}

//维度详情
export function dataDimConfigDetails(data) {
  return request({
    url: '/admin/data/DataDimConfig/dataDimConfigDetails',
    method: 'post',
    data,
  });
}

//维度删除
export function dataDimConfigUpdateDelete(data) {
  return request({
    url: '/admin/data/DataDimConfig/dataDimConfigUpdateDelete',
    method: 'post',
    data,
  });
}

//维度修改状态
export function dataDimConfigUpdateStatus(data) {
  return request({
    url: '/admin/data/DataDimConfig/dataDimConfigUpdateStatus',
    method: 'post',
    data,
  });
}

//初始化数据
export function initTableData(data) {
  return request({
    url: '/admin/data/DataDimConfig/initTableData',
    method: 'post',
    data,
  });
}
//调用
export function syncOnce(data) {
  return request({
    url: '/admin/data/DataDimConfig/syncOnce',
    method: 'post',
    data,
  });
}
